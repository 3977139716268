'use strict'

const View = require('ampersand-view')
const FilteredCollection = require('ampersand-filtered-subcollection')
const app = require('ampersand-app')

module.exports = View.extend({
  template: require('./target.pug').default,

  events: {
    'change select': 'onSelect'
  },

  initialize () {
    const transaction = this.model

    // exclude transaction source
    this._filter = function (model) {
      return model.id !== transaction.sourceId
    }
    this.collection = new FilteredCollection(app.locations, {
      filter: this._filter,
      watched: ['type']
    })

    // do not set initial target, as per feature request from Björn
    // if (!this.model.targetId) this.model.set('targetId', this.collection.at(0).id)

    this.listenTo(this.collection, 'add remove change', this.render)
    this.listenTo(this.model, 'change:sourceId', this.onSourceChange)
  },

  onSourceChange (transaction, sourceId) {
    const filter = function (model) {
      return model.id !== sourceId
    }
    this.collection.swapFilters(filter, this._filter)
    this._filter = filter
    if (!this.model.targetId) {
      this.model.set('targetId', this.collection.at(0).id)
    }
  },

  onSelect (evt) {
    this.model.set('targetId', evt.target.value)
  }
})
