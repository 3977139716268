.dashboard-page
  .pure-g
    .pure-u-1-2: h1 Übersicht
  .pure-g
    .pure-u-1
      h2 Produkte
      h3 Gesamtbestand
      div(data-hook="products")

  .pure-g(data-hook="product-details")
  .pure-g(data-hook="product-locations")
  .pure-g
    .pure-u-1.link-wrap
      a(role="button", href="#", data-hook="toggle-product-details") Details einblenden...

  if isAdmin
    .pure-g
      .pure-u-1: h2 Umsatz
      .pure-u-lg-1-2.pure-u-1.card__wrapper
        div(data-hook="best-bars")
      .pure-u-lg-1-2.pure-u-1.card__wrapper
        div(data-hook="best-products")

  .pure-g
    .pure-u-1
      h2 Transaktionen
      div(data-hook="transactions")


