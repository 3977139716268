'use strict'

const FormView = require('ampersand-form-view')
const InputView = require('ampersand-input-view')
const SelectView = require('ampersand-select-view')
const CheckboxView = require('ampersand-checkbox-view')

const FilteredCollection = require('ampersand-filtered-subcollection')
const { packaging } = require('../../models/packages')

const units = new FilteredCollection(packaging, {
  where: { type: 'unit' },
  comparator: 'name'
})

const packages = new FilteredCollection(packaging, {
  where: { type: 'package' },
  comparator: 'name'
})

const InputAddonView = InputView.extend({
  props: {
    addon: ['string', true],
    type: ['string', true, 'number'],
    placeholder: ['string', true, '0'],
    requiredMessage: ['string', true, 'Pflichtfeld.'],
    validityClassSelector: ['string', true, 'label']
  },
  template: require('./input-addon.pug').default
})

const MoneyInputView = InputAddonView.extend({
  derived: {
    value: {
      deps: ['inputValue'],
      fn () {
        return this.inputValue + ''
      }
    }
  }
})

function gtZero (val) {
  if (val <= 0) return 'Muss größer als 0 sein.'
}

module.exports = FormView.extend({
  validCallback (valid) {
    if (!this.submitBtn) {
      this.submitBtn = this.el.querySelector('[type=submit]')
    }
    this.submitBtn.disabled = !valid
  },

  fields () {
    return [
      new InputView({
        parent: this,
        validityClassSelector: 'label',
        el: this.el.querySelector('[data-hook=name]'),
        name: 'name',
        value: this.model.name,
        label: 'Bezeichnung',
        required: true,
        requiredMessage: 'Pflichtfeld'
      }),

      new InputView({
        parent: this,
        validityClassSelector: 'label',
        el: this.el.querySelector('[data-hook=package-size]'),
        name: 'packageSize',
        value: this.model.packageSize,
        label: 'Verpackung',
        type: 'number',
        placeholder: '0',
        required: true,
        requiredMessage: 'Pflichtfeld',
        tests: [gtZero]
      }),

      new InputAddonView({
        parent: this,
        el: this.el.querySelector('[data-hook=volume-per-unit]'),
        name: 'volumePerUnit',
        value: this.model.volumePerUnit,
        addon: 'l',
        label: 'Inhalt',
        tests: [gtZero]
      }),

      new SelectView({
        parent: this,
        name: 'packageId',
        label: '',
        required: true,
        el: this.el.querySelector('[data-hook=package-name]'),
        options: packages,
        value: this.model.packageId || packages.get('box'),
        idAttribute: 'id',
        textAttribute: 'name',
        disabledAttribute: 'disabled',
        yieldModel: false
      }),

      new SelectView({
        parent: this,
        name: 'unitId',
        label: '',
        required: true,
        el: this.el.querySelector('[data-hook=package-unit]'),
        options: units,
        value: this.model.unitId || units.get('bottle'),
        idAttribute: 'id',
        textAttribute: 'name',
        disabledAttribute: 'disabled',
        yieldModel: false
      }),

      new MoneyInputView({
        parent: this,
        el: this.el.querySelector('[data-hook=cost]'),
        name: 'cost',
        value: this.model.cost && (+this.model.cost).toFixed(2),
        label: 'Einkauf (netto)',
        addon: '€',
        tests: [gtZero]
      }),

      new SelectView({
        parent: this,
        name: 'costUnit',
        label: 'pro',
        required: true,
        el: this.el.querySelector('[data-hook=cost-unit]'),
        options: packaging,
        value: this.model.costUnitId || packaging.get('box'),
        idAttribute: 'id',
        textAttribute: 'name',
        disabledAttribute: 'disabled',
        yieldModel: true,
        beforeSubmit () {
          this.value = this.value.type
        }
      }),

      new MoneyInputView({
        parent: this,
        el: this.el.querySelector('[data-hook=price]'),
        name: 'price',
        value: this.model.price && (+this.model.price).toFixed(2),
        label: 'Verkauf (brutto)',
        addon: '€',
        tests: [gtZero]
      }),

      new InputAddonView({
        parent: this,
        el: this.el.querySelector('[data-hook=serving-size]'),
        name: 'servingSize',
        value: this.model.servingSize,
        label: 'pro',
        addon: 'l',
        tests: [gtZero]
      }),

      new InputAddonView({
        parent: this,
        el: this.el.querySelector('[data-hook=weight-full]'),
        name: 'weightFull',
        value: this.model.weightFull,
        label: 'Gewicht voll',
        addon: 'g',
        required: false
      }),

      new InputAddonView({
        parent: this,
        el: this.el.querySelector('[data-hook=weight-empty]'),
        name: 'weightEmpty',
        value: this.model.weightEmpty,
        label: 'Gewicht leer',
        addon: 'g',
        required: false
      }),

      new CheckboxView({
        parent: this,
        value: this.model.canSpill || false,
        el: this.el.querySelector('[data-hook=can-spill]'),
        name: 'canSpill',
        label: 'Schankverlust einrechnen'
      })
    ]
  }
})
