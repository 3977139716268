section.transaction-page
  header.pure-g
    .pure-u-1-4
      h1 Transaktionen
    .pure-u-3-4
      .flex-center
        .cell
          div(data-hook="search-bar")
  div(data-hook="slider")
  div(data-hook="search-state")

  table.pure-table.table-striped
    thead
      tr
        th Info
        th(data-hook="filter-by-product").transaction-page-column__filterable Produkt
          i.ion-android-arrow-dropdown
        th Menge
        th(data-hook="filter-by-source").transaction-page-column__filterable Von
          i.ion-android-arrow-dropdown
        th(data-hook="filter-by-target").transaction-page-column__filterable Nach
          i.ion-android-arrow-dropdown

        if me.role === 'admin'
          th

    tbody(data-hook="transaction-list")

  footer.transaction-page__footer
    | Zeige <span data-hook="displayCount"></span> von insgesamt <span data-hook="count"></span> Transaktionen.
