
select(name="source").pure-input-1
  optgroup(label="Lager")
    each source in collection.models
      if source.type === 'storage'
        option(value=source.id, selected=model.sourceId === source.id) #{source.name}

  optgroup(label="Bars")
    each source in collection.models
      if source.type === 'bar'
        option(value=source.id, selected=model.sourceId === source.id) #{source.name}
