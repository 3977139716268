const View = require('ampersand-view')
const { DateTime } = require('luxon')

module.exports = View.extend({
  template: require('./date-picker.pug').default,

  props: {
    inputValue: 'date'
  },

  derived: {
    formattedDate: {
      deps: ['inputValue'],
      fn () {
        return this.inputValue
          ? DateTime.fromJSDate(this.inputValue).toFormat("yyyy-LL-dd'T'HH:mm")
          : ''
      }
    }
  },

  events: {
    'change input': 'onChange'
  },

  initialize (opts = {}) {
    this.inputValue = opts.initialValue
  },

  /**
   * @param {InputEvent} event
   */
  onChange (event) {
    const el = /** @type {HTMLInputElement} */ (event.target)
    this.inputValue = new Date(el.value)
  },

  render () {
    this.renderWithTemplate()
    return this
  }
})
