'use strict'

const View = require('ampersand-view')
const { default: tippy } = require('tippy.js')
const onAnimationEnd = require('../../on-end').onAnimationEnd

const Form = View.extend({
  events: {
    'keyup input': 'onKeyup',
    'submit form': 'submit'
  },

  props: {
    username: ['string', true, ''],
    password: ['string', true, '']
  },

  derived: {
    isUsernameValid: {
      deps: ['username'],
      fn () {
        const isLowerCase = this.username.toLowerCase() === this.username
        const hasSpaces = this.username.search(/\s+/g) > -1
        return isLowerCase && !hasSpaces
      }
    },
    isPasswordValid: {
      deps: ['password'],
      fn () {
        return !!this.password
      }
    },
    isValid: {
      deps: ['isUsernameValid', 'isPasswordValid'],
      fn () {
        return this.isUsernameValid && this.isPasswordValid
      }
    }
  },

  initialize () {
    this.listenTo(this, 'change:isValid', this.onValidation)
    this.listenTo(this, 'change:isUsernameValid', this.onUsernameValidation)
  },

  onValidation () {
    this.query('button').disabled = !this.isValid
  },

  onUsernameValidation (view, isUsernameValid) {
    if (!isUsernameValid) {
      this.tip = tippy(this.usernameEl, {
        trigger: 'manual',
        content:
          'Dein Benutzername sollte keine Großbuchstaben und keine Leerzeichen enthalten.'
      })
      this.tip.show()
    } else if (this.tip) {
      this.tip.destroy()
    }
  },

  onKeyup (e) {
    this[e.target.name] = e.target.value
  },

  onError () {
    this.query('.error').textContent = 'Da hat was nicht geklappt...'
  },

  render () {
    this.renderWithTemplate()
    this.cacheElements({
      usernameEl: this.queryByHook('username'),
      passwordEl: this.queryByHook('password')
    })
    return this
  }
})

const LoginForm = Form.extend({
  template: require('./login.pug').default,
  submit (e) {
    e.preventDefault()
    this.model.login(this.username, this.password)
  }
})

const SignUpForm = Form.extend({
  template: require('./signup.pug').default,
  submit (e) {
    e.preventDefault()
    this.model.signup(this.username, this.password)
  }
})

/*
  Auth Page View
 */
module.exports = View.extend({
  template: require('./auth.pug').default,

  events: {
    'click [data-hook=flip]': 'flip'
  },

  subviews: {
    login: {
      container: '[data-hook=login-form]',
      prepareView (el) {
        return new LoginForm({ el, model: this.model })
      }
    },
    signup: {
      container: '[data-hook=signup-form]',
      prepareView (el) {
        return new SignUpForm({ el, model: this.model })
      }
    }
  },

  initialize () {
    this.listenTo(this.model, 'sync', this.hide)
    // delay listening so we don't shake on initial error
    setTimeout(() => {
      this.listenTo(this.model, 'error', this.onError)
    }, 0)
  },

  onError () {
    const el = this.query('.auth-wrap')
    onAnimationEnd(el, () => el.classList.remove('shake'))
    el.classList.add('shake')
  },

  hide () {
    const container = this.query('.auth-container')
    onAnimationEnd(container, () => this.remove())
    this.el.classList.add('fadeOut')
    container.classList.add('slideOutUp')
  },

  flip (e) {
    e.preventDefault()
    this.el.classList.toggle('flipped')
  }
})
