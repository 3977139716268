import groupBy from 'lodash/groupBy'
import { Fragment } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import {
  api,
  useCurrentEventSlug,
  useCurrentRole,
  useTransactions
} from '../hooks'
import { Modal } from '../modal/Modal'
import { PreactView } from '../preact-view'
import { TaskboardEditTransaction } from './TaskboardEditTransaction'
import { TaskboardGroupActions } from './TaskboardGroupActions'
import { TaskboardItem } from './TaskboardItem'

import * as styles from './taskboard.module.css'
import { TaskboardGroup } from './TaskboardGroup'

export const TaskboardView = PreactView.extend({
  component: Taskboard
})

/** @param {import('../types').Transaction} tx */
const onlyBarOrders = (tx) => tx.user.currentEventSettings.role === 'bar'

/** @param {import('../types').Transaction} tx */
const otherOrders = (tx) => tx.user.currentEventSettings.role !== 'bar'

/** @param {import('../types').Transaction} tx */
const getGroupKey = (tx) => `${tx.createdAt}_${tx.sourceId}_${tx.targetId}`

/** @param {{ onClose?: () => void }} props */
export function Taskboard ({ onClose }) {
  const eventSlug = useCurrentEventSlug()
  const role = useCurrentRole()
  const { data: transactions, mutate, error } = useTransactions()
  const barOrders = transactions?.data.filter(onlyBarOrders)
  const barOrdersGroups = Object.entries(groupBy(barOrders, getGroupKey))
  const otherTransactions = transactions?.data.filter(otherOrders)
  const otherTransactionsGroups = Object.entries(
    groupBy(otherTransactions, getGroupKey)
  )
  const [editTransaction, setEditTransaction] = useState(
    /** @type {import('../types').Transaction | null} */ (null)
  )
  const [showEditTransactionModal, setShowEditTransactionModal] =
    useState(false)

  const [rerender, setRerender] = useState(0)

  // update the relative dispatch time every 10s
  useEffect(() => {
    const id = setTimeout(() => {
      setRerender(rerender + 1)
    }, 10 * 1000)

    return () => {
      clearTimeout(id)
    }
  }, [rerender])

  /**
   * @param {string} id
   * @param {{
   *   state?: import('../types').Transaction['state']
   *   comment?: string
   * }} payload
   */
  async function saveTransaction (id, payload) {
    // remove completed transactions immediately
    if (payload.state === 'complete' && transactions) {
      await mutate(
        {
          ...transactions,
          data: transactions.data.filter((tx) => tx.id !== id)
        },
        { revalidate: false }
      )
    }

    await api(`/api/events/${eventSlug}/transactions/${id}`, {
      method: 'PUT',
      body: payload
    })
    await mutate()
  }

  async function deleteTransaction (id) {
    if (transactions) {
      await mutate(
        {
          ...transactions,
          data: transactions.data.filter((tx) => tx.id !== id)
        },
        { revalidate: false }
      )
    }
    await api(`/api/events/${eventSlug}/transactions/${id}`, {
      method: 'DELETE'
    })
    await mutate()
  }

  /**
   * @param {string} id
   * @param {Pick<
   *   import('../types').Transaction,
   *   'units' | 'sourceId' | 'comment'
   * >} payload
   */
  async function handleEditTransaction (id, payload) {
    await api(`/api/events/${eventSlug}/transactions/${id}`, {
      method: 'PUT',
      body: payload
    })
    await mutate()
  }

  /** @param {import('../types').Transaction} transaction */
  function handleShowEditModal (transaction) {
    setEditTransaction(transaction)
    setShowEditTransactionModal(true)
  }

  return (
    <div class={onClose ? styles.taskboardSidebar : styles.taskboard}>
      {onClose && (
        <button
          class={styles.closeButton}
          data-title='Taskboard ausblenden'
          onClick={onClose}
        >
          <i class='ion-close-round' />
        </button>
      )}

      {error && !transactions && (
        <div class={styles.error}>
          <p>Error: {error.message}</p>
          {!navigator.onLine && <p>Bist du mit dem Internet verbunden?</p>}
        </div>
      )}

      {transactions && transactions.data.length === 0 && (
        <p class={styles.noItems}>Keine offenen Aufträge.</p>
      )}

      {barOrders && barOrders.length > 0 && (
        <div class={styles.transactionSection}>
          <h3>{role === 'bar' ? 'Deine Bestellungen' : 'Barbestellungen'}</h3>
          <ul>
            {barOrdersGroups.map(([key, transactions]) => (
              <Fragment key={key}>
                {transactions.length > 1
                  ? (
                    <TaskboardGroup transactions={transactions}>
                      <ul>
                        {transactions.map((transaction) => (
                          <TaskboardItem
                            key={transaction.id}
                            transaction={transaction}
                            isGrouped
                            onUpdateTransaction={saveTransaction}
                            onDeleteTransaction={deleteTransaction}
                            onEditTransaction={handleShowEditModal}
                            role={role}
                          />
                        ))}
                      </ul>

                      {transactions && transactions.length > 1 && (
                        <TaskboardGroupActions
                          ids={transactions.map((transaction) => transaction.id)}
                          onRevalidate={mutate}
                        />
                      )}
                    </TaskboardGroup>
                    )
                  : (
                    <TaskboardItem
                      transaction={transactions[0]}
                      onUpdateTransaction={saveTransaction}
                      onDeleteTransaction={deleteTransaction}
                      onEditTransaction={handleShowEditModal}
                      role={role}
                    />
                    )}
              </Fragment>
            ))}
          </ul>
        </div>
      )}

      {otherTransactions && otherTransactions.length > 0 && (
        <div class={styles.transactionSection}>
          <h3>Andere Aufträge</h3>

          <ul>
            {otherTransactionsGroups.map(([key, transactions]) => (
              <Fragment key={key}>
                {transactions.length > 1
                  ? (
                    <TaskboardGroup transactions={transactions}>
                      <ul>
                        {transactions.map((transaction) => (
                          <TaskboardItem
                            key={transaction.id}
                            transaction={transaction}
                            isGrouped
                            onUpdateTransaction={saveTransaction}
                            onDeleteTransaction={deleteTransaction}
                            onEditTransaction={handleShowEditModal}
                            role={role}
                          />
                        ))}
                      </ul>

                      {transactions && transactions.length > 1 && (
                        <TaskboardGroupActions
                          show={['complete']}
                          ids={transactions.map((transaction) => transaction.id)}
                          onRevalidate={mutate}
                        />
                      )}
                    </TaskboardGroup>
                    )
                  : (
                    <TaskboardItem
                      transaction={transactions[0]}
                      onUpdateTransaction={saveTransaction}
                      onDeleteTransaction={deleteTransaction}
                      onEditTransaction={handleShowEditModal}
                      role={role}
                    />
                    )}
              </Fragment>
            ))}
          </ul>
        </div>
      )}

      {/* {(role === 'bar') && (
        <div style={{ display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'flex-end' }}>
          <button class='pure-button pure-button-primary'>Neue Bestellung</button>
        </div>
      )} */}

      <Modal
        isOpen={showEditTransactionModal}
        onClose={() => setShowEditTransactionModal(false)}
      >
        {editTransaction && (
          <TaskboardEditTransaction
            transaction={editTransaction}
            onEditTransaction={handleEditTransaction}
            onClose={() => setShowEditTransactionModal(false)}
          />
        )}
      </Modal>
    </div>
  )
}
