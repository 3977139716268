'use strict'

const View = require('ampersand-view')
const { default: tippy } = require('tippy.js')
const app = require('ampersand-app')

const Form = require('./add-product-form')

const ESC_KEY = 27

module.exports = View.extend({
  template: require('./product-form.pug').default,

  events: {
    'keyup input': 'keyup',
    'click [data-hook=cancel]': 'onCancel',
    'focus [name=packageSize]': 'onFocusIn',
    'blur [name=packageSize]': 'onFocusOut',
    'focus [name=volumePerUnit]': 'onFocusIn',
    'blur [name=volumePerUnit]': 'onFocusOut'
  },

  derived: {
    isFirstProduct: {
      cache: false,
      fn () {
        if (!this.parent) return false
        return this.parent.collection.length === 0
      }
    }
  },

  initialize () {
    this.listenTo(this.model, 'sync', this.onSync)
  },

  /**
   * @param {FocusEvent} evt
   */
  onFocusIn (evt) {
    if (evt.target.value) return
    if (evt.target.name === 'packageSize') {
      this.tooltip = tippy(evt.target, {
        trigger: 'manuel',
        content: 'Wieviele Einheiten sind in einem Gebinde?'
      })
    }

    if (evt.target.name === 'volumePerUnit') {
      this.tooltip = tippy(evt.target, {
        trigger: 'manuel',
        content: 'Wieviel Liter sind in einer Einheit?'
      })
    }
    this.tooltip.show()
  },

  onFocusOut () {
    if (this.tooltip) this.tooltip.destroy()
  },

  onCancel () {
    this.remove()
    if (!this.model.editable && this.parent) this.parent.hasNew = false
    this.model.editable = false
  },

  onSync () {
    this.remove()
    // for new products reset state
    if (!this.model.editable && this.parent) {
      this.parent.hasNew = false
      this.parent.addProduct()
    }
    this.model.editable = false
  },

  /*
    DOM Event Handlers
   */

  keyup (evt) {
    if (evt.which === ESC_KEY) return this.onSync()
    const el = evt.target
    // hide if the user typed something
    if (
      el.value &&
      (el.name === 'packageSize' || el.name === 'volumePerUnit')
    ) {
      this.onFocusOut()
    }
  },

  render () {
    this.renderWithTemplate()

    this.form = new Form({
      model: this.model,
      autoRender: true,
      autoAppend: false,
      el: this.queryByHook('add-product-form'),

      submitCallback (obj) {
        this.model.set(obj)
        // save in the global collection, so we don't have to rely on the changes feed
        app.products.create(this.model, { wait: true })
      }
    })

    // registering the form view as a subview
    this.registerSubview(this.form)

    // focus name field and scroll into view
    window.requestAnimationFrame(() => {
      const form = /** @type {HTMLFormElement} */ (this.el)
      form.scrollIntoView({ behavior: 'smooth' })
      this.query('input[name=name]').focus()
    })
  }
})
