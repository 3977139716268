tr
  td
  td
    if model.product.deletedAt
      i(title=`${model.product.name} wurde gelöscht.`).is-deleted.ion-trash-a
    span(data-hook="name").item-name #{model.product.name}
  td.volume
    span(data-hook="packages-localized").packages #{model.packagesLocalized}
    span(data-hook="units-localized").units #{model.unitsLocalized}

  td.action(data-hook="dispatch-button")
