'use strict'

const View = require('ampersand-view')
const app = require('ampersand-app')
const SearchBarView = require('../search-bar')
const StockTakingCollection = require('../../models/stock-taking-collection')

const ListItemView = View.extend({
  template: require('./templates/stock-taking-list-item.pug').default,

  derived: {
    baseUrl: {
      cache: false,
      fn () {
        if (
          this.model.location.type === 'storage' ||
          this.model.location.type === 'crash'
        ) {
          return `/lager/${this.model.location.slug}/inventuren/${this.model.id}`
        } else {
          return `/bars/${this.model.location.slug}/inventuren/${this.model.id}`
        }
      }
    },
    editUrl: {
      cache: false,
      deps: ['baseUrl'],
      fn () {
        return `${this.baseUrl}/edit`
      }
    }
  }
})

module.exports = View.extend({
  template: require('./templates/stock-taking-page.pug').default,

  title () {
    return `Inventuren ${this.model.name}`
  },

  derived: {
    exportUrl: {
      cache: false,
      fn () {
        return `/api/events/${app.me.currentEvent.slug}/locations/${this.model.id}/inventories/export`
      }
    }
  },

  events: {
    'click [data-hook=create-inventory]': 'createNew'
  },

  subviews: {
    searchBar: {
      hook: 'search-bar',
      prepareView (el) {
        return new SearchBarView({
          el,
          parent: this,
          placeholder: 'Schichtname'
        })
      }
    },
    list: {
      hook: 'list',
      prepareView (el) {
        return this.renderCollection(this.collection, ListItemView, el)
      }
    }
  },

  initialize () {
    this.collection = new StockTakingCollection(null, { location: this.model })
    this.collection.fetch()
  },

  // will be called by SearchBarView
  search (query) {
    this.collection.byName(query)
  },

  createNew () {
    if (this.model.type === 'storage' || this.model.type === 'crash') {
      app.navigate(`/lager/${this.model.slug}/inventuren/neu`)
    } else {
      app.navigate(`/bars/${this.model.slug}/inventuren/neu`)
    }
  }
})
