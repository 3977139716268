'use strict'

const app = require('ampersand-app')
const View = require('ampersand-view')
const EventView = require('../event')
const Event = require('../../models/event')
const EventCollection = require('../../models/event-collection')

const EventSelector = View.extend({
  template: require('./event-selector.pug').default,

  events: {
    'change [data-hook="event-selector"]': 'onChange'
  },

  initialize () {
    this.collection = new EventCollection()
    this.listenTo(this.collection, 'sync', this.render)
    this.collection.fetch()
  },

  onChange (event) {
    // wait for app sync to redirect, so we know everything is loaded
    app.once('sync', () => app.navigate('/'))
    app.me.save({ currentEventId: event.target.value }, { wait: true })
  }
})

module.exports = View.extend({
  title: 'Willkommen',
  template: require('./welcome-page.pug').default,
  subviews: {
    event: {
      hook: 'create-event',
      prepareView (el) {
        return new EventView({ model: new Event(), el })
      }
    },

    eventsList: {
      hook: 'events-list',
      prepareView (el) {
        return new EventSelector({ el })
      }
    }
  },

  initialize () {
    this.model = app.me
  }
})
