'use strict'

const app = require('ampersand-app')
const View = require('ampersand-view')
const { default: tippy } = require('tippy.js')
const { DispatchButtonView } = require('../dispatch/DispatchModal')
const { TaskboardCheckboxView } = require('./TaskboardCheckbox')

module.exports = View.extend({
  template: require('./sidebar.pug').default,

  subviews: {
    dispatchButton: {
      hook: 'dispatch-button-placeholder',
      prepareView (el) {
        return new DispatchButtonView({ el })
      }
    },
    taskboardToggle: {
      hook: 'taskboard-toggle',
      prepareView (el) {
        return new TaskboardCheckboxView({ el })
      }
    }
  },

  bindings: {
    // toggle nav-buttons based on currentEventId
    'model.currentEventId': {
      selector: 'a.toggleable',
      type: 'booleanAttribute',
      no: 'disabled'
    }
  },

  initialize () {
    this.listenTo(app.changes, 'change:online', this.onConnectionChange)
  },

  onConnectionChange (changes, isOnline) {
    this.queryByHook('sync-indicator').classList.toggle('ok', isOnline)
  },

  remove () {
    if (this.tooltips) {
      this.tooltips.forEach((tip) => tip.destroy())
    }
    return View.prototype.remove.call(this)
  },

  render () {
    this.renderWithTemplate(this)

    if (!app.isMobile) {
      this.tooltips = tippy(this.queryAll('a[data-title]'), {
        placement: 'right'
      })
    }

    return this
  }
})
