import { useCurrentEventSlug, useCurrentRole, useLocations } from '../hooks'
import { PreactView } from '../preact-view'

export const UserSettingsView = PreactView.extend({
  component: UserSettings
})

function UserSettings ({ model }) {
  const eventSlug = useCurrentEventSlug()
  const role = useCurrentRole()
  const { storage, bars } = useLocations(eventSlug)

  return (
    <form
      class='pure-form pure-form-stacked'
      onBlur={(event) => {
        const data = Object.fromEntries(new FormData(event.currentTarget))
        model.save(data)
      }}
    >
      <fieldset>
        <legend>Name</legend>
        <div
          style={{
            display: 'grid',
            gap: '2em',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          <div>
            <label for='firstName'>Vorname</label>
            <div class='input-addon'>
              <input
                class='input-addon-field'
                type='text'
                name='firstName'
                defaultValue={model.firstName}
              />
            </div>
          </div>
          <div>
            <label for='lastName'>Nachname</label>
            <div class='input-addon'>
              <input
                class='input-addon-field'
                type='text'
                name='lastName'
                defaultValue={model.lastName}
              />
            </div>
          </div>
        </div>
      </fieldset>

      <div class='pure-g'>
        <div class='pure-u-1'>
          <div class='append'>
            Gib deinen Namen an, damit andere dich besser erkennen können. Er
            wird z.B. bei Transaktionen und Inventuren, die du erstellt hast,
            angezeigt.
          </div>
        </div>
      </div>

      {(role === 'admin' || role === 'dispatcher') && (
        <fieldset style={{ marginTop: '1em' }}>
          <legend>Dispatch Einstellungen</legend>
          <div
            style={{
              display: 'grid',
              gap: '2em',
              gridTemplateColumns: '1fr 1fr'
            }}
          >
            <div>
              <label for='defaultSourceLocationId'>Standardquelle</label>
              <select
                class='pure-u-1'
                name='defaultSourceLocationId'
                value={model.defaultSourceLocationId || ''}
              >
                <option value='' disabled>
                  Quelle wählen...
                </option>
                {storage && (
                  <optgroup label='Lager'>
                    {storage.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </optgroup>
                )}
                {bars && (
                  <optgroup label='Bars'>
                    {bars.map((bar) => (
                      <option key={bar.id} value={bar.id}>
                        {bar.name}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
            </div>
            <div>
              <label for='defaultTargetLocationId'>Standardziel</label>
              <select
                class='pure-u-1'
                name='defaultTargetLocationId'
                value={model.defaultTargetLocationId || ''}
              >
                <option value='' disabled>
                  Ziel wählen...
                </option>
                {bars && (
                  <optgroup label='Bars'>
                    {bars.map((bar) => (
                      <option key={bar.id} value={bar.id}>
                        {bar.name}
                      </option>
                    ))}
                  </optgroup>
                )}
                {storage && (
                  <optgroup label='Lager'>
                    {storage.map((store) => (
                      <option key={store.id} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
            </div>
          </div>
        </fieldset>
      )}
    </form>
  )
}
