'use strict'

const View = require('ampersand-view')
const app = require('ampersand-app')

const EventCollection = require('../../models/event-collection')
const EventModel = require('../../models/event-simple')

const EventView = require('./event-view')
const CreateEventBaseForm = require('../event')
const Form = require('./simple-form')
const { UserSettingsView } = require('./UserSettings')

const CreateEventForm = CreateEventBaseForm.extend({
  template: require('./templates/new-event.pug').default,
  initialize () {
    this.model = new EventModel()
    this.listenTo(this.model, 'sync', this.remove)
  }
})

/* Form for changing user's password */
const PasswordForm = Form.extend({
  template: require('./templates/password-form.pug').default,
  props: {
    oldPassword: ['string', true],
    newPassword: ['string', true],
    newPasswordConfirm: ['string', true]
  },

  derived: {
    valid: {
      deps: ['newPassword', 'newPasswordConfirm', 'oldPassword'],
      fn () {
        return (
          this.oldPassword &&
          this.newPassword &&
          this.newPasswordConfirm &&
          this.newPassword === this.newPasswordConfirm
        )
      }
    }
  },

  initialize () {
    this.on('change:valid', this.onValid, this)
    this.listenTo(this.model, 'sync', this.onSuccess)
    this.listenTo(this.model, 'error', this.onError)
  },

  onValid (model, isValid) {
    this.queryByHook('submit').disabled = !isValid
  },

  onError (err) {
    this.queryByHook('error').textContent = err.message
  },

  onSuccess () {
    this.queryByHook('error').textContent = ''
    this.el.reset()
  },

  submit (evt) {
    evt.preventDefault()
    this.model.changePassword(this.toJSON())
  }
})

/* Settings Page */
module.exports = View.extend({
  title: 'Einstellungen',

  template: require('./templates/settings-page.pug').default,

  events: {
    'click [data-hook="add"]': 'onAddEvent'
  },

  subviews: {
    nameForm: {
      hook: 'username-form',
      prepareView (el) {
        return new UserSettingsView({ el, props: { model: this.model } })
      }
    },

    passwordForm: {
      hook: 'password-form',
      prepareView (el) {
        return new PasswordForm({ el, model: this.model })
      }
    },

    eventsList: {
      hook: 'event-list',
      prepareView (el) {
        return this.renderCollection(this.eventsCollection, EventView, el)
      }
    }
  },

  props: {
    createEvent: ['boolean', true, false]
  },

  initialize () {
    this.model = app.me
    // collection fetches itself
    this.eventsCollection = new EventCollection()
    this.on('change:createEvent', this.toggleEventForm, this)
  },

  onAddEvent (event) {
    event.preventDefault()
    this.createEvent = true
  },

  toggleEventForm (model, createEvent) {
    this.queryByHook('add').classList.toggle('hidden', createEvent)
    if (createEvent) {
      this.createEventForm = new CreateEventForm({ parent: this })
      this.listenToOnce(this.createEventForm.model, 'sync', (newEvent) => {
        this.eventsCollection.add(newEvent)
        this.createEvent = false
      })
      this.renderSubview(this.createEventForm, '[data-hook="create-event"]')
    } else {
      this.createEventForm.remove()
    }
  }
})
