'use strict'

const Model = require('ampersand-model')
const app = require('ampersand-app')
const Big = require('big.js')

const WeightCollection = require('./weight-collection')
const {
  packagesLocalized,
  unitsLocalized,
  localizedUnit,
  localizedPackage
} = require('../utils')

module.exports = Model.extend({
  props: {
    id: {
      type: 'string',
      setOnce: true
    },
    productId: {
      type: 'string',
      required: true
    },
    units: {
      type: 'number',
      required: true,
      default: 0
    }
  },

  collections: {
    weight: WeightCollection
  },

  session: {
    inputPackages: ['number', false, 0],
    inputUnits: ['number', false, 0],
    stockUnits: ['number', false, 0],
    weightUnits: ['number', false, 0],
    unitsLastInventory: ['number', false, 0],
    unitsSinceInventory: ['number', false, 0],
    lastInventoryId: ['string'],
    lastInventoryTime: ['date']
  },

  derived: {
    product: {
      deps: ['productId'],
      fn () {
        return app.products.get(this.productId)
      }
    },

    unitsSold: {
      deps: ['stockUnits', 'units'],
      fn () {
        return this.stockUnits - this.units
      }
    },

    isUnitsSoldNegative: {
      deps: ['unitsSold'],
      fn () {
        return this.unitsSold < 0
      }
    },

    inputUnitsLocalized: localizedUnit('inputUnits'),
    inputPackagesLocalized: localizedPackage('inputPackages'),

    unitsLocalized: unitsLocalized('units'),
    packagesLocalized: packagesLocalized('units'),

    unitsSoldLocalized: unitsLocalized('unitsSold'),
    packagesSoldLocalized: packagesLocalized('unitsSold'),

    unitsLastInventoryLocalized: unitsLocalized('unitsLastInventory'),
    packagesLastInventoryLocalized: packagesLocalized('unitsLastInventory'),

    unitsSinceInventoryLocalized: unitsLocalized('unitsSinceInventory'),
    packagesSinceInventoryLocalized: packagesLocalized('unitsSinceInventory')
  },

  initialize () {
    this.listenToAndRun(
      this,
      'change:inputPackages change:inputUnits change:weightUnits',
      this.onAmountChange
    )
    this.listenToAndRun(this.weight, 'change add remove', this.onWeightChange)
  },

  parse (res) {
    if (Array.isArray(res.weight)) {
      res.weight = res.weight.map((weight) => {
        return { value: weight, productId: res.productId }
      })
    }
    return res
  },

  onAmountChange () {
    this.units =
      this.inputPackages * this.product.packageSize +
      this.inputUnits +
      this.weightUnits
  },

  onWeightChange () {
    if (!this.product) return null

    if (!this.weight || !this.weight.length) {
      this.weightUnits = 0
      return null
    }

    const item = this
    this.weightUnits = +this.weight
      .map((weight) => new Big(weight.value).div(item.product.weightFull))
      .reduce((a, b) => a.plus(b))
  }
})
