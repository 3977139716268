'use strict'

const app = require('ampersand-app')
const Model = require('ampersand-model')
const utils = require('../utils')

const { packaging } = require('./packages')

function gtZero (val) {
  if (val < 0) return 'Must be greater than 0'
}

function currency (key) {
  return {
    deps: [key],
    fn () {
      return utils.currency(this[key])
    }
  }
}

module.exports = Model.extend({
  urlRoot () {
    return `/api/events/${app.me.currentEvent.slug}/products`
  },

  initialize (data, opts) {
    opts = opts || {}
  },

  props: {
    id: {
      type: 'string',
      setOnce: true
    },
    deletedAt: ['date'],
    name: ['string', true],
    packageId: ['string', true],
    packageSize: {
      type: 'number'
      // test: gtZero
    },
    unitId: ['string', true],
    costUnit: {
      type: 'string',
      required: true,
      values: ['package', 'unit']
    },

    volumePerUnit: ['number', true],
    weightEmpty: ['number', false],
    weightFull: ['number', false],
    servingSize: ['number', true],
    canSpill: ['boolean'],

    // monetary properties in €
    cost: {
      type: 'string',
      required: true,
      test: gtZero
    },
    price: {
      type: 'string',
      required: true,
      test: gtZero
    },

    sortPosition: ['number', false],
    factor: ['number', true, 1],
    costPerUnit: ['number'],
    pricePerUnit: ['number']
  },

  session: {
    editable: ['boolean', true, false]
  },

  derived: {
    slug: {
      deps: ['name'],
      fn () {
        return utils.slugify(this.name)
      }
    },
    costLocalized: currency('cost'),
    priceLocalized: currency('price'),
    costPerUnitLocalized: currency('costPerUnit'),
    pricePerUnitLocalized: currency('pricePerUnit'),

    servingSizeLocalized: {
      deps: ['servingSize'],
      fn () {
        return utils.localizeServingSize(this.servingSize)
      }
    },

    package: {
      deps: ['packageId'],
      fn () {
        return (packaging.get(this.packageId) || {}).name
      }
    },

    unit: {
      deps: ['unitId'],
      fn () {
        return (packaging.get(this.unitId) || {}).name
      }
    },

    costUnitId: {
      deps: ['costUnit', 'packageId', 'unitId'],
      fn () {
        const pkg = packaging.get(this[this.costUnit + 'Id']) || {}
        return pkg.id || this.costUnit
      }
    },

    costUnitLocalized: {
      deps: ['costUnit', 'packageId', 'unitId'],
      fn () {
        const pkg = packaging.get(this[this.costUnit + 'Id']) || {}
        return pkg.name || this.costUnit
      }
    },

    _nameIndex: {
      deps: ['name'],
      fn () {
        return utils.indexify(this.name)
      }
    }
  }
})
