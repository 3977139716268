'use strict'

const app = require('ampersand-app')
const { currency, net, vat } = require('../utils')
const LocationBase = require('./location-base-model')

module.exports = LocationBase.extend({
  initialize () {
    this.listenToAndRun(this.stock, 'change', this.onChange)
    this.listenTo(
      app.me.currentEvent,
      'change:tokenValue',
      (event, tokenValue) => {
        this.eventTokenValue = tokenValue
      }
    )
  },

  props: {
    type: ['string', true, 'bar']
  },

  session: {
    sumSalesGrossValue: ['number'],
    sumSalesGross: ['string'],
    sumSalesNet: ['string'],
    sumSalesGrossAfterSpillage: ['string'],
    sumSalesGrossAfterSpillageValue: ['number'],
    sumSalesNetAfterSpillage: ['string'],
    vat: ['string'],
    vatAfterSpillage: ['string'],
    tokens: ['number', true, 0],
    eventTokenValue: {
      type: 'number',
      default () {
        return app.me.currentEvent.tokenValue
      }
    }
  },

  derived: {
    tokensValue: {
      deps: ['tokens', 'eventTokenValue'],
      fn () {
        return this.tokens * this.eventTokenValue
      }
    },
    sumSalesGrossAfterSpillageWithoutTokens: {
      deps: ['sumSalesGrossAfterSpillageValue', 'tokensValue'],
      fn () {
        return currency(this.sumSalesGrossAfterSpillageValue - this.tokensValue)
      }
    },
    sumSalesNetAfterSpillageWithoutTokens: {
      deps: ['sumSalesGrossAfterSpillageValue', 'tokensValue'],
      fn () {
        return currency(
          net(this.sumSalesGrossAfterSpillageValue - this.tokensValue)
        )
      }
    },
    vatAfterSpillageWithoutTokens: {
      deps: ['sumSalesGrossAfterSpillageValue', 'tokensValue'],
      fn () {
        const netValue = net(
          this.sumSalesGrossAfterSpillageValue - this.tokensValue
        )
        return currency(
          vat(netValue, this.sumSalesGrossAfterSpillageValue - this.tokensValue)
        )
      }
    }
  },

  onChange () {
    this.sumSalesGrossValue = this.stock
      .map((item) => item.salesGross)
      .reduce((a, b) => a + b, 0)
    this.sumSalesGross = currency(this.sumSalesGrossValue)

    const sumSalesNet = net(this.sumSalesGrossValue)
    this.sumSalesNet = currency(sumSalesNet)
    this.vat = currency(vat(sumSalesNet, this.sumSalesGrossValue))

    this.sumSalesGrossAfterSpillageValue = this.stock
      .map((item) => item.salesGrossAfterSpillage)
      .reduce((a, b) => a + b, 0)
    this.sumSalesGrossAfterSpillage = currency(
      this.sumSalesGrossAfterSpillageValue
    )

    const sumSalesNetAfterSpillage = net(this.sumSalesGrossAfterSpillageValue)
    this.sumSalesNetAfterSpillage = currency(sumSalesNetAfterSpillage)
    this.vatAfterSpillage = currency(
      vat(sumSalesNetAfterSpillage, this.sumSalesGrossAfterSpillageValue)
    )
  }
})
