'use strict'

const domify = require('domify')
const app = require('ampersand-app')
const popover = (module.exports = {})

function offset (box, doc) {
  const body = doc.body || doc.getElementsByTagName('body')[0]
  const docEl = doc.documentElement || body.parentNode
  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0
  const scrollTop = window.pageYOffset || docEl.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft

  return {
    top: box.top + scrollTop - clientTop,
    left: box.left + scrollLeft - clientLeft
  }
}

popover.render = function (opts = { show: true }) {
  this.el = domify(this.template(this))

  // append to the app view so we get proper link handling
  app.view.el.appendChild(this.el)

  this.opts.position = this.opts.position || 'right'
  this.position(this.opts.position)

  this._listenOnResize()
  this.closeButton = this.el.querySelector('[data-hook=popover-close]')
  if (this.closeButton) {
    this.closeButton.addEventListener('click', this.remove.bind(this), false)
  }

  if (opts.show) this.el.classList.add(opts.className || 'open')
  this.trigger('shown')
  return this
}

popover.remove = function (event) {
  if (event && typeof event.preventDefault === 'function') { event.preventDefault() }
  // this.button = null
  if (this.el.parentNode) {
    this.el.parentNode.removeChild(this.el)
  }

  this.trigger('removed')

  // remove all event listeners
  this.off()
  this.stopListening()
  // this.button.removeEventListener('click', this.remove, false)
  if (this.closeButton) {
    this.closeButton.removeEventListener('click', this.remove.bind(this), false)
    this.closeButton = null
  }
  window.removeEventListener('resize', this._resizeListener, false)
}

/**
 * Attach popover to element `el`
 * @param {String|Element} el
 * @api public
 */
popover.setButton = function (el) {
  el = typeof el === 'string' ? document.querySelector(el) : el
  this.button = el
  this.buttonCoords = this.button.getBoundingClientRect()

  // this.button.addEventListener('click', this.remove.bind(this), false)

  return this
}

popover.position = function (pos) {
  let x, y

  this.el.classList.add('popover-' + pos)
  this._rect = this.el.getBoundingClientRect()
  const to = offset(this.buttonCoords, document)

  if (pos === 'top' || pos === 'bottom') {
    x = this._calculateX()
    y =
      pos === 'top'
        ? to.top - this._rect.height
        : to.top + this.buttonCoords.height

    this.el.style[this.opts.align || 'left'] = Math.round(x) + 'px'
    this.el.style.top = Math.round(y) + 'px'

    // position arrow accordingly
    this._positionArrow('left', this.el.getBoundingClientRect().left)
  }

  if (pos === 'right' || pos === 'left') {
    x =
      pos === 'right'
        ? to.left + this.buttonCoords.width
        : to.left - this._rect.width
    y = this._calculateY()

    this.el.style.left = Math.round(x) + 'px'
    this.el.style.top = Math.round(y) + 'px'

    // position arrow accordingly
    this._positionArrow('top', y)
  }

  return this
}

popover._listenOnResize = function () {
  const self = this
  this._resizeListener = function () {
    self.buttonCoords = self.button.getBoundingClientRect()
    self.position(self.opts.position)
  }
  window.addEventListener('resize', this._resizeListener, false)
}

popover._positionArrow = function (direction, offset) {
  const arrow = this.el.querySelector('.popover-arrow')
  if (!arrow) return
  const size = direction === 'top' ? 'height' : 'width'
  const pos =
    this.buttonCoords[direction] + this.buttonCoords[size] / 2 - offset
  arrow.style[direction] = Math.round(pos) + 'px'
}

popover._calculateY = function () {
  if (!this._rect) this._rect = this.el.getBoundingClientRect()
  const height = this._rect.height

  // center relative to the button
  let top = this.buttonCoords.top + (this.buttonCoords.height - height) / 2

  this._autoAlign(top)

  if (this.opts.align === 'top') top = this.buttonCoords.top
  return top
}

popover._calculateX = function () {
  if (!this._rect) this._rect = this.el.getBoundingClientRect()
  const width = this._rect.width

  // center relative to the button
  let x = this.buttonCoords.left + (this.buttonCoords.width - width) / 2

  this._autoAlign(x)

  if (this.opts.align === 'left') x = this.buttonCoords.left
  if (this.opts.align === 'right') {
    x = window.innerWidth - (this.buttonCoords.left + this.buttonCoords.width)
  }
  return x
}

popover._autoAlign = function (v) {
  if (!this._rect) this._rect = this.el.getBoundingClientRect()

  const width = this._rect.width
  const pos = this.opts.position
  const isVertical = pos === 'top' || pos === 'bottom'

  if (isVertical && v + width > window.innerWidth) this.opts.align = 'right'
  if (isVertical && v < 0) this.opts.align = 'left'
  if (!isVertical && v < 0) this.opts.align = 'top'
  // this.opts.align = 'bottom'

  return this
}
