.stock-taking-page
  .pure-g
    .pure-u-1-2
      h1 Inventuren für #{model.name}
    .pure-u-1-2
      .flex-center
        .cell
          div(data-hook="search-bar")
        .cell.btn-group
          a(href=exportUrl, title="Inventuren herunterladen...", target="_blank").btn-round.pure-button
            i.icon.ion-ios-cloud-download
          button(data-hook="create-inventory", data-title="Inventur erstellen...").btn-round.pure-button
            i.icon.ion-plus

  .pure-g
    .pure-u-1
      table.pure-table
        thead
          tr
            th Schicht
            th Datum
            th Umsatz
            th Betrag erhalten
            //- th
        tbody(data-hook="list")
