'use strict'

const { default: StockTaking } = require('./stock-taking')
const StockTakingItems = require('./stock-taking-item-collection')

module.exports = StockTaking.extend({
  collections: {
    items: StockTakingItems
  },

  initialize () {
    this.listenToAndRun(this.items, 'change:unitsSold', this.onChange)
    this.listenToAndRun(this, 'change:tokens', this.onChange)
  },

  prefill (shiftName) {
    this.shiftName = shiftName
    this.items.each((item) => {
      item.inputUnits =
        Math.round((item.stockUnits % item.product.packageSize) * 100) / 100
      const packages = item.stockUnits / item.product.packageSize
      item.inputPackages =
        item.stockUnits < 0 ? Math.ceil(packages) : Math.floor(packages)
    })
  },

  onChange () {
    this.sumSold = this.items
      .map((item) => item.unitsSold * item.product.pricePerUnit)
      .reduce((a, b) => a + b, 0)
  }
})
