.stock-taking-page.stock-taking-page__details
  .pure-g
    .pure-u-3-4
      h1
        a(href=backUrl).btn-back: i.ion-ios-arrow-back
        span #{model.shiftName || 'Schicht'} #{location.name}
      .append Schichtende: #{model.shiftEndTimeLocalized}
    .pure-u-1-4
      .buttons.btn-group
        a(href=exportUrl, title="Inventur herunterladen...", target="_blank").btn-round.pure-button
          i.icon.ion-ios-cloud-download
        a(href=editUrl, data-title="Inventur bearbeiten...").btn-round.pure-button
          i.icon.ion-edit
        div(data-hook="delete")

  .pure-g
    .pure-u-1
      h2.stock-taking-section-header
        span Bestände
        div(data-hook="search-bar")
  .pure-g
    .pure-u-1
      table.pure-table.table-striped
        thead
          tr
            th Produkt
            th Anfangsbestand
            th Nachlieferung
            th Endbestand
            th Verkauf

        tbody(data-hook="item-list")

  .pure-g
    .pure-u-1
      h2.stock-taking-section-header Informationen

  .pure-g
    .pure-u-2-3
      h3 Umsatz
      .pure-u-3-4
        ul.inventory-results
          li
            span.inventory-results__operator
            span.inventory-results__value(class=model.sumSold < 0 ? 'error' : null) #{model.sumSoldLocalized}
            span.inventory-results__descr Umsatz
          li
            span.inventory-results__operator -
            span.inventory-results__value(class=model.tokens < 0 ? 'error' : null) #{model.tokensLocalized}
            span.inventory-results__descr #{model.tokens} Personalmarke#{model.tokens > 1 || !model.tokens ? 'n' : ''}
          li.inventory-results__result
            span.inventory-results__operator =
            span.inventory-results__value(class=model.salesWithoutTokens < 0 ? 'error' : null) #{model.salesWithoutTokensLocalized}
            span.inventory-results__descr Umsatz abzgl. Personalmarken
      .pure-u-3-4
        ul.inventory-results
          li
            span.inventory-results__operator
            span.inventory-results__value(class=model.amountGiven < 0 ? 'error' : null) #{model.amountGivenLocalized}
            span.inventory-results__descr Betrag erhalten
          li
            span.inventory-results__operator -
            span.inventory-results__value(class=model.salesWithoutTokens < 0 ? 'error' : null) #{model.salesWithoutTokensLocalized}
            span.inventory-results__descr Umsatz abzgl. Personalmarken
          li.inventory-results__result
            span.inventory-results__operator =
            span.inventory-results__value(class=model.differenceWithoutTokens < 0 ? 'error' : null) #{model.differenceWithoutTokensLocalized}
            span.inventory-results__descr Differenz

    .pure-u-1-3
      h3 Kommentar
      .comment-field-wrap
        .arrow
        .comment-field
          if model.comment
            - var lines = model.comment.split('\n')
            each line in lines
              span #{line} <br>
          else
            .append Kein Kommentar hinterlegt.

