import { useEffect, useMemo, useRef, useState } from 'preact/hooks'
import useSWR from 'swr'
import { Dialog } from '@headlessui/react'
import { PreactView } from '../preact-view'
import app from 'ampersand-app'
import { packaging } from '../../models/packages'
import keyBy from 'lodash/keyBy'

import { logger } from '../../utils'
import {
  bulkCreateTransactions,
  useCurrentEventSlug,
  useCurrentRole,
  useLocations,
  useTransactions
} from '../hooks'
import { Modal } from '../modal/Modal'
import { TextButton } from '../text-button/TextButton'
import { Combobox } from '../combobox/Combobox'

import * as styles from './dispatch-modal.module.css'

const log = logger.extend('dispatch')

function DispatchButton () {
  const [isOpen, setIsOpen] = useState(false)
  const initialFocus = useRef()

  return (
    <>
      <button
        className='pure-button pure-button-primary btn-round dispatch'
        onClick={() => setIsOpen(true)}
      >
        <i className='ion-paper-airplane' />
      </button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        initialFocus={!app.isMobile ? initialFocus : undefined}
      >
        <DispatchForm
          onClose={() => setIsOpen(false)}
          initialFocus={initialFocus}
        />
      </Modal>
    </>
  )
}

export const DispatchButtonView = PreactView.extend({
  component: DispatchButton
})

function DispatchForm ({ onClose, initialFocus }) {
  const eventSlug = useCurrentEventSlug()
  const { mutate: mutateTransactions } = useTransactions()

  const role = useCurrentRole()
  const { locations, storage, bars } = useLocations(eventSlug)
  /** @type {import('swr').SWRResponse<import('../types').Product[]>} */
  const { data: products } = useSWR(`/api/events/${eventSlug}/products`)

  const productsById = keyBy(products || [], 'id')
  const productOptions = useMemo(
    () =>
      products?.map((product) => ({
        value: product.id,
        label: product.name
      })) ?? [],
    [products]
  )

  const [sourceId, setSourceId] = useState('')
  const [targetId, setTargetId] = useState('')
  const [comment, setComment] = useState('')
  const [transactions, setTransactions] = useState(
    /**
     * @type {{
     *   productId: string
     *   inputPackages: number
     *   inputUnits: number
     * }[]}
     */ ([{ productId: '', inputPackages: 0, inputUnits: 0 }])
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(
    function setInitialSource () {
      if (!sourceId && storage && locations) {
        const source = app.me.defaultSourceLocationId
          ? locations.find(
            (store) => store.id === app.me.defaultSourceLocationId
          )
          : storage[0]
        if (source) {
          setSourceId(source.id)
          log(`set initial source: ${source.name} (${source.id})`)
        }
      }
    },
    [locations, storage, sourceId]
  )

  // set target for user's with assigned location (e.g. bar bosses)
  useEffect(
    function setInitialTarget () {
      const locationId =
        app.me.defaultTargetLocationId || app.me.assignedLocationId
      if (!targetId && locations && locationId) {
        const target = locations.find((l) => l.id === locationId)
        if (target) {
          setTargetId(target.id)
          log(`set initial target: ${target.name} (${target.id})`)
        }
      }
    },
    [locations, targetId]
  )

  const isValid = Boolean(
    sourceId &&
      targetId &&
      transactions.some(
        (tx) => tx.productId && (tx.inputPackages > 0 || tx.inputUnits > 0)
      )
  )

  return (
    <form
      class='pure-form'
      onSubmit={async (event) => {
        event.preventDefault()

        setIsSubmitting(true)
        const payload = transactions
          .filter(
            (tx) => tx.productId && (tx.inputPackages > 0 || tx.inputUnits > 0)
          )
          .map((tx) => ({
            sourceId,
            targetId,
            productId: tx.productId,
            units:
              tx.inputPackages * productsById[tx.productId].packageSize +
              tx.inputUnits,
            comment
          }))

        try {
          await bulkCreateTransactions(eventSlug, payload)
          await mutateTransactions()
          onClose()
        } catch (error) {
          console.error(error)
        } finally {
          setIsSubmitting(false)
        }
      }}
    >
      <fieldset>
        <Dialog.Title as='legend'>
          {role === 'bar'
            ? `Produkte anfordern für ${
                locations?.find((l) => l.id === targetId)?.name
              }`
            : 'Produkte dispatchen'}
        </Dialog.Title>

        <div class={styles.route}>
          <div>
            <label for='source'>Von</label>
            <select
              class='pure-input-1'
              name='source'
              value={sourceId}
              onChange={(event) => setSourceId(event.target.value)}
              ref={initialFocus}
              required
            >
              {storage && (
                <optgroup label='Lager'>
                  {storage.map((store) => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </optgroup>
              )}
              {bars && (
                <optgroup label='Bars'>
                  {bars.map((bar) => (
                    <option key={bar.id} value={bar.id}>
                      {bar.name}
                    </option>
                  ))}
                </optgroup>
              )}
            </select>
          </div>

          <div>
            <label for='target'>Nach</label>
            <select
              class='pure-input-1'
              name='target'
              value={targetId}
              onChange={(event) => setTargetId(event.target.value)}
              required
            >
              <option disabled value=''>
                Ziel wählen...
              </option>
              {bars && (
                <optgroup label='Bars'>
                  {bars.map((bar) => (
                    <option key={bar.id} value={bar.id}>
                      {bar.name}
                    </option>
                  ))}
                </optgroup>
              )}
              {storage && (
                <optgroup label='Lager'>
                  {storage.map((store) => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </optgroup>
              )}
            </select>
          </div>
        </div>
      </fieldset>

      <fieldset>
        {transactions.map((tx, i) => (
          <div key={i} class={styles.dispatchItemRow}>
            <Combobox
              name='productId'
              placeholder='Produkt auswählen...'
              value={tx.productId}
              options={productOptions}
              onChange={(option) => {
                const newTransactions = [...transactions]
                newTransactions[i] = {
                  ...newTransactions[i],
                  productId: option
                }
                setTransactions(newTransactions)
              }}
            />

            <div>
              <input
                class='pure-input-1'
                name='inputPackages'
                type='number'
                min='0'
                placeholder='0'
                value={tx.inputPackages || ''}
                onChange={(event) => {
                  const newTransactions = [...transactions]
                  newTransactions[i] = {
                    ...newTransactions[i],
                    inputPackages: event.target.valueAsNumber
                  }
                  setTransactions(newTransactions)
                }}
              />
              <span class='append'>
                {productsById && tx.productId
                  ? packaging.get(productsById[tx.productId].packageId).name
                  : 'Kiste'}
              </span>
            </div>
            <div>
              <input
                class='pure-input-1'
                name='inputUnits'
                type='number'
                min='0'
                step='any'
                placeholder='0'
                value={tx.inputUnits || ''}
                onChange={(event) => {
                  const newTransactions = [...transactions]
                  newTransactions[i] = {
                    ...newTransactions[i],
                    inputUnits: event.target.valueAsNumber
                  }
                  setTransactions(newTransactions)
                }}
              />
              <span class='append'>
                {productsById && tx.productId
                  ? packaging.get(productsById[tx.productId].unitId).name
                  : 'Flasche'}
              </span>
            </div>

            <a
              class='dispatch-item-remove'
              href='#'
              title='Entfernen...'
              role='button'
              tabIndex={-1}
              onClick={(event) => {
                event.preventDefault()
                setTransactions(transactions.filter((tx, index) => index !== i))
              }}
            >
              <i class='ion-ios-minus-outline' />
            </a>
          </div>
        ))}
      </fieldset>

      <div class={styles.buttons}>
        <TextButton
          onClick={(event) => {
            event.preventDefault()
            setTransactions([
              ...transactions,
              { productId: '', inputPackages: 0, inputUnits: 0 }
            ])
          }}
        >
          Weiteres Produkt hinzufügen...
        </TextButton>

        <div style={{ alignSelf: 'stretch' }}>
          <label htmlFor='comment'>Kommentar</label>
          <textarea
            name='comment'
            rows={3}
            style={{ width: '100%' }}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </div>

        <button
          class='pure-button pure-button-primary'
          type='submit'
          disabled={!isValid || isSubmitting}
        >
          Abschicken
        </button>
      </div>
    </form>
  )
}
