import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'preact'
import * as styles from './modal.module.css'

/**
 * Note: this component's markup is structured in a way that an "outside click"
 * does **not** close the modal. This is a workaround, because headlessui does
 * not currently support disabling the outside click behavior.
 *
 * @param {{
 *   isOpen: boolean
 *   onClose(): void
 *   children: any
 *   initialFocus?: import('preact/hooks').MutableRef<HTMLElement | undefined>
 * }} props
 */
export function Modal ({ isOpen, onClose, children, initialFocus }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        onClose={onClose}
        className={styles.dialog}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter={styles.backdropEnter}
          enterFrom={styles.backdropEnterFrom}
          enterTo={styles.backdropEnterTo}
          leave={styles.backdropLeave}
          leaveFrom={styles.backdropLeaveFrom}
          leaveTo={styles.backdropLeaveTo}
        >
          <div className={styles.backdrop} aria-hidden='true' />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter={styles.panelEnter}
          enterFrom={styles.panelEnterFrom}
          enterTo={styles.panelEnterTo}
          leave={styles.panelLeave}
          leaveFrom={styles.panelLeaveFrom}
          leaveTo={styles.panelLeaveTo}
        >
          <Dialog.Panel className={styles.wrapper}>
            <div className={styles.dialogPanel}>
              <button class={styles.closeButton} onClick={onClose}>
                <i class='ion-close-round' />
              </button>

              {children}
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
