'use strict'

const User = require('./user')
const Event = require('./event')
const pick = require('lodash/pick')
const app = require('ampersand-app')

module.exports = User.extend({
  url: '/api/user',

  props: {
    currentEventId: 'string',
    assignedLocationId: 'string',
    defaultSourceLocationId: 'string',
    defaultTargetLocationId: 'string'
  },

  children: {
    currentEvent: Event
  },

  async changePassword (data) {
    let json
    try {
      json = JSON.stringify(
        pick(data, 'oldPassword', 'newPassword', 'newPasswordConfirm')
      )
    } catch (err) {
      console.error(err)
      this.trigger('error', err)
    }
    try {
      const resp = await fetch(new URL(this.url, window.location.origin), {
        body: json,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }
      })
      const body = await resp.json()

      if (!resp.ok) {
        this.trigger('error', body)
        return
      }
      this.trigger('sync', this)
    } catch (err) {
      return this.trigger('error', err)
    }
  },

  async login (username, password) {
    try {
      const resp = await fetch(new URL('/api/login', window.location.origin), {
        body: JSON.stringify({ username, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
      const body = await resp.json()

      if (!resp.ok) {
        this.trigger('error', body)
        return
      }
      this.set(body)
      this.trigger('sync', this)
    } catch (err) {
      return this.trigger('error', err)
    }
  },

  async signup (username, password) {
    try {
      const resp = await fetch(new URL('/api/signup', window.location.origin), {
        body: JSON.stringify({ username, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
      const body = await resp.json()

      if (!resp.ok) {
        this.trigger('error', body)
        return
      }
      this.set(body)
      this.trigger('sync', this)
    } catch (err) {
      return this.trigger('error', err)
    }
  },

  async logout () {
    try {
      const resp = await fetch(new URL('/api/logout', window.location.origin))

      if (!resp.ok) {
        this.trigger('error', new Error('logout failed'))
        return
      }
      app.clear()
      window.location.href = '/'
    } catch (err) {
      return this.trigger('error', err)
    }
  }
})
