header.location__header.pure-g
  .pure-u-2-3
    if editable
      i(data-hook="drag-handle", title="Zum Sortieren ziehen").ion-drag.drag-handle
    h2
      if !name || editable
        - var placeholder = type === 'bar' ? 'Neue Bar' : 'Neues Lager'
        input(type="text", value=name, placeholder=placeholder, autocomplete="off", name="location-name").empty.location-name
        if !id && (type === 'storage' || type === 'crash')
          form.pure-form
            label.pure-checkbox
              input(type="checkbox", checked=type === 'crash', data-hook="is-crash")
              | Bruchlager
      else
        .location-name #{name}
  .pure-u-1-3
    div(data-hook="buttons")
