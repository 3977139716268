'use strict'

const View = require('ampersand-view')
const assign = require('lodash/assign')

const { isAdminProp } = require('../../utils')
const SearchBarView = require('../search-bar')
const sortableMixin = require('../sortable-table-mixin')
const DashboardProductCollection = require('../../models/dashboard-products')
const { default: TransactionsChart } = require('./transactions-chart')
const ProductsChart = require('./products-chart')
const BestBars = require('./best-bars-card')
const BestProducts = require('./best-products-card')

const ItemView = View.extend({
  template: require('./product-details-item.pug').default
})

const ProductDetails = View.extend(sortableMixin, {
  template: require('./product-details.pug').default,

  subviews: {
    list: {
      hook: 'list',
      prepareView (el) {
        return this.renderCollection(this.collection, ItemView, el)
      }
    },
    searchBar: {
      hook: 'search-bar',
      prepareView (el) {
        return new SearchBarView({ el, parent: this })
      }
    }
  },

  initialize () {
    this.initSortableTable(this.collection.state)
  },

  events: assign({}, sortableMixin.events, {
    'click [data-hook="sort-ratio"]': 'sortByRatio',
    'click [data-hook="sort-delivery"]': 'sortByDelivery'
  }),

  setComparator (comparator) {
    this.collection.setComparator(comparator)
  },

  // this will be called by SearchBarView
  search (query) {
    this.collection.byName(query)
  }
})

module.exports = View.extend({
  title: 'Übersicht',
  template: require('./dashboard-page.pug').default,

  events: {
    'click [data-hook="toggle-product-details"]': 'toggleProductDetails'
  },

  props: {
    showProductDetails: ['boolean', true, false],
    isAdmin: isAdminProp
  },

  derived: {
    buttonText: {
      deps: ['showProductDetails'],
      fn () {
        return this.showProductDetails
          ? 'Details ausblenden...'
          : 'Details einblenden...'
      }
    }
  },

  bindings: {
    buttonText: {
      hook: 'toggle-product-details'
    }
  },

  subviews: {
    productsChart: {
      hook: 'products',
      prepareView (el) {
        return new ProductsChart({ el, collection: this.products })
      }
    },

    bestBars: {
      hook: 'best-bars',
      constructor: BestBars
    },

    bestProducts: {
      hook: 'best-products',
      constructor: BestProducts
    },

    transactionsChart: {
      hook: 'transactions',
      constructor: TransactionsChart
    }
  },

  initialize () {
    this.products = new DashboardProductCollection()
    this.products.fetch()
  },

  toggleProductDetails (evt) {
    evt.preventDefault()
    this.toggle('showProductDetails')
    if (this.showProductDetails) {
      this.productDetails = new ProductDetails({ collection: this.products })
      // this.productLocations = new ProductsLocations({ collection: this.products })
      this.renderSubview(
        this.productDetails,
        this.queryByHook('product-details')
      )
      // this.renderSubview(this.productLocations, this.queryByHook('product-locations'))
    } else {
      this.productDetails.remove()
      this.productLocations.remove()
    }
  }
})
