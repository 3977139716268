
form.pure-form.pure-form-stacked.stock-taking-meta-form
  .pure-g
    .pure-u-2-3
      .pure-g
        .pure-u-1-4
          label(for="tokens") Personalmarken
          input(type="number", name="tokens", step=1, min=0, placeholder=0, value=model.tokens || null)
        .pure-u-1-4
        .pure-u-1-4
          label(for="amountGiven") Betrag erhalten
          .input-addon
            input(type="number", name="amountGiven", step="0.01", min=0, placeholder=0, value=model.amountGiven || null).input-addon-field
            span.input-addon-item €
      if isAdmin
        .pure-g
          .pure-u-3-4
            ul.inventory-results
              li
                span.inventory-results__operator
                span.inventory-results__value(data-hook="sum-sold")
                span.inventory-results__descr Umsatz
              li
                span.inventory-results__operator -
                span.inventory-results__value(data-hook="tokens-localized")
                span.inventory-results__descr <span data-hook="tokens"></span> Personalmarke#{model.tokens > 1 || !model.tokens ? 'n' : ''}
              li.inventory-results__result
                span.inventory-results__operator =
                span.inventory-results__value(data-hook="sales-without-tokens")
                span.inventory-results__descr Umsatz abzgl. Personalmarken
              li.inventory-results__result
                span.inventory-results__operator =
                span.inventory-results__value(data-hook="difference")
                span.inventory-results__descr Differenz

    .pure-u-1-3
      label(for="barChef") Barchef
      input(type="text", name="barChef", value=model.barChef)

      label(for="comment") Kommentar
      textarea(name="comment", rows="3").pure-u-1 #{model.comment}

  .buttons
    .btn-group
      button(type="reset", title="Abbrechen (Esc)", data-hook="cancel").pure-button
        i.icon.ion-close
      button(type="submit", data-hook="submit").pure-button.button-success Inventur speichern
