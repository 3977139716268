'use strict'

const View = require('ampersand-view')
const DispatchView = require('../dispatch/dispatch-view-simple')
const PopoverButton = require('../popover-button')
const { isAdminProp } = require('../../utils')

module.exports = View.extend({
  template: require('./storage-item.pug').default,
  crashTemplate: require('./crash-item.pug').default,

  props: {
    isAdmin: isAdminProp
  },

  initialize () {
    this.listenTo(this.model, 'change', this.render)
  },

  render () {
    const type = this.parent.model.type
    const template = type === 'crash' ? this.crashTemplate : this.template
    this.renderWithTemplate(this, template)

    if (type !== 'crash') {
      this.renderSubview(
        new PopoverButton({
          icon: 'ion-paper-airplane',
          title: this.model.product.name + ' dispatchen...',
          classes: 'pure-button-primary',
          Popover: DispatchView,
          popoverOpts: {
            sourceId: this.parent.model.id,
            productId: this.model.productId,
            position: 'bottom'
          }
        }),
        '[data-hook="dispatch-button"]'
      )
    }
    return this
  }
})
