'use strict'

function transitionEnd () {
  const el = document.createElement('bootstrap')

  const transEndEventNames = {
    transition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
    MozTransition: 'transitionend',
    OTransition: 'oTransitionEnd otransitionend'
  }

  for (const name in transEndEventNames) {
    if (el.style[name] !== undefined) {
      return transEndEventNames[name]
    }
  }

  return false // explicit for ie8 (  ._.)
}

function animationEnd () {
  const el = document.createElement('bootstrap')

  const transEndEventNames = {
    animation: 'animationend',
    webkitAnimation: 'webkitAnimationEnd',
    MozAnimation: 'mozAnimationEnd',
    OAnimation: 'oAnimationEnd oanimationend'
  }

  for (const name in transEndEventNames) {
    if (el.style[name] !== undefined) {
      return transEndEventNames[name]
    }
  }

  return false // explicit for ie8 (  ._.)
}

function onEnd (props) {
  return function (el, fn) {
    const self = this
    function handler (event) {
      fn.call(self, event)
      el.removeEventListener(event.type, handler)
    }
    el.addEventListener(props, handler, false)
  }
}

module.exports = {
  onAnimationEnd: onEnd(animationEnd()),
  onTransitionEnd: onEnd(transitionEnd())
}
