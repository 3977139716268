'use strict'

const app = require('ampersand-app')
const { default: Sortable } = require('sortablejs')
const View = require('ampersand-view')
const FilteredCollection = require('ampersand-filtered-subcollection')
const { default: tippy } = require('tippy.js')

const ProductCollection = require('../../models/product-collection')
const AddProductView = require('./add-product-view')
const ProductView = require('./product-view')
const SearchBarView = require('../search-bar')
const Product = require('../../models/product')
const { isAdminProp } = require('../../utils')
const {
  ProductImportButtonView
} = require('../product-import/ProductImportButton')

const DeletedProductView = View.extend({
  template: require('./product-row-deleted.pug').default,
  events: {
    'click [data-hook=restore]': 'onRestore'
  },
  onRestore (event) {
    event.preventDefault()
    this.model.save({ deletedAt: null })
  }
})

module.exports = View.extend({
  title: 'Produkte',
  template: require('./product-page.pug').default,

  events: {
    'click [data-hook=add-product]': 'addProduct'
  },

  props: {
    hasNew: ['boolean', true, false],
    isAdmin: isAdminProp
  },

  derived: {
    exportUrl: {
      cache: false,
      fn () {
        return `/api/events/${app.me.currentEvent.slug}/products/export`
      }
    }
  },

  subviews: {
    searchBar: {
      hook: 'search-bar',
      prepareView (el) {
        return new SearchBarView({ el, parent: this })
      }
    },

    importProducts: {
      hook: 'import-products',
      prepareView (el) {
        return new ProductImportButtonView({ el })
      }
    },

    activeProducts: {
      container: '[data-hook=products]',
      prepareView (el) {
        // active products
        const activeProducts = new FilteredCollection(this.collection, {
          filter (product) {
            return !product.deletedAt
          },
          watched: ['deletedAt']
        })
        return this.renderCollection(activeProducts, ProductView, el)
      }
    },

    deletedProducts: {
      container: '[data-hook=deleted-products]',
      prepareView (el) {
        // active products
        const deletedProducts = new FilteredCollection(this.collection, {
          filter (product) {
            return !!product.deletedAt
          },
          watched: ['deletedAt']
        })
        return this.renderCollection(deletedProducts, DeletedProductView, el)
      }
    }
  },

  initialize () {
    // create new collection, since we don't want to manipulate our
    // global products collection during searches
    this.collection = new ProductCollection(app.products.models, {
      cache: false
    })

    // update our global products collection. this let's us render the page right away,
    // but still fetch updates in the background
    app.products.fetch()
  },

  addProduct () {
    if (this.hasNew) return
    this.renderSubview(
      new AddProductView({
        model: new Product(),
        parent: this
      }),
      '[data-hook=new-product]'
    )
    this.hasNew = true
  },

  // will be called by SearchBarView
  search (query) {
    this.collection.byName(query)
  },

  remove () {
    if (this.sortable) this.sortable.destroy()
    if (this.tooltips) {
      this.tooltips.forEach((tip) => tip.destroy())
    }
    return View.prototype.remove.call(this)
  },

  render () {
    const parent = this
    this.renderWithTemplate(this)

    // add tooltips to the buttons
    if (!app.isMobile) {
      if (this.tooltips) {
        this.tooltips.forEach((tip) => tip.destroy())
      }
      this.tooltips = tippy(
        this.queryAll('[data-hook="page-header"] [data-title]')
      )
    }

    // if we don't have any products, let the user create
    // one right away and return
    if (!this.collection.length) {
      this.addProduct()
      return this
    }

    if (this.sortable) {
      this.sortable.destroy()
    }

    // create sortable
    this.sortable = Sortable.create(this.queryByHook('products'), {
      draggable: '.product-row',
      handle: '.ion-drag',
      animation: 150,
      onEnd () {
        this.toArray().forEach(function (id, i) {
          const model = parent.collection.get(id)
          const index = i + 1
          model.save({ sortPosition: index })
        })
        app.products.sort()
      }
    })

    return this
  }
})
