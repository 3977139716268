
form.pure-form.pure-form-stacked
  table.pure-table.table-striped
    thead
      tr
        th Produkt
        th Bestand
        th Verkauft

    tbody(data-hook="item-list")
