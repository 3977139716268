'use strict'

const State = require('ampersand-state')
const { io } = require('socket.io-client')
const { logger } = require('../utils')

const log = logger.extend('changes')

const ENDPOINT = window.location.origin

module.exports = State.extend({
  props: {
    eventId: ['string'],
    online: ['boolean', true, false]
  },

  initialize () {
    this.on('change:eventId', this.start, this)
  },

  start () {
    if (this.socket) this.socket.disconnect()
    const namespace = `${ENDPOINT}/${this.eventId}`
    log(`connecting to ${namespace}`)
    this.socket = io(namespace)
    this.socket.on('change', this.onChange.bind(this))
    this.socket.on('connect', this.onConnect.bind(this))
    this.socket.on('connect_error', this.onConnectError.bind(this))
  },

  onConnect () {
    const transport = this.socket.io.engine.transport.name // in most cases, "polling"
    log(`connected (${transport})`)

    this.socket.io.engine.on('upgrade', () => {
      const upgradedTransport = this.socket.io.engine.transport.name // in most cases, "websocket"
      log(`upgrade: ${transport} -> ${upgradedTransport}`)
    })
    this.online = true
  },

  onConnectError (error) {
    log(`error: ${error.message}`)
    this.online = false
  },

  onChange (change) {
    log('change: %o', change)
    this.trigger(change.table, change)
  }
})
