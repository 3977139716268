'use strict'

const app = require('ampersand-app')
const Event = require('./event')

module.exports = Event.extend({
  initialize () {
    this.listenTo(app.me, 'change:currentEventId', (me, currentEventId) => {
      this.currentEventId = currentEventId
    })

    // proxy changes to the user's currentEvent
    this.listenTo(this, 'sync', () => {
      if (this.isCurrentEvent) app.me.currentEvent.set(this.toJSON())
    })
  },

  session: {
    currentEventId: {
      type: 'string',
      default () {
        return (app.me || {}).currentEventId
      }
    }
  },

  derived: {
    isCurrentEvent: {
      deps: ['id', 'currentEventId'],
      fn () {
        return this.id === this.currentEventId
      }
    }
  }
})
