'use strict'

const app = require('ampersand-app')
const Collection = require('ampersand-rest-collection')
const { indexify } = require('../utils')

module.exports = Collection.extend({
  url () {
    return `/api/events/${app.me.currentEvent.slug}/locations/${this.location.id}/inventories`
  },

  model: require('./stock-taking').default,
  comparator: 'shiftEndTime',

  ajaxConfig () {
    return {
      xhrFields: { timeout: 30000 }
    }
  },

  initialize (data, opts) {
    this.filtered = false
    this.location = opts.location
  },

  serializeToStorage () {
    // serialize session props as well
    return this.map((model) => model.getAttributes({ props: true, session: true }))
  },

  byName (term) {
    term = indexify(term)

    // save unfiltered models for later
    if (!this.filtered) this._originalModels = this.models

    // reset search
    if (!term) {
      this.reset(this._originalModels)
      this.filtered = false
      return
    }
    if (term.length < 2) return

    // filter original models
    const filtered = this._originalModels.filter(function (inv) {
      return inv._nameIndex.indexOf(term) > -1
    })
    this.reset(filtered)
    this.filtered = true
  }
})
