import { useEffect, useState } from 'preact/hooks'
import { packaging } from '../../models/packages'
import { useCurrentEventSlug, useLocations } from '../hooks'
import * as styles from './taskboard.module.css'

/**
 * @param {import('../types').Transaction} tx
 */
function getInputUnits (tx) {
  return tx.units % tx.product.packageSize
}

/**
 * @param {import('../types').Transaction} tx
 */
function getInputPackages (tx) {
  return Math.floor(tx.units / tx.product.packageSize)
}

/**
 * @param {{
 *   transaction: import("../types").Transaction,
 *   onClose(): void,
 *   onEditTransaction(id: string, payload: Pick<import('../types').Transaction, 'units' | 'sourceId' | 'comment'>): Promise<void>
 * }} props
 */
export function TaskboardEditTransaction ({
  transaction,
  onClose,
  onEditTransaction
}) {
  const [inputPackages, setInputPackages] = useState(
    getInputPackages(transaction)
  )
  const [inputUnits, setInputUnits] = useState(getInputUnits(transaction))
  const [sourceId, setSourceId] = useState(transaction.sourceId)
  const [comment, setComment] = useState(transaction.comment || '')
  const [error, setError] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const eventSlug = useCurrentEventSlug()
  const { bars, storage } = useLocations(eventSlug)

  useEffect(() => {
    setInputPackages(getInputPackages(transaction))
    setInputUnits(getInputUnits(transaction))
    setSourceId(transaction.sourceId)
    setComment(transaction.comment || '')
  }, [transaction])

  return (
    <form
      class={`pure-form ${styles.transactionEditForm}`}
      onSubmit={async (event) => {
        event.preventDefault()
        try {
          setError(undefined)
          setIsSubmitting(true)
          await onEditTransaction(transaction.id, {
            sourceId,
            units: inputPackages * transaction.product.packageSize + inputUnits,
            comment
          })
          onClose()
        } catch (error) {
          console.error(error)
          setIsSubmitting(false)
          setError(error)
        }
      }}
    >
      <fieldset>
        <legend>Transaktion bearbeiten</legend>
      </fieldset>

      <div class={styles.transactionEditFormRoute}>
        <div style={{ paddingRight: '2em' }}>
          <label for='source'>Von</label>
          <select
            class='pure-input-1'
            name='source'
            value={sourceId}
            onChange={(event) => setSourceId(event.target.value)}
            required
          >
            <option disabled value=''>
              Quelle wählen...
            </option>
            {storage && (
              <optgroup label='Lager'>
                {storage.map((store) => (
                  <option key={store.id} value={store.id}>
                    {store.name}
                  </option>
                ))}
              </optgroup>
            )}
            {bars && (
              <optgroup label='Bars'>
                {bars.map((bar) => (
                  <option key={bar.id} value={bar.id}>
                    {bar.name}
                  </option>
                ))}
              </optgroup>
            )}
          </select>
        </div>
        <div>
          <label for='source'>Nach</label>
          <div>{transaction.target.name}</div>
        </div>
      </div>

      <div class={styles.transactionEditFormProduct}>
        <b>{transaction.product.name}</b>
        <div>
          <input
            class='pure-input-1'
            name='inputPackages'
            type='number'
            min='0'
            placeholder='0'
            value={inputPackages || ''}
            onChange={(event) => {
              setInputPackages(event.target.valueAsNumber)
            }}
          />
          <span class='append'>
            {packaging.get(transaction.product.packageId).name}
          </span>
        </div>
        <div>
          <input
            class='pure-input-1'
            name='inputUnits'
            type='number'
            min='0'
            step='any'
            placeholder='0'
            value={inputUnits || ''}
            onChange={(event) => {
              setInputUnits(event.target.valueAsNumber)
            }}
          />
          <span class='append'>
            {packaging.get(transaction.product.unitId).name}
          </span>
        </div>
      </div>

      <div style={{ alignSelf: 'stretch' }}>
        <label htmlFor='comment'>Kommentar</label>
        <textarea
          name='comment'
          rows={3}
          style={{ width: '100%' }}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </div>

      <footer class={styles.transactionEditFormFooter}>
        {error && <div class='error'><b>Error:</b> {error.message}</div>}
        <button
          type='submit'
          class='pure-button pure-button-primary'
          disabled={isSubmitting}
        >
          Speichern
        </button>
      </footer>
    </form>
  )
}
