'use strict'

const assign = require('lodash/assign')
const pick = require('lodash/pick')
const View = require('ampersand-view')
const app = require('ampersand-app')

module.exports = View.extend({
  template: require('./popover-button.pug').default,

  events: {
    click: 'onClick'
  },

  props: {
    showPopover: ['boolean', true, false],
    online: ['boolean', true, false]
  },

  bindings: {
    online: {
      type: 'booleanAttribute',
      no: 'disabled',
      yes: ''
    }
  },

  initialize (opts) {
    assign(this, pick(opts, 'classes', 'icon', 'title', 'Popover', 'popoverOpts', 'name', 'template'))
    this.on('change:showPopover', this.togglePopover, this)
    this.listenToAndRun(app.changes, 'change:online', () => {
      this.online = app.changes.online
    })
  },

  onClick (event) {
    event.preventDefault()
    this.toggle('showPopover')
  },

  remove () {
    View.prototype.remove.call(this)
    this.Popover = null
    this.popoverOpts = null
    return this
  },

  togglePopover () {
    if (this.showPopover) {
      this.popover = new this.Popover(assign({}, this.popoverOpts || {}, { button: this.el }))
      this.popover.render()
      this.popover.once('removed', function () {
        this.set('showPopover', false, { silent: true })
      }, this)
      this.registerSubview(this.popover)
    } else {
      this.popover.remove()
    }
  }
})
