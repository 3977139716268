mixin sortableColumn (prop, descr)
  th(data-hook="sort-" + prop, class=[collection.state.comparator === prop ? 'sorted' : null, collection.state.order].join(' ')).sortable-column
    span #{descr}
    span: i.ion-ios-arrow-down

.pure-u-1
  .pure-g
    .pure-u-1-2: h3 Gesamtbestand - Details
    .pure-u-1-2
      .flex-center
        .cell
          div(data-hook="search-bar")
  .pure-g
    .pure-u-1
      table.pure-table.table-striped
        thead
          tr
            +sortableColumn("name", "Name")
            +sortableColumn("delivery", "Lieferung")
            +sortableColumn("units", "Bestand")
            th Verkauft
        tbody(data-hook="list")
