import * as styles from './text-button.module.css'

/**
 * @param {{ onClick(): void, children: any, variant?: 'error' }} props
 */
export function TextButton ({ onClick, children, variant }) {
  return (
    <button
      type='button'
      class={variant === 'error' ? styles.textButtonError : styles.textButton}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
