form.pure-form.item-data-form(data-hook="add-product-form")
  .arrow
  //- Display descriptive message if this is our first product
  if isFirstProduct
    .pure-g
      .pure-u-1-8
      .pure-u-3-4
        h3 Willkommen zu deinem ersten Produkt.
        p.
          Hier kannst du dein erstes Produkt anlegen. Produkte können später durch Lieferungen zu Orten
          hinzugefügt werden oder in Transaktionen zwischen verschiedenen Orten hin- und herbewegt werden.
        p.
          Keine Angst! Du kannst die Produktdaten jederzeit ändern, falls du etwas falsch eingegeben hast.
        p.append.
          Tipp: Drücke <code>ESC</code>, um diesen Dialog zu schließen oder <code>ENTER</code>, um
          das Produkt anzulegen. Mit <code>TAB</code> kannst du durch die Felder navigieren.

  .pure-g
    .pure-u-1-3
      fieldset
        legend Produktdaten
        .pure-g
          .pure-u-1-1
            div(data-hook="name")

        .pure-g.packaging
          .pure-u-1-2
            div(data-hook="package-size")
          .pure-u-1-2
            div(data-hook="volume-per-unit")

        .pure-g.package-format
          .pure-u-1-2
            div(data-hook="package-name")

          .pure-u-1-2
            div(data-hook="package-unit")

    .pure-u-1-3
      fieldset
        legend Preise
        .pure-g
          .pure-u-1-2
            div(data-hook="cost")

          .pure-u-1-2
            div(data-hook="cost-unit")

        .pure-g
          .pure-u-1-2
            div(data-hook="price")
          .pure-u-1-2
            div(data-hook="serving-size")


    .pure-u-1-3
      fieldset
        legend optional
        .pure-g
          .pure-u-1-2
            div(data-hook="weight-full")
          .pure-u-1-2
            div(data-hook="weight-empty")

        .pure-g
          .pure-u-1-1
            div(data-hook="can-spill")

  .pure-g
    .pure-u-1-1
      .btn-group.buttons
        button.pure-button.cancel(type="reset", title="Abbrechen (Esc)", data-hook="cancel")
          i.icon.ion-close
        button.button-success.pure-button.save(type="submit", title=`${model.editable ? 'Speichern' : 'Hinzufügen'} (Enter)`)
          | #{model.editable ? 'Speichern' : 'Hinzufügen'}
