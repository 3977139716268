'use strict'

const app = require('ampersand-app')
const View = require('ampersand-view')
const assign = require('lodash/assign')
const LocationBase = require('../location-base/location-base-view')
const BarItemView = require('./bar-item-view')

const BarFooter = View.extend({
  template: require('./bar-footer.pug').default,
  initialize () {
    this.listenTo(this.model.stock, 'change add remove', this.render)
    this.listenTo(this.model, 'change', this.render)
  }
})

/*
  View für eine einzelne Bar
  Model ist Bar, mit Inventar als model.stock
 */
module.exports = LocationBase.extend({
  template: require('./bar.pug').default,

  ItemView: BarItemView,

  derived: {
    isBarchef: {
      cache: false,
      fn () {
        return app.me.role === 'bar'
      }
    }
  },

  subviews: assign({}, LocationBase.prototype.subviews, {
    footer: {
      hook: 'bar-footer',
      prepareView (el) {
        return new BarFooter({ el, model: this.model })
      }
    }
  }),

  events: assign({}, LocationBase.prototype.events, {
    'click .dispatch-all': 'dispatchAll'
  }),

  initialize () {
    LocationBase.prototype.initialize.apply(this)
    this.listenTo(this.model, 'sort', this.onSort)
    this.listenTo(this.model, 'change:editable', this.onEditable)
  },

  dispatchAll (evt) {
    evt.preventDefault()
    evt.stopPropagation()
  }
})
