.popover.confirm-delete.animated
  .popover-arrow
  .popover-content.inventory-popover
    a(href=model.inventoryUrl)
      i.icon.ion-ios-paper
      | Inventuren verwalten
    a(href=`${model.inventoryUrl}/neu`)
      i.icon.ion-plus
      | Neue Inventur anlegen
    a(href=model.exportUrl, target="_blank")
      i.icon.ion-ios-cloud-download
      | Inventuren herunterladen
