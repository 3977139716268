.taskboard {
  display: block;
}

.taskboardSidebar {
  composes: taskboard;
  position: fixed;
  top: 0;
  right: 0;
  width: 360px;
  border-left: var(--border);
  padding: 20px 15px;
  background-color: white;
  /* individual scrolling behavior */
  height: 100vh;
  overflow: auto;
}

.iconButton {
  appearance: none;
  background: transparent;
  border: none;
  text-align: center;
  color: #ccc;
  padding: 10px;
  line-height: 1;
  display: block;
  cursor: pointer;
}

.iconButton:hover {
  color: #888;
}

.closeButton {
  composes: iconButton;
  position: absolute;
  top: 15px;
  right: 15px;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 0;
  color: var(--highlight-red);
}

.noItems {
  color: #888;
  text-align: center;
}

.taskboard h3 {
  margin-top: 0;
}

.taskboard ul,
.taskboard li {
  list-style: none;
  padding: 0;
}

.taskboard ul {
  margin-top: 0.5em;
}

.taskboardItem {
  font-size: 0.875rem;
  margin-bottom: 1em;
}

.taskboardMetadata {
  font-size: 0.8125rem;
  color: #888;
  text-decoration: none;
}

.taskboardItemHeader,
.taskboardItemFooter {
  composes: taskboardMetadata;
}

.taskboardItemHeader {
  display: flex;
  align-items: center;
  gap: 4px;
}

.taskboardItemFooter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
}

.taskboardItemFooter time {
  text-align: right;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1em;
  width: 100%;
}

.userWrap {
  display: flex;
  align-items: center;
  gap: 4px;
}

.commentBubble {
  cursor: pointer;
  color: #888;
}

.commentBubble i {
  font-size: 18px;
}

.commentForm {
  margin-top: 0.5em;
}

.saveCommentButton {
  display: block;
  margin: 0 auto;
  margin-top: 0.5em;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--button-blue);
}

.saveCommentButton:hover {
  color: var(--highlight-blue);
}

.textarea {
  border: 0;
  border-radius: 3px;
  padding: 0.25em 0.5em;
  width: 100%;
  max-width: 100%;
}

.textarea:focus {
  outline: 0;
}

.textareaWrap {
  position: relative;
  border-radius: 3px;
  border: var(--border);
}

.textareaWrap .arrow,
.textareaWrap .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.textareaWrap .arrow {
  border-width: 7px;
}

.textareaWrap .arrow {
  left: 7%;
  margin-left: -7px;
  border-top-width: 0;
  border-bottom-color: var(--border-light-grey);
  top: -7px;
}

.textareaWrap .arrow:after {
  border-width: 6px;
  content: ' ';
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

.transactionSection {
  margin-bottom: 2em;
}

.groupActions {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.transactionEditForm {
  width: 100%;
}

.transactionEditFormRoute {
  display: grid;
  grid-template-columns: 1fr 1fr;

  justify-content: stretch;
}

.transactionEditFormProduct {
  display: grid;
  grid-template-columns: 50fr 25fr 25fr;
  align-items: center;
  column-gap: 2em;
  margin-top: 1em;
}

.transactionEditFormFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
}

.taskboardGroup {
  font-size: 0.875rem;
  margin-bottom: 1em;
}

.taskboardGroupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.taskboardGroupToggleButton {
  composes: iconButton;
  width: 36px;
  color: var(--button-blue);
}

.taskboardGroupToggleButton:hover {
  color: var(--highlight-blue);
}

.taskboardGroupRoute {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.taskboardGroupComment {
  display: flex;
  gap: 4px;
  font-size: 0.8125rem;
}

.taskboardGroupComment > i {
  font-size: 1rem;
  line-height: 1;
  color: #888;
}

/* @media only screen and (max-width: 1024px) {
  .taskboard {
    top: 60px;
    width: 100vw;
    box-shadow: -2px 0 15px rgba(0, 0, 0, 0.07);
  }

  .buttons {
    gap: 1.5em;
  }
} */
