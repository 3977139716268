'use strict'

const View = require('ampersand-view')
const DatePicker = require('../date-picker/date-picker')

module.exports = View.extend({
  template: require('./templates/stock-shift-end.pug').default,

  events: {
    'keyup input[name="shiftName"]': 'onKeyup'
  },

  bindings: {
    'model.shiftName': {
      type: 'value',
      selector: '[name=shiftName]'
    }
  },

  subviews: {
    datePicker: {
      hook: 'date-picker',
      prepareView (el) {
        const view = new DatePicker({
          el,
          initialValue: this.model.shiftEndTime
        })
        this.listenTo(view, 'change:inputValue', this.onDateChange)
        return view
      }
    }
  },

  initialize () {
    this.listenTo(this.model, 'sync', this.resetDate)
  },

  onKeyup (evt) {
    this.model.shiftName = evt.target.value
  },

  resetDate () {
    this.datePicker.inputValue = this.model.shiftEndTime
  },

  onDateChange (view, date) {
    this.model.shiftEndTime = date
  }
})
