'use strict'

const View = require('ampersand-view')
const debounce = require('lodash/debounce')

const ESC_KEY = 27

module.exports = View.extend({
  template: require('./search-bar.pug').default,

  events: {
    'click [data-hook=reset-search]': 'onReset',
    submit: 'onSubmit',
    'keyup [data-hook=search-form]': 'onKeyup'
  },

  // if an (optional) model with an initial "q" prop is passed
  // we bind it to the input
  bindings: {
    'model.q': {
      type: 'value',
      selector: 'input'
    }
  },

  initialize (opts) {
    this.placeholder = opts.placeholder
  },

  onSubmit (evt) {
    evt.preventDefault()
  },

  onReset (event) {
    event.preventDefault()
    this.searchInput.value = ''
    this.search('')
  },

  onKeyup: debounce(function (event) {
    let query = event.target.value.trim()
    if (event.which === ESC_KEY) {
      event.target.value = ''
      query = ''
    }
    this.search(query)
  }, 50),

  search (query) {
    this.resetButton.classList.toggle('hidden', !query)
    if (this.parent && typeof this.parent.search === 'function') {
      this.parent.search(query)
    }
    this.trigger('search', query)
  },

  render () {
    this.renderWithTemplate(this)
    this.cacheElements({
      resetButton: '[data-hook="reset-search"] a',
      searchInput: '[data-hook="search-form"] input'
    })
    return this
  }
})
