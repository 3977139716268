'use strict'

const pick = require('lodash/pick')
const assign = require('lodash/assign')
const View = require('ampersand-view')
const State = require('ampersand-state')
const Collection = require('ampersand-collection')

const mixin = require('../popover-mixin')

const Item = State.extend({
  props: {
    id: 'string',
    name: 'string',
    selected: ['boolean', true, false]
  }
})

const Items = Collection.extend({ model: Item })

const ItemView = View.extend({
  template: require('./selectable-item.pug').default,
  events: {
    click: 'onClick'
  },

  bindings: {
    'model.selected': {
      type: 'booleanClass'
    }
  },

  onClick () {
    this.model.toggle('selected')
  }
})

module.exports = View.extend(mixin, {
  template: require('./attribute-filter.pug').default,

  initialize (opts) {
    this.setButton(opts.button)
    this.opts = opts
    this.opts.position = 'bottom'
    this.state = opts.state

    this.collection = new Items(
      this.collection.map((item) => {
        return assign(pick(item, 'id', 'name'), {
          selected: this.state[opts.prop] === item.id
        })
      })
    )

    this.listenTo(this.collection, 'change:selected', this.onSelected)
  },

  onSelected (selectedModel, selected) {
    // de-select the rest
    this.collection.forEach((model) => {
      if (model.id !== selectedModel.id) model.selected = false
    })
    this.parent.collection.state[this.opts.prop] = selected
      ? selectedModel.id
      : null
    this.remove()
  },

  render () {
    mixin.render.apply(this, arguments)
    this.renderCollection(this.collection, ItemView, '[data-hook=list]')
    return this
  }
})
