/*$AMPERSAND_VERSION*/
var View = require('ampersand-view');
var set = require('lodash/set');
var isFunction = require('lodash/isFunction');
var result = require('lodash/result');

module.exports = View.extend({

    session: {
        valid: ['boolean', false, false]
    },

    derived: {
        data: {
            fn: function () {
                var res = {};
                for (var key in this._fieldViews) {
                    if (this._fieldViews.hasOwnProperty(key)) {
                        // If field name ends with '[]', don't interpret
                        // as verbose form field...
                        if (key.match(/\[\]$/)) {
                            res[key] = this._fieldViews[key].value;
                        } else {
                            set(res, key, this._fieldViews[key].value);
                        }
                    }
                }
                return this.clean(res);
            },
            cache: false
        }
    },

    initialize: function(opts) {
        opts = opts || {};
        this.el = opts.el;
        this.fieldContainerEl = opts.fieldContainerEl;
        this.validCallback = opts.validCallback || this.validCallback;
        this.submitCallback = opts.submitCallback || this.submitCallback;
        this.clean = opts.clean || this.clean || function (res) { return res; };

        if (opts.model) this.model = opts.model;

        this.preventDefault = opts.preventDefault === false ? false : true;
        this.autoAppend = opts.autoAppend === false ? false : true;

        // storage for our fields
        this._fieldViews = {};
        this._fieldViewsArray = [];

        // add all our fields
        (result(opts, 'fields') || result(this, 'fields') || []).forEach(this.addField, this);

        if (opts.autoRender) {
            this.autoRender = opts.autoRender;
            // &-view requires this.template && this.autoRender to be truthy in
            // order to autoRender. template doesn't apply to &-form-view, but
            // we manually flip the bit to honor autoRender
            this.template = opts.template || this.template || true;
        }

        if (opts.values) this._startingValues = opts.values;

        if (this.validCallback) {
            this.on('change:valid', function(view, validBool) {
                this.validCallback(validBool);
            });
        }

        if (this.submitCallback) this.on('submit', this.submitCallback);
    },

    addField: function (fieldView) {
        this._fieldViews[fieldView.name] = fieldView;
        this._fieldViewsArray.push(fieldView);
        return this;
    },

    removeField: function (name, strict) {
        var field = this.getField(name, strict);
        if (field) {
            field.remove();
            delete this._fieldViews[name];
            this._fieldViewsArray.splice(this._fieldViewsArray.indexOf(field), 1);
        }
    },

    getField: function (name, strict) {
        var field = this._fieldViews[name];
        if (!field && strict) {
            throw new ReferenceError('field name  "' + name + '" not found');
        }
        return field;
    },

    setValues: function (data) {
        for (var name in data) {
            if (data.hasOwnProperty(name)) {
                this.setValue(name, data[name]);
            }
        }
    },

    checkValid: function () {
        this.valid = this._fieldViewsArray.every(function (field) {
            return field.valid;
        });
        return this.valid;
    },

    beforeSubmit: function () {
        this._fieldViewsArray.forEach(function (field) {
            if (field.beforeSubmit) field.beforeSubmit();
        });
    },

    update: function (field) {
        this.trigger('change:' + field.name, field);
        // if this one's good check 'em all
        if (field.valid) {
            this.checkValid();
        } else {
            this.valid = false;
        }
    },

    remove: function () {
        this.el.removeEventListener('submit', this.handleSubmit, false);
        this._fieldViewsArray.forEach(function (field) {
            field.remove();
        });
        return View.prototype.remove.call(this);
    },

    handleSubmit: function (e) {
        this.beforeSubmit();
        this.checkValid();
        if (!this.valid) {
            e.preventDefault();
            return false;
        }

        if (this.preventDefault) {
            e.preventDefault();
            this.trigger('submit', this.data);
            return false;
        }
    },

    reset: function () {
        this._fieldViewsArray.forEach(function (field) {
            if (isFunction(field.reset)) {
                field.reset();
            }
        });
    },

    clear: function () {
        this._fieldViewsArray.forEach(function (field) {
            if (isFunction(field.clear)) {
                field.clear();
            }
        });
    },

    render: function () {
        if (this.rendered) return;
        if (!this.el) {
            this.el = document.createElement('form');
        }
        if (this.autoAppend) {
            this.fieldContainerEl = this.query(this.fieldContainerEl || '[data-hook~=field-container]') || this.el;
        }
        this._fieldViewsArray.forEach(function renderEachField(fV) {
            this.renderField(fV, true);
        }, this);
        if (this._startingValues) {
            // setValues is ideally executed at initialize, with no persistent
            // memory consumption inside ampersand-form-view, however, some
            // fieldViews don't permit `setValue(...)` unless the field view
            // itself is rendered.  thus, cache init values into _startingValues
            // and update all values after each field is rendered
            this.setValues(this._startingValues);
            delete this._startingValues;
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.el.addEventListener('submit', this.handleSubmit, false);
        // force `change:valid` to be triggered when `valid === false` post-render,
        // despite `valid` not having changed from its default pre-render value of `false`
        this.set('valid', null, {silent: true});
        this.checkValid();
    },

    renderField: function (fieldView, renderInProgress) {
        if (!this.rendered && !renderInProgress) return this;
        fieldView.parent = this;
        fieldView.render();
        if (this.autoAppend) this.fieldContainerEl.appendChild(fieldView.el);
    },

    getValue: function(name) {
        var field = this.getField(name, true);
        return field.value;
    },

    setValue: function(name, value) {
        var field = this.getField(name, true);
        field.setValue(value);
        return this;
    },

    // deprecated
    getData: function() {
        console.warn('deprecation warning: ampersand-form-view `.getData()` replaced by `.data`');
        return this.data;
    }

});
