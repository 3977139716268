'use strict'

const FilteredCollection = require('ampersand-filtered-subcollection')
const View = require('ampersand-view')
const { default: Sortable } = require('sortablejs')
const { default: tippy } = require('tippy.js')
const app = require('ampersand-app')

const { isAdminProp } = require('../../utils')
const StorageView = require('../storage-page/storage-view')
const BarView = require('../bar-page/bar-view')
const SearchBarView = require('../search-bar')

/**
 * Quick Select View
 *
 * - lists the locations
 * - filters by id
 * - re-renders on when collection changes
 */
const QuickSelectView = View.extend({
  template: require('./quick-select.pug').default,

  events: {
    'change [data-hook=quick-select]': 'quickSelect'
  },

  initialize () {
    this.collection = new FilteredCollection(app.locations, {
      filter: this.parent.collection.filterFn,
      watched: ['type']
    })
    this.listenTo(this.collection, 'add remove change', this.render)
  },

  quickSelect (event) {
    event.preventDefault()
    const val = event.target.value

    if (val === 'all') {
      this.parent.collection.removeFilter(this._filter)
    } else {
      const filter = function (model) {
        return model.id === val
      }
      this.parent.collection.swapFilters(filter, this._filter || [])
      this._filter = filter
    }
  },

  render () {
    this.renderWithTemplate({ collection: this.collection.toJSON() })
    return this
  }
})

module.exports = View.extend({
  events: {
    'click [data-hook=add-location]': 'onNew',
    'click [data-hook=edit-location]': 'onEdit'
  },

  props: {
    editable: ['boolean', true, false],
    showNew: ['boolean', true, false],
    isAdmin: isAdminProp
  },

  subviews: {
    quickSelect: {
      hook: 'quick-select',
      prepareView (el) {
        return new QuickSelectView({ el, parent: this })
      }
    },

    searchBar: {
      hook: 'search-bar',
      prepareView (el) {
        return new SearchBarView({ el, parent: this })
      }
    },

    locationList: {
      hook: 'location-list',
      prepareView (el) {
        return this.renderCollection(
          this.collection,
          (opts) => {
            if (opts.model.type === 'storage' || opts.model.type === 'crash') {
              return new StorageView({ model: opts.model, parent: this })
            }
            if (opts.model.type === 'bar') {
              return new BarView({ model: opts.model, parent: this })
            }
          },
          el
        )
      }
    }
  },

  onEdit () {
    this.toggle('editable')
    this.collection.each((model) => model.toggle('editable'))
    window.requestAnimationFrame(() => {
      this.el.classList.toggle('location--edit', this.editable)
    })
    this.toggleEditMode()
  },

  toggleEditMode () {
    const isEditable = this.get('editable')

    if (isEditable) {
      this.sortable = Sortable.create(this.query('[data-hook=location-list]'), {
        handle: '.drag-handle',
        animation: 150,
        onEnd () {
          this.toArray().forEach(function (id, index) {
            const model = app.locations.get(id)
            if (index !== model.sortPosition) model.sortPosition = index
          })
        }
      })
    } else {
      this.sortable.destroy()
    }
  },

  // this will be called by SearchBarView
  search (query) {
    // search the stock of each location
    this.collection.each((location) => location.stock.byName(query))
  },

  remove () {
    if (this.tooltips) {
      this.tooltips.forEach((tip) => tip.destroy())
    }
    return View.prototype.remove.call(this)
  },

  render () {
    // render page shell
    this.renderWithTemplate(this)

    // add tooltips to the buttons
    if (!app.isMobile) {
      this.tooltips = tippy(this.queryAll('button[data-title]'))
    }

    // display input form if collection is empty
    this.listenTo(this.collection, 'sync reset remove', () => {
      if (!this.collection.length && !this.showNew) {
        this.showNew = true
        this.onNew()
      }
    })
    return this
  }
})
