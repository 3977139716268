.popover.dispatch-view-simple.animated
  .popover-arrow
  a(href="#", role="button", data-hook="popover-close").popover-close
    i.ion-close-round
  .popover-content
    form.pure-form.pure-form-stacked
      fieldset
        legend #{model.product.name} dispatchen

        .pure-g.route
          .pure-u-1
            //- Ziel
            div(data-hook="select-target")

        .items(data-hook="products")

      button(type="submit", disabled, data-hook="submit").pure-button.pure-button-primary Abschicken
