'use strict'

const View = require('ampersand-view')
const { default: tippy } = require('tippy.js')
const app = require('ampersand-app')

const EditView = require('./add-product-view')
const ConfirmPopover = require('../confirm-delete')
const PopoverButton = require('../popover-button')
const { isAdminProp } = require('../../utils')

module.exports = View.extend({
  template: require('./product-row-wrap.pug').default,

  initialize () {
    this.listenTo(this.model, 'change:editable', this.render)
  },

  render () {
    if (this.subview) this.subview.remove()
    this.renderWithTemplate(this)
    if (this.model.editable) {
      this.subview = new EditView({ model: this.model })
    } else {
      this.subview = new Row({ model: this.model })
    }
    this.renderSubview(this.subview, '[data-hook="subview"]')
    return this
  }
})

const Row = View.extend({
  template: require('./product-row.pug').default,

  events: {
    'click [data-hook=edit]': 'edit'
  },

  subviews: {
    deleteButton: {
      hook: 'delete-button',
      prepareView (el) {
        return new PopoverButton({
          el,
          icon: 'ion-trash-a',
          title: `${this.model.name} löschen...`,
          classes: 'button-error',
          Popover: ConfirmPopover,
          popoverOpts: {
            model: this.model,
            position: 'bottom'
          }
        })
      }
    }
  },

  props: {
    isAdmin: isAdminProp
  },

  edit () {
    this.model.editable = true
  },

  remove () {
    if (this.tooltips) {
      this.tooltips.forEach((tip) => tip.destroy())
    }
    return View.prototype.remove.call(this)
  },

  render () {
    this.renderWithTemplate(this)
    // add tooltips to the buttons
    if (!app.isMobile) {
      this.tooltips = tippy(this.queryAll('[data-title]'))
    }

    return this
  }
})
