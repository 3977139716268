'use strict'

const View = require('ampersand-view')
const app = require('ampersand-app')

const PopoverButton = require('../popover-button')
const ConfirmPopover = require('../confirm-delete')
const InventoryPopover = require('./inventory-popover')
const { isAdminProp } = require('../../utils')

const ENTER_KEY = 13
const ESC_KEY = 27

/*
  Location Action Buttons
 */
const ButtonsView = View.extend({
  template: require('./buttons.pug').default,

  events: {
    'click [data-hook=cancel]': 'cancel',
    'click [data-hook=save]': 'save',
    'click [data-hook=delivery-button]': 'renderDeliveryModal'
  },

  props: {
    isAdmin: isAdminProp
  },

  derived: {
    isBarchef: {
      cache: false,
      fn () {
        return app.me.role === 'bar'
      }
    },
    canStartDelivery: {
      cache: false,
      fn () {
        return app.me.role === 'dispatcher' || app.me.role === 'admin'
      }
    },
    exportUrl: {
      cache: false,
      fn () {
        return `/api/events/${app.me.currentEvent.slug}/locations/${this.model.id}/inventories/export`
      }
    },
    inventoryUrl: {
      cache: false,
      fn () {
        if (this.model.type === 'storage' || this.model.type === 'crash') {
          return `/lager/${this.model.slug}/inventuren`
        }
        return `/bars/${this.model.slug}/inventuren`
      }
    }
  },

  initialize () {
    this.listenTo(this.model, 'change:name', this.onChange)
  },

  cancel () {
    this.parent.cancel()
  },

  save () {
    app.locations.create(this.model, { wait: true })
  },

  renderDeliveryModal () {
    import('../delivery/DeliveryModal').then((module) => {
      const DeliveryModalView = module.default
      this.deliveryView = new DeliveryModalView({
        props: {
          targetId: this.model.id,
          onClose: () => {
            this.deliveryView.remove()
          }
        }
      })
      this.renderSubview(this.deliveryView, app.view.el)
    })
  },

  onChange (model, name) {
    ;(this.query('[data-hook=save]') || {}).disabled = !name
  },

  render () {
    this.renderWithTemplate(this)

    if (this.isAdmin || this.isBarchef) {
      this.renderSubview(
        new PopoverButton({
          el: this.queryByHook('inventory-button'),
          icon: 'ion-ios-paper',
          title: 'Inventuren verwalten...',
          Popover: InventoryPopover,
          popoverOpts: {
            model: this,
            position: 'bottom'
          }
        })
      )
    }

    if (!this.model.isNew() && this.model.editable) {
      this.renderSubview(
        new PopoverButton({
          el: this.queryByHook('delete-button'),
          icon: 'ion-ios-trash',
          title: `${this.model.name} löschen...`,
          classes: 'button-error',
          Popover: ConfirmPopover,
          popoverOpts: {
            model: this.model,
            position: 'bottom'
          }
        })
      )
    }

    return this
  }
})

/*
  Location Header with title and buttons
 */
const HeaderView = View.extend({
  template: require('./location-header.pug').default,

  events: {
    'blur .location-name': 'setName',
    'keyup .location-name': 'keyup'
  },

  props: {
    isAdmin: isAdminProp
  },

  initialize () {
    this.listenTo(this.model, 'change:editable', this.render)
    if (this.model.isNew()) {
      this.listenToOnce(this.model, 'sync', this.cancel)
    }
  },

  setName (event) {
    const val = event.target.value
    if (val) {
      this.model.set('name', val.trim())
    }
  },

  cancel () {
    this.parent.remove()
  },

  keyup (event) {
    this.setName(event)

    if (event.which === ENTER_KEY) {
      event.preventDefault()
      if (event.target.value) app.locations.create(this.model, { wait: true })
      return
    }

    if (this.model.isNew() && event.which === ESC_KEY) {
      this.cancel()
    }
  },

  render () {
    this.renderWithTemplate(this.model)

    this.renderSubview(
      new ButtonsView({ model: this.model }),
      this.query('[data-hook=buttons]')
    )

    if (this.model.isNew()) {
      window.requestAnimationFrame(() => this.query('.location-name').focus())
    }

    return this
  }
})

/*
  Location Base View
 */
module.exports = View.extend({
  subviews: {
    header: {
      hook: 'location-header',
      prepareView (el) {
        return new HeaderView({ el, model: this.model })
      }
    }
  },

  props: {
    isAdmin: isAdminProp
  },

  events: {
    'click [data-hook="sort-name"]': 'sortByName',
    'click [data-hook="sort-units"]': 'sortByStock',
    'click [data-hook="sort-sortPosition"]': 'sortByPosition'
  },

  initialize () {
    this.listenTo(
      this.model.stock.state,
      'change:comparator change:order',
      this.toggleIcon
    )
  },

  toggleIcon () {
    this.queryAll('.sortable-column').forEach((el) =>
      el.classList.remove('sorted')
    )
    const column = this.queryByHook('sort-' + this.model.stock.state.comparator)

    column.classList.remove('desc', 'asc')
    column.classList.add('sorted', this.model.stock.state.order)
  },

  sortByName (evt) {
    evt.preventDefault()
    this.model.stock.setComparator('name')
  },

  sortByStock (evt) {
    evt.preventDefault()
    this.model.stock.setComparator('units')
  },

  sortByPosition (evt) {
    evt.preventDefault()
    this.model.stock.setComparator('sortPosition')
  },

  onEditable (model, isEditable) {
    if (!isEditable) model.save()
  },

  render () {
    // render storage shell
    this.renderWithTemplate(this)

    // render stock list
    this.renderCollection(
      this.model.stock,
      this.ItemView,
      this.queryByHook('item-list')
    )

    return this
  }
})
