import { api, useCurrentEventSlug, useCurrentRole } from '../hooks'
import * as styles from './taskboard.module.css'

/**
 * @param {{
 *   ids: string[]
 *   show?: ('accept' | 'complete')[]
 *   onRevalidate(): void
 * }} props
 */
export function TaskboardGroupActions ({
  ids,
  show = ['accept', 'complete'],
  onRevalidate
}) {
  const eventSlug = useCurrentEventSlug()
  const role = useCurrentRole()

  return (
    <div class={styles.groupActions}>
      {show.includes('accept') &&
        (role === 'admin' || role === 'dispatcher') && (
          <button
            className='pure-button'
            onClick={async () => {
              const ok = window.confirm(
                `${ids.length} Transaktionen akzeptieren?`
              )

              if (!ok) {
                return
              }

              try {
                await api(`/api/events/${eventSlug}/transactions`, {
                  method: 'PUT',
                  body: {
                    state: 'accepted',
                    ids
                  }
                })
                await onRevalidate()
              } catch (error) {
                console.error(error)
              }
            }}
          >
            Alle akzeptieren
          </button>
      )}

      {show.includes('complete') && (
        <button
          className='pure-button'
          onClick={async () => {
            const ok = window.confirm(`${ids.length} Transaktionen bestätigen?`)

            if (!ok) {
              return
            }

            try {
              await api(`/api/events/${eventSlug}/transactions`, {
                method: 'PUT',
                body: {
                  state: 'complete',
                  ids
                }
              })
              await onRevalidate()
            } catch (error) {
              console.error(error)
            }
          }}
        >
          <i class='icon ion-checkmark' style={{ marginRight: '0.5em' }} />
          Alle bestätigen
        </button>
      )}
    </div>
  )
}
