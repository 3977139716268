'use strict'

const View = require('ampersand-view')
const app = require('ampersand-app')

module.exports = View.extend({
  template: require('./event.pug').default,
  events: {
    'click [data-hook=cancel]': 'onCancel',
    'keyup input[name=name]': 'onKeyup',
    submit: 'onSubmit'
  },

  initialize () {
    this.listenTo(this.model, 'sync', this.success)
  },

  onKeyup (e) {
    const val = (e.target.value || '').trim()
    this.model.name = val
    this.query('button[type=submit]').disabled = !val.length
  },

  onCancel (e) {
    e.preventDefault()
    if (this.parent) this.parent.createEvent = false
  },

  onSubmit (e) {
    e.preventDefault()
    this.model.save()
  },

  success () {
    // only set the newly created event, if the user doesn't yet
    // have a current event selected
    if (!app.me.currentEventId) {
      app.me.once('sync', () => app.navigate(''))
      app.me.save({ currentEventId: this.model.id })
    }
  },

  render () {
    this.renderWithTemplate(this)
    window.requestAnimationFrame(() => this.query('input[name=name]').focus())
    return this
  }
})
