import Slidy from 'slidy'
import { DateTime } from 'luxon'
import View from 'ampersand-view'
import template from './slider.pug'

export default View.extend({
  template,

  initialize (opts) {
    // search-state
    this.state = opts.state

    // reset slider on filter reset
    this.listenTo(this.state, 'change:filtered', (state, filtered) => {
      if (!filtered) this.render()
    })

    this.listenTo(this.model, 'change:start change:end', this.render)
  },

  onChange () {
    const pickers = this.slidy.pickers
    const start = DateTime.fromMillis(pickers[0].value)
    const end = DateTime.fromMillis(pickers[1].value)
    this.state.start = DateTime.min(start, end).toMillis()
    this.state.end = DateTime.max(start, end).toMillis()
  },

  remove () {
    if (this.slidy) this.slidy.disable()
    View.prototype.remove.call(this)
  },

  renderSlidy () {
    if (this.slidy) this.slidy.disable()
    if (!this.model.start || !this.model.end) return

    const start = this.model.start.getTime()
    const end = this.model.end.getTime()

    this.slidy = new Slidy({
      min: start,
      max: end,
      step: (end - start) / 3600000,
      pickers: [{ value: start }, { value: end }]
    })

    this.slidy.on('change', this.onChange.bind(this))
    this.queryByHook('slider').appendChild(this.slidy.element)
  },

  render () {
    this.renderWithTemplate(this)
    this.renderSlidy()
    return this
  }
})
