import Collection from 'ampersand-collection'
import Model from 'ampersand-state'

const Packaging = Collection.extend({
  model: Model.extend({
    props: {
      id: 'string',
      type: 'string',
      name: 'string',
      plural: 'string'
    }
  })
})

export const packagesList = [
  { id: 'keg', type: 'package', name: 'Fass', plural: 'Fässer' },
  { id: 'box', type: 'package', name: 'Kiste', plural: 'Kisten' },
  { id: 'tray', type: 'package', name: 'Tray', plural: 'Trays' },
  { id: 'carton', type: 'package', name: 'Karton', plural: 'Kartons' },
  { id: 'bottle', type: 'unit', name: 'Flasche', plural: 'Flaschen' },
  { id: 'can', type: 'unit', name: 'Dose', plural: 'Dosen' }
]

export const packaging = new Packaging(packagesList)
