'use strict'

const View = require('ampersand-view')
const app = require('ampersand-app')
const { isAdminProp } = require('../../utils')

module.exports = View.extend({
  template: require('./templates/event.pug').default,

  events: {
    'click [data-hook=activate]': 'activate'
  },

  bindings: {
    'model.name': {
      hook: 'name'
    },

    'model.isCurrentEvent': [{
      type: 'booleanClass',
      hook: 'activate',
      name: 'hidden'
    }, {
      type: 'booleanClass',
      hook: 'name',
      name: 'item-name'
    }]
  },

  props: {
    isAdmin: isAdminProp
  },

  activate (event) {
    event.preventDefault()
    app.me.save({ currentEventId: this.model.id }, { wait: true })
  },

  render () {
    this.renderWithTemplate(this)

    return this
  }
})
