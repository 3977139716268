.auth-flip-view.front
  form.pure-form.pure-form-stacked.login-form
    fieldset
      legend Anmelden

      label(for="username") Benutzername
      input.pure-u-1(name="username", type="text", placeholder="Name", data-hook="username", autofocus, autocomplete="off", autocorrect="off", autocapitalize="off")
      label(for="password") Passwort
      input.pure-u-1(type="password", name="password", placeholder="Passwort", data-hook="password", autocomplete="current-password")

    button(type="submit", disabled).pure-button.pure-button-primary Anmelden
    p.note ...oder <a href="/signup", data-hook="flip">neuen Benutzer anlegen.</a>
