import Model from 'ampersand-state'
import app from 'ampersand-app'
import isNumber from 'lodash/isNumber'
import Big from 'big.js'
import { DateTime } from 'luxon'

import { unitsLocalized, packagesLocalized, vat, net, currency as formatCurrency } from '../utils'

function currency (key) {
  return {
    deps: [key],
    fn () {
      return formatCurrency(this[key])
    }
  }
}

export const StockItem = Model.extend({
  props: {
    units: ['number', false, 0],
    unitsSinceInventory: ['number', false, 0],
    unitsLastInventory: ['number', false, 0],
    unitsSold: ['number', false, 0],
    lastInventoryTime: ['date'],
    lastInventoryId: ['string'],
    productId: ['string'],
    locationId: ['string']
  },

  session: {
    spillage: {
      type: 'number',
      default () {
        return app.me && app.me.currentEvent.spillage
      }
    },
    eventVat: {
      type: 'number',
      default () {
        return app.me && app.me.currentEvent.vat
      }
    }
  },

  initialize () {
    // proxy sortPosition change to collection
    this.listenTo(this.product, 'change:sortPosition', (product, position) => {
      this.trigger('change:sortPosition', this, position)
    })

    this.listenTo(app.me.currentEvent, 'change:vat change:spillage', () => {
      this.set({
        spillage: app.me.currentEvent.spillage,
        eventVat: app.me.currentEvent.vat
      })
    })
  },

  derived: {
    product: {
      deps: ['productId'],
      fn () {
        return app.products && app.products.get(this.productId)
      }
    },

    lastInventoryTimeLocalized: {
      deps: ['lastInventoryTime'],
      fn () {
        return DateTime.fromJSDate(this.lastInventoryTime).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    },

    lastInventoryUrl: {
      deps: ['lastInventoryId'],
      fn () {
        const type = this.collection.parent.type === 'bar' ? 'bars' : 'lager'
        return `${type}/${this.collection.parent.slug}/inventuren/${this.lastInventoryId}`
      }
    },

    unitsLocalized: unitsLocalized('units'),
    packagesLocalized: packagesLocalized('units'),

    unitsSoldLocalized: unitsLocalized('unitsSold'),
    packagesSoldLocalized: packagesLocalized('unitsSold'),

    unitsSinceInventoryLocalized: unitsLocalized('unitsSinceInventory'),
    packagesSinceInventoryLocalized: packagesLocalized('unitsSinceInventory'),

    stockNetValue: {
      deps: ['units'],
      fn () {
        if (!isNumber(this.units) || !isNumber(this.product.costPerUnit)) {
          return 0
        }
        return +new Big(this.units).times(this.product.costPerUnit)
      }
    },

    stockNetValueLocalized: currency('stockNetValue'),

    salesGross: {
      deps: ['unitsSold'],
      fn () {
        if (!isNumber(this.unitsSold) || !isNumber(this.product.pricePerUnit)) {
          return null
        }
        return +new Big(this.unitsSold).times(this.product.pricePerUnit)
      }
    },

    salesGrossLocalized: currency('salesGross'),

    salesNet: {
      deps: ['salesGross', 'eventVat'],
      fn () {
        if (!this.salesGross) return null
        return net(this.salesGross)
      }
    },

    salesNetLocalized: currency('salesNet'),

    vat: {
      deps: ['salesNet', 'salesGross', 'eventVat'],
      fn () {
        return vat(this.salesNet, this.salesGross)
      }
    },

    vatLocalized: currency('vat'),

    salesGrossAfterSpillage: {
      deps: ['salesGross', 'spillage'],
      fn () {
        if (!isNumber(this.salesGross) || !isNumber(this.spillage)) return null
        if (!this.product.canSpill) return this.salesGross
        const rate = new Big(100).minus(this.spillage).div(100)
        return +new Big(this.salesGross).times(rate)
      }
    }
  },

  update (transaction) {
    const locationId = this.collection.parent.id
    const isComplete = transaction.state === 'complete'

    if (isComplete && transaction.sourceId === locationId) {
      this.units = this.units - transaction.units
    } else if (isComplete && transaction.targetId === locationId) {
      this.units = this.units + transaction.units
    }

    return this
  }
})
