const View = require('ampersand-view')
const app = require('ampersand-app')
const localLinks = require('local-links')
const ViewSwitcher = require('ampersand-view-switcher')
const result = require('lodash/result')

const Sidebar = require('../sidebar/sidebar')
const { TaskboardView } = require('../taskboard/Taskboard')

const mq = window.matchMedia('(min-width: 1025px)')

module.exports = View.extend({
  template: require('./app.pug').default,

  events: {
    'click a[href]': 'handleLinkClick'
  },

  props: {
    isTaskboardEnabled: ['boolean', true, mq.matches],
    canRenderTaskboardSidebar: ['boolean', true, mq.matches]
  },

  derived: {
    showTaskboard: {
      deps: ['isTaskboardEnabled', 'canRenderTaskboardSidebar'],
      fn () {
        return this.isTaskboardEnabled && this.canRenderTaskboardSidebar
      }
    }
  },

  bindings: {
    showTaskboard: {
      type: 'booleanClass',
      yes: '',
      no: 'transit-bar-hidden'
    }
  },

  subviews: {
    sidebar: {
      hook: 'sidebar',
      prepareView (el) {
        return new Sidebar({ el, model: app.me })
      }
    },
    transitBar: {
      hook: 'transit-bar',
      waitFor: 'showTaskboard',
      prepareView (el) {
        return new TaskboardView({
          el,
          props: { onClose: this.toggleTaskboard.bind(this) }
        })
      }
    }
  },

  initialize () {
    this.listenTo(app.router, 'page', this.handleNewPage)
    this.listenTo(app.me, 'change:currentEventId', this.setTitle)
    this.listenTo(app.me, 'change:currentEvent.name', this.setTitle)
    this.on('change:showTaskboard', this.toggleTaskboardView, this)

    mq.addEventListener('change', this.onScreenSizeChange.bind(this))
  },

  /**
   * @param {MediaQueryListEvent} event
   */
  onScreenSizeChange (event) {
    this.canRenderTaskboardSidebar = event.matches
  },

  toggleTaskboard (evt) {
    evt.preventDefault()
    this.toggle('isTaskboardEnabled')
  },

  toggleTaskboardView (view, showTaskboard) {
    if (!showTaskboard) {
      if (this.transitBar) {
        this.transitBar.remove()
        this.transitBar = undefined
      }
    } else {
      if (!this.transitBar) {
        this.transitBar = this.registerSubview(
          new TaskboardView({
            props: { onClose: this.toggleTaskboard.bind(this) }
          })
        )
        this.el.appendChild(this.transitBar.render().el)
      }
    }
  },

  handleLinkClick (e) {
    // This module determines whether a click event is
    // a local click (making sure the for modifier keys, etc)
    // and dealing with browser quirks to determine if this
    // event was from clicking an internal link. That we should
    // treat like local navigation.
    const localPath = localLinks.pathname(e)

    if (localPath) {
      e.preventDefault()
      app.navigate(localPath)
    }
  },

  handleNewPage (view) {
    // tell the view switcher to render the new one
    this.pageSwitcher.set(view)

    // mark the correct nav item selected
    this.updateActiveNav()
  },

  updateActiveNav () {
    const path = window.location.pathname.slice(1)
    this.queryAll('.sidebar a[href]').forEach(function (aTag) {
      const aPath = aTag.pathname.slice(1)

      if ((!aPath && !path) || (aPath && path.indexOf(aPath) === 0)) {
        aTag.classList.add('active')
      } else {
        aTag.classList.remove('active')
      }
    })
  },

  setTitle (newView) {
    newView = newView.title ? newView : app.currentPage
    const eventName = app.me.currentEvent && app.me.currentEvent.name
    document.title =
      `${result(newView, 'title')} · ${eventName} · BoozeBoy` || 'BoozeBoy'
  },

  render () {
    const self = this
    // some additional stuff we want to add to the document head
    document.title = 'BoozeBoy'

    // main renderer
    this.renderWithTemplate()

    // add class indicating user role
    this.el.classList.add(app.me.role)

    // init and configure our page switcher
    this.pageSwitcher = new ViewSwitcher({
      el: this.query('main'),
      show (newView) {
        // it's inserted and rendered for me
        self.setTitle(newView)
        document.scrollingElement.scrollTop = 0

        // add a class specifying it's active
        newView.el.classList.add('active')

        // force setting of location hash to trigger scrolling
        window.requestAnimationFrame(() => {
          const hash = window.location.hash
          if (!hash) return
          document.scrollingElement.scrollTo({
            top: newView.query(hash).offsetTop,
            behavior: 'smooth'
          })
        })

        // don't keep a reference of the previous page around
        // should help reduce memory usage
        self.pageSwitcher.previous = null
        app.currentPage = newView
      }
    })

    return this
  }
})
