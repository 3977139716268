import { useState } from 'preact/hooks'
import { DateTime } from 'luxon'
import { i18nPackages, i18nUnits } from '../../utils'
import * as styles from './taskboard.module.css'

/**
 * @param {{
 *   source: import('../types').TransactionSource
 *   target: import('../types').TransactionTarget
 * }} props
 */
export function TaskboardItemRoute ({ source, target }) {
  return (
    <div class={styles.taskboardItemHeader}>
      <span>{source.name}</span>
      <i class='icon ion-arrow-right-c' />
      <span>{target.name}</span>
    </div>
  )
}

/**
 * @param {{
 *   role: 'admin' | 'dispatcher' | 'bar'
 *   transaction: import('../types').Transaction
 *   isGrouped?: boolean
 *   onUpdateTransaction(
 *     id: string,
 *     payload: {
 *       state?: import('../types').Transaction['state']
 *       comment?: string
 *     }
 *   ): void
 *   onDeleteTransaction(id: string): void
 *   onEditTransaction(tx: import('../types').Transaction): void
 * }} props
 */
export function TaskboardItem ({
  role,
  transaction,
  isGrouped,
  onUpdateTransaction,
  onDeleteTransaction,
  onEditTransaction
}) {
  const [showComment, setShowComment] = useState(false)

  const units = i18nUnits(transaction.units, transaction.product)
  const packages = i18nPackages(transaction.units, transaction.product)
  const isBarOrder = transaction.user.currentEventSettings.role === 'bar'

  return (
    <li class={styles.taskboardItem}>
      {!isGrouped && (
        <TaskboardItemRoute
          source={transaction.source}
          target={transaction.target}
        />
      )}

      <div class='pure-g'>
        <div class='pure-u-1-2'>
          <div>
            {packages && <div>{packages}</div>}
            {units && <div>{units}</div>}
          </div>
          <div class='item-name'>{transaction.product.name}</div>
        </div>
        <div class='pure-u-1-2'>
          <div class={styles.buttons}>
            {(role === 'admin' || role === 'dispatcher') && (
              <button
                class='btn-round-small btn-round pure-button'
                title='Transaktion bearbeiten'
                onClick={() => onEditTransaction(transaction)}
              >
                <i class='ion-edit' />
              </button>
            )}

            {(role === 'admin' ||
              role === 'dispatcher' ||
              (role === 'bar' && transaction.state === 'transit')) && (
                <button
                  class='btn-round-small btn-round pure-button'
                  title='Diese Transaktion stornieren?'
                  onClick={() => {
                    const ok = window.confirm(
                      'Diese Transaktion wirklich stornieren?'
                    )

                    if (ok) {
                      onDeleteTransaction(transaction.id)
                    }
                  }}
                >
                  <i class='ion-close' />
                </button>
            )}

            {(role === 'admin' || role === 'dispatcher') &&
              isBarOrder &&
              transaction.state === 'transit' && (
                <button
                  class='btn-round pure-button pure-button-primary'
                  title='Akzeptieren'
                  onClick={() =>
                    onUpdateTransaction(transaction.id, { state: 'accepted' })}
                >
                  <i class='icon ion-thumbsup' />
                </button>
            )}

            <button
              class='btn-round button-success pure-button'
              title='Bestätigen'
              onClick={() =>
                onUpdateTransaction(transaction.id, { state: 'complete' })}
            >
              <i class='icon ion-checkmark' />
            </button>
          </div>
        </div>
      </div>

      {!isGrouped && (
        <div class={styles.taskboardItemFooter}>
          <div class={styles.userWrap}>
            <span>von {transaction.user.name}</span>
            <div
              class={styles.commentBubble}
              title='Kommentar hinzufügen/anzeigen...'
              onClick={() => setShowComment(!showComment)}
            >
              {transaction.comment
                ? (
                  <i
                    class='ion-ios-chatbubble'
                    style={{ color: 'var(--button-blue)' }}
                  />
                  )
                : (
                  <i class='ion-ios-chatbubble-outline' />
                  )}
            </div>
          </div>

          <time dateTime={transaction.dispatchedAt}>
            {DateTime.fromISO(transaction.dispatchedAt).toRelative()}
          </time>
        </div>
      )}

      {!isGrouped && showComment && (
        <form
          class={styles.commentForm}
          onSubmit={(event) => {
            event.preventDefault()
            const data = new FormData(event.target)

            onUpdateTransaction(transaction.id, {
              comment: data.get('comment')?.toString()
            })
            setShowComment(false)
          }}
        >
          <div class={styles.textareaWrap}>
            <div class={styles.arrow} />
            <textarea
              class={styles.textarea}
              name='comment'
              placeholder='Kommentar hinzufügen...'
              defaultValue={transaction.comment}
            />
          </div>

          <button type='submit' class={styles.saveCommentButton}>
            Kommentar speichern...
          </button>
        </form>
      )}
    </li>
  )
}
