'use strict'

const View = require('ampersand-view')

const mixin = require('../popover-mixin')

/**
 * Confirm Removal Popover
 */
module.exports = View.extend(mixin, {
  template: require('./confirm-delete.pug').default,
  events: {
    'click [data-hook=confirm-delete]': 'confirm',
    'click [data-hook=cancel]': 'remove'
  },

  initialize (opts) {
    this.setButton(opts.button)
    this.opts = opts

    this.model.once('sync', this.remove, this)
  },

  confirm () {
    this.model.destroy()
  },

  render () {
    mixin.render.apply(this, arguments)
    return this
  }
})
