'use strict'

const Model = require('ampersand-model')

module.exports = Model.extend({
  props: {
    id: {
      type: 'string'
    },
    role: {
      type: 'string',
      default: 'dispatcher',
      values: ['dispatcher', 'bar', 'admin']
    },

    firstName: 'string',
    lastName: 'string',

    username: 'string',
    password: 'string'
  },

  session: {
    name: 'string'
  },

  derived: {
    roleLocalized: {
      deps: ['role'],
      fn () {
        switch (this.role) {
          case 'admin':
            return 'Administrator'
          case 'dispatch':
            return 'Dispatcher'
          case 'bar':
            return 'Barchef'
          default:
            return ''
        }
      }
    }
  }
})
