'use strict'

const View = require('ampersand-view')
const app = require('ampersand-app')

module.exports = View.extend({
  template: require('./templates/stock-meta-form.pug').default,

  events: {
    'keyup input': 'onKeyup',
    'change input': 'onKeyup',
    'click [data-hook=cancel]': 'onCancel',
    'blur textarea': 'onBlur',
    submit: 'onSubmit'
  },

  bindings: {
    'model.tokens': {
      hook: 'tokens'
    },
    'model.tokensLocalized': {
      hook: 'tokens-localized'
    },
    'model.salesWithoutTokensLocalized': {
      hook: 'sales-without-tokens'
    },
    'model.sumSoldLocalized': {
      hook: 'sum-sold'
    },
    'model.differenceWithoutTokensLocalized': {
      hook: 'difference'
    },
    'model.isDifferenceNegative': {
      type: 'booleanClass',
      name: 'error',
      hook: 'difference'
    },
    'model.isSumSoldNegative': [
      {
        type: 'booleanClass',
        name: 'error',
        hook: 'sum-sold'
      },
      {
        type: 'booleanClass',
        name: 'error',
        hook: 'sales-without-tokens'
      }
    ]
  },

  derived: {
    isAdmin: {
      cache: false,
      fn () {
        return app.me.role === 'admin'
      }
    }
  },

  initialize () {
    this.listenTo(this.model, 'sync', this.render)
    this.listenTo(this.model, 'change', this.onChange)
    this.listenTo(this.model, 'request', this.onRequest)
    this.listenTo(this.model, 'error', this.onError)
  },

  onChange (model) {
    this.queryByHook('submit').disabled = !model.isValid()
  },

  onKeyup (event) {
    const isNumber =
      event.target.type === 'number' && event.target.name !== 'amountGiven'
    const val = isNumber ? event.target.value - 0 : event.target.value
    this.model.set(event.target.name, val)
  },

  onBlur (event) {
    this.model.set('comment', event.target.value)
  },

  onCancel (event) {
    event.preventDefault()
    app.navigate(this.parent.backUrl)
  },

  remove () {
    clearTimeout(this.pulsing)
    return View.prototype.remove.apply(this)
  },

  onRequest () {
    this.pulsing = setTimeout(() => {
      this.queryByHook('submit').classList.add('animated', 'pulse', 'infinite')
    }, 1000)
    this.queryByHook('submit').disabled = true
    this.queryByHook('cancel').disabled = true
  },

  onError (model, err) {
    clearTimeout(this.pulsing)
    console.error(err)
    this.queryByHook('submit').classList.remove('animated', 'pulse', 'infinite')
    this.queryByHook('submit').disabled = false
    this.queryByHook('cancel').disabled = false
  },

  onSubmit (event) {
    event.preventDefault()
    this.listenToOnce(this.model, 'sync', () =>
      app.navigate(this.parent.backUrl)
    )
    this.model.save()
  }
})
