- var pattern = "^(\\d+(\\.?|,)\\d{0,2}\\+?)*$"
tr
  td.item-name #{model.product.name}

  td
    .pure-g
      .pure-u-1-2
        label(for="inputPackages") #{model.product.package}
        input(type="text", name="inputPackages", pattern=pattern, placeholder=0, value=model.inputPackages || null)
      .pure-u-1-2
        label(for="inputUnits") #{model.product.unit}
        input(type="text", name="inputUnits", pattern=pattern, placeholder=0, value=model.inputUnits || null)

    if model.product.factor !== 1
      .pure-g
        .pure-u-1-1.weight
          .pure-g.flex
            .pure-u-3-4.weight-heading #{model.product.unit} nach Gewicht
            .pure-u-1-4.right.weight-heading
              a(href="#", role="button", title="Hinzufügen...", data-hook="add-weight")
                i.ion-ios-plus-outline
          .pure-g
            ul(data-hook="weights").list.pure-u-1

    .pure-g
      div(data-hook="error").error

  td(data-hook="sales")
    div(data-hook="packages-sold") #{model.packagesSoldLocalized}
    div(data-hook="units-sold") #{model.unitsSoldLocalized}

