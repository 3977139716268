.combobox {
  position: relative;
}

.input {
  width: 100%;
}

.button {
  position: absolute;
  top: 0;
  /* Leave room for input border */
  bottom: 1px;
  right: 0;
  border: none;
  width: 30px;
  cursor: pointer;
  background-color: white;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2220%22%20width%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22%23d8d8d8%22%3E%3Cpath%20d%3D%22M4.516%207.548c0.436-0.446%201.043-0.481%201.576%200l3.908%203.747%203.908-3.747c0.533-0.481%201.141-0.446%201.574%200%200.436%200.445%200.408%201.197%200%201.615-0.406%200.418-4.695%204.502-4.695%204.502-0.217%200.223-0.502%200.335-0.787%200.335s-0.57-0.112-0.789-0.335c0%200-4.287-4.084-4.695-4.502s-0.436-1.17%200-1.615z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 5px top 50%;
  background-size: 20px 20px;
}

.options {
  position: absolute;
  z-index: 100;
  margin-top: 5px;
  max-height: 240px;
  width: 100%;
  overflow: auto;
  padding: 5px 0;
  background-color: white;
  border: var(--border);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
}

.option {
  position: relative;
  padding: 5px 10px;
  padding-left: 30px;
  cursor: default;
  user-select: none;
  font-size: 0.875em;
}

.optionActive {
  color: white;
  background-color: var(--button-blue);
}

.optionSelected {
  font-weight: 700;
}

.optionLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iconSelected {
  position: absolute;
  left: 10px;
  color: var(--button-blue);
}

.optionActive .iconSelected {
  color: inherit;
}
