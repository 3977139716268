'use strict'

const View = require('ampersand-view')
const WeightView = require('./weight-view')

// parse and add up strings like "0.2 + 0,4 +  1,3"
function calculator (str = '') {
  const sum = str.replace(/,/g, '.')
    .replace(/\s*/g, '')
    .split('+')
    .map((v) => +v)
    .reduce((a, b) => a + b, 0)

  return Math.round(sum * 100) / 100
}

module.exports = View.extend({
  template: require('./templates/stock-taking-item.pug').default,

  events: {
    'keyup input': 'onKeyUp',
    'change input': 'onKeyUp',
    'click [data-hook=add-weight]': 'addWeight'
  },

  bindings: {
    'model.unitsSoldLocalized': {
      hook: 'units-sold'
    },
    'model.packagesSoldLocalized': {
      hook: 'packages-sold'
    },
    'model.isUnitsSoldNegative': {
      type: 'booleanClass',
      name: 'error',
      hook: 'sales'
    },
    'model.inputPackages': {
      selector: 'input[name=inputPackages]',
      type: 'value'
    },
    'model.inputUnits': {
      selector: 'input[name=inputUnits]',
      type: 'value'
    }
  },

  subviews: {
    weightList: {
      hook: 'weights',
      prepareView (el) {
        return this.renderCollection(this.model.weight, WeightView, el)
      }
    }
  },

  initialize () {

  },

  onKeyUp (event) {
    event.stopPropagation()
    const sum = calculator(event.target.value)
    this.model.set(event.target.name, sum)
  },

  addWeight (event) {
    event.preventDefault()
    this.model.weight.add({ productId: this.model.productId })
  }
})
