tfoot
  tr
    td
    td(colspan="2") Summe

    td #{model.sumSalesGross}
    td #{model.sumSalesNet}
      span.append Mwst. #{model.vat}
    td
  tr
    td
    td(colspan="2") abzgl. Schankverlust

    td #{model.sumSalesGrossAfterSpillage}
    td #{model.sumSalesNetAfterSpillage}
      span.append Mwst. #{model.vatAfterSpillage}
    td
  tr
    td
    td(colspan="2") abzgl. Personalmarken

    td #{model.sumSalesGrossAfterSpillageWithoutTokens}
    td #{model.sumSalesNetAfterSpillageWithoutTokens}
      span.append Mwst. #{model.vatAfterSpillageWithoutTokens}
    td
