import app from 'ampersand-app'
import CachedCollection from './cached-collection'
import State from 'ampersand-state'
import debounce from 'lodash/debounce'
import assign from 'lodash/assign'
import pick from 'lodash/pick'
import { DateTime } from 'luxon'

import { indexify } from '../utils'
import Transaction from './transaction'

const SearchState = State.extend({
  props: {
    q: ['string'],
    source: ['string'],
    target: ['string'],
    product: ['string'],
    start: ['date'],
    end: ['date']
  },

  derived: {
    filtered: {
      deps: ['q', 'source', 'target', 'product', 'start', 'end'],
      fn () {
        return ['q', 'source', 'target', 'product', 'start', 'end']
          .map((prop) => !!this[prop])
          .reduce((a, b) => a || b)
      }
    },
    activeSource: {
      deps: ['source'],
      fn () {
        return (app.locations.get(this.source) || {}).name
      }
    },
    activeTarget: {
      deps: ['target'],
      fn () {
        return (app.locations.get(this.target) || {}).name
      }
    },
    activeProduct: {
      deps: ['product'],
      fn () {
        return (app.products.get(this.product) || {}).name
      }
    },
    startLocalized: {
      deps: ['start'],
      fn () {
        return DateTime.fromJSDate(this.start).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    },
    endLocalized: {
      deps: ['end'],
      fn () {
        return DateTime.fromJSDate(this.end).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    }
  },

  parse (res) {
    if (!res) return res
    // translate slugs to ids
    return assign(res, {
      source: (app.locations.findWhere({ slug: res.source }) || {}).id,
      target: (app.locations.findWhere({ slug: res.target }) || {}).id,
      product: (app.products.findWhere({ slug: res.product }) || {}).id
    })
  }
})

export const Meta = State.extend({
  props: {
    start: 'date',
    end: 'date',
    count: ['number', false, 0]
  }
})

export default CachedCollection.extend({
  url () {
    return `/api/events/${app.me.currentEvent.slug}/transactions`
  },

  model: Transaction,

  storageKey () {
    return app.me.currentEvent.slug + '-transactions'
  },

  // Nach Zeit absteigend und dann Name aufsteigend sortieren
  comparator (a, b) {
    const diff = b.dispatchedAt.getTime() - a.dispatchedAt.getTime()
    if (diff === 0) {
      return a.product.name > b.product.name ? 1 : -1
    }
    return diff
  },

  initialize (data, opts) {
    opts = assign({ watch: true, cache: true }, opts)
    // metadata model: need to init it before the storage mixin
    this.meta = new Meta()

    // populate search state with query params
    this.state = new SearchState(opts.query, { parse: true })

    if (opts.cache) {
      this.initStorage()
      this.on('change sync', this.writeToStorage, this)
      this.on('stale empty', this.fetchWithParams, this)
    }

    if (opts.watch) this.listenTo(app.changes, 'transactions', this.onChange)

    this.listenTo(this.state, 'change', this._fetchDebounced)
  },

  bulkSave () {
    const self = this
    // only save transactions with units
    const toBeSaved = this.filter((transaction) => !!transaction.units)
    const opts = {
      attrs: toBeSaved,
      success (res) {
        self.trigger('sync', self, res, opts)
      }
    }
    this.sync('create', this, opts)
  },

  allSaved () {
    return this.filter((model) => !model.isNew()).length === this.length
  },

  search (term) {
    this.state.q = term

    // reset search
    if (!this.state.q) {
      this._fetchDebounced()
      return
    }
    if (this.state.q.length < 2) return

    // filter original (local) models
    const localQuery = indexify(term)
    const filtered = this.filter((transaction) => {
      const productMatches =
        transaction.product._nameIndex.indexOf(localQuery) > -1
      const sourceMatches =
        transaction.source._nameIndex.indexOf(localQuery) > -1
      const targetMatches =
        transaction.target._nameIndex.indexOf(localQuery) > -1
      const userMatches =
        indexify(transaction.user.name).indexOf(localQuery) > -1
      return productMatches || sourceMatches || targetMatches || userMatches
    })
    this.reset(filtered)

    // ask for more on the server
    this._fetchDebounced()
  },

  serializeToStorage () {
    return assign({ data: this.toJSON() }, this.meta.toJSON())
  },

  parseFromStorage (data) {
    return this.parse(data)
  },

  fetchWithParams () {
    const params = pick(this.state.toJSON(), 'q', 'source', 'target', 'product')
    if (this.state.start) params.start = this.state.start.toISOString()
    if (this.state.end) params.end = this.state.end.toISOString()
    this.fetch({ data: params })
  },

  _fetchDebounced: debounce(function () {
    this.fetchWithParams()
  }, 100),

  parse (res) {
    // set metadata
    if (!Array.isArray(res)) this.meta.set(res)
    // return transactions list
    return Array.isArray(res) ? res : res.data
  },

  fetchMore () {
    this.fetch({
      remove: false,
      data: assign({ offset: this.length }, this.state.toJSON())
    })
  },

  onChange (change) {
    if (change.type === 'delete') {
      this.remove(change.id)
      return
    }

    if (change.type === 'insert' || change.type === 'update') {
      this.set(change.row, { remove: false })
    }
  }
})
