'use strict'

const app = require('ampersand-app')
const Big = require('big.js')
const isnumber = require('lodash/isNumber')

const utils = require('../utils')
const { unitsLocalized, packagesLocalized } = utils
const StockCollection = require('./stock-collection')
const { StockItem } = require('./stock-item')

const DashboardItem = StockItem.extend({
  props: {
    id: 'string',
    delivery: 'number',
    breakage: 'number',
    tokens: 'string',
    freeware: 'number',
    unitsSold: ['number', false, 0]
  },

  derived: {
    product: {
      deps: ['id'],
      fn () {
        return app.products && app.products.get(this.id)
      }
    },

    freewareUnitsLocalized: unitsLocalized('freeware'),
    freewarePackagesLocalized: packagesLocalized('freeware'),

    unitsSoldLocalized: unitsLocalized('unitsSold'),
    packagesSoldLocalized: packagesLocalized('unitsSold'),

    deliveryUnitsLocalized: unitsLocalized('delivery'),
    deliveryPackagesLocalized: packagesLocalized('delivery'),

    ratio: {
      deps: ['delivery', 'units'],
      fn () {
        if (!this.delivery) return 0
        return this.units / this.delivery
      }
    },
    ratioLocalized: {
      deps: ['ratio'],
      fn () {
        if (!isnumber(this.ratio)) return null
        return (new Big(this.ratio).times(100).round(1) + ' %').replace(
          '.',
          ','
        )
      }
    }
  },

  initialize () {}
})

module.exports = StockCollection.extend({
  url () {
    return `/api/events/${app.me.currentEvent.slug}/dashboard/products`
  },
  model: DashboardItem,
  comparator: utils.byStockSortPosition,

  // override StockCollection
  mainIndex: 'id',

  // ttl: CachedCollection.prototype.ttl,
  // tts: CachedCollection.prototype.tts,

  // storageKey () {
  //   return `${app.me.currentEventId}-dashboard-products`
  // },

  initialize () {
    StockCollection.prototype.initialize.apply(this)
  },

  // override StockCollection
  onTransaction () {}
})
