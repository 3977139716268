'use strict'

const assign = require('lodash/assign')
const FilteredCollection = require('ampersand-filtered-subcollection')
const LocationBasePage = require('../location-base/location-base-page')
const app = require('ampersand-app')
const BarView = require('./bar-view')
const Bar = require('../../models/bar')

/*
  Seite - Barübersicht
 */
module.exports = LocationBasePage.extend({
  title: 'Bars',
  template: require('./bar-page.pug').default,

  events: assign({}, LocationBasePage.prototype.events, {}),

  derived: {
    isBarchef: {
      cache: false,
      fn () {
        return app.me.role === 'bar'
      }
    }
  },

  initialize () {
    const where = { type: 'bar' }

    /**
     * The endpoint returns multiple locations even for barchefs based on the settings:
     * - defaultSourceLocationId
     * - defaultTargetLocationId
     * - assignedLocationId
     * ...we only want to display the assigned location, though.
     */
    if (app.me.assignedLocationId) {
      where.id = app.me.assignedLocationId
    }
    // render from cache and fetch updates in the background
    this.collection = new FilteredCollection(app.locations, {
      where
    })

    this.collection.filterFn = function (model) {
      return model.type === 'bar'
    }

    app.locations.fetch()
  },

  /**
   * Adds a new location to the collection
   */
  onNew () {
    this.renderSubview(
      new BarView({
        model: new Bar(),
        parent: this
      }),
      '[data-hook="new-location"]'
    )
  }
})
