'use strict'

const Model = require('ampersand-model')
const Collection = require('ampersand-rest-collection')
const User = require('./user')

const UsersCollection = Collection.extend({
  url () {
    return `/api/events/${this.parent.id}/users`
  },
  model: User,
  comparator: 'name'
})

module.exports = Model.extend({
  urlRoot: '/api/events',

  props: {
    id: 'string',
    name: ['string', true],
    vat: ['number', true, 19],
    spillage: ['number', true, 3],
    tokenValue: ['number', true, 4],
    slug: 'string',
    // role of the current user
    role: 'string',

    // clone param
    clone: 'string'
  },

  derived: {
    roleLocalized: {
      deps: ['role'],
      fn () {
        switch (this.role) {
          case 'admin':
            return 'Administrator'
          case 'dispatcher':
            return 'Dispatcher'
          case 'bar':
            return 'Barchef'
          default:
            return ''
        }
      }
    }
  },

  collections: {
    users: UsersCollection
  }
})
