.textButton {
  display: inline-block;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--button-blue);
  padding: 4px 6px;
}

.textButton:hover {
  text-decoration: underline;
}

.textButtonError {
  composes: textButton;
  color: var(--highlight-red);
}


