.page.settings-page
  h1 Einstellungen

  .pure-g
    .pure-u-1-2
      .pure-g
        .pure-u-1
          h2 Events
      .pure-g
        .pure-u-20-24
          .append
            | Hier kannst du Events erstellen, zwischen ihnen wechseln und, wenn du Administrator bist,
            | Einstellungen ändern, andere Nutzer hinzufügen oder das Event löschen.
      .pure-g
        .pure-u-1
          ul(data-hook="event-list").list
          a(href="#", role="button", data-hook="add") Neues Event erstellen...
      .pure-g(data-hook="create-event")

    .pure-u-1-2
      h2 Du
      .pure-g
        .pure-u-1
          div(data-hook="username-form")
      .pure-g
        .pure-u-1
          div(data-hook="password-form")
