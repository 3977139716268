'use strict'

const app = require('ampersand-app')
const View = require('ampersand-view')
const debounce = require('lodash/debounce')

const { default: Transactions } = require('../../models/transaction-collection')
const SearchBarView = require('../search-bar')
const TransactionRow = require('./transaction-row')
const { default: Slider } = require('./slider.js')
const AttributeFilter = require('../attribute-filter')

// View displaying filter state
const SearchStateView = View.extend({
  template: require('./search-state.pug').default,
  events: {
    'click [data-hook=remove-filter]': 'onRemoveFilter',
    'click [data-hook=reset-filters]': 'onResetFilter'
  },
  initialize () {
    this.listenTo(this.model, 'change', this.render)
  },
  onRemoveFilter (event) {
    this.model.set(event.target.dataset.prop, null)
  },
  onResetFilter (event) {
    event.preventDefault()
    this.model.clear()
  }
})

module.exports = View.extend({
  title: 'Transaktionen',
  template: require('./transaction-page.pug').default,

  events: {
    'click [data-hook="filter-by-source"]': 'filterBySource',
    'click [data-hook="filter-by-target"]': 'filterByTarget',
    'click [data-hook="filter-by-product"]': 'filterByProduct'
  },

  props: {
    searchTerm: 'string',
    count: ['number', false, 0],
    displayCount: ['number', false, 0]
  },

  subviews: {
    searchBar: {
      hook: 'search-bar',
      prepareView (el) {
        return new SearchBarView({
          placeholder: 'Produkt, Ort, Nutzer',
          model: this.collection.state,
          el,
          parent: this
        })
      }
    },
    slider: {
      hook: 'slider',
      prepareView (el) {
        return new Slider({
          model: this.collection.meta,
          state: this.collection.state,
          el,
          parent: this
        })
      }
    },
    searchState: {
      hook: 'search-state',
      prepareView (el) {
        return new SearchStateView({
          model: this.collection.state,
          el,
          parent: this
        })
      }
    }
  },

  bindings: {
    displayCount: {
      hook: 'displayCount'
    },
    count: {
      hook: 'count'
    }
  },

  initialize (opts) {
    // needed for admin check in template
    this.me = app.me

    this.collection = new Transactions(null, opts)
    this.on('change:searchTerm', this.triggerSearch, this)
    this.listenTo(this.collection.state, 'change', this.setQueryString)

    this.listenTo(this.collection, 'add remove', this.onAddRemove)
    this.on('change:displayCount', this.onLengthChange, this)

    this._scrollListener = this.infiniteScroll.bind(this)
    window.addEventListener('scroll', this._scrollListener, false)
  },

  infiniteScroll: debounce(function () {
    if (
      window.scrollY >=
      document.body.offsetHeight - window.innerHeight - 200
    ) {
      this.collection.fetchMore()
    }
  }, 50),

  setQueryString (state) {
    const params = new URLSearchParams()
    const opts = {
      q: state.q,
      source: (app.locations.get(state.source) || {}).slug,
      target: (app.locations.get(state.target) || {}).slug,
      product: (app.products.get(state.product) || {}).slug,
      start: state.start && state.start.getTime(),
      end: state.end && state.end.getTime()
    }

    for (const [key, value] of Object.entries(opts)) {
      if (value || value === 0) {
        params.set(key, value)
      }
    }
    let qs = params.toString()
    if (qs.length) {
      qs = `?${qs}`
    }

    history.replaceState(history.state, '', `${location.pathname}${qs}`)
  },

  filterBySource () {
    this.showAttributeFilter('source', 'Quelle', app.locations)
  },

  filterByTarget () {
    this.showAttributeFilter('target', 'Ziel', app.locations)
  },

  filterByProduct () {
    this.showAttributeFilter('product', 'Produkt', app.products)
  },

  showAttributeFilter (prop, title, collection) {
    const button = this.queryByHook('filter-by-' + prop)
    // popover toggle behavior
    if (this.popover) this.popover.remove()

    this.popover = new AttributeFilter({
      prop,
      button,
      name: title,
      collection,
      state: this.collection.state
    })
    this.registerSubview(this.popover.render())
  },

  onAddRemove () {
    this.count = this.collection.meta.count
    this.displayCount = this.collection.length
  },

  remove () {
    View.prototype.remove.call(this)
    window.removeEventListener('scroll', this._scrollListener, false)
    this.collection.stopListening()
    this.collection.off()
  },

  // will be called by SearchBarView
  search (query) {
    this.searchTerm = query
  },

  triggerSearch () {
    this.collection.search(this.searchTerm)
  },

  render () {
    this.renderWithTemplate(this)
    this.renderCollection(
      this.collection,
      TransactionRow,
      '[data-hook=transaction-list]'
    )
    return this
  }
})
