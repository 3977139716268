nav.sidebar
  div(data-hook="dispatch-button-placeholder")
  if model.role !== 'bar'
    a(href="/", data-title="Übersicht").toggleable
      i.icon.ion-stats-bars
      .label Übersicht
  a(href="/bars", data-title=`${model.role === 'bar' ? 'Meine Bar' : 'Bars'}`).toggleable
    i.icon.ion-ios-wineglass
    if model.role === 'bar'
      .label Meine Bar
    else
      .label Bars
  if model.role !== 'bar'
    a(href="/lager", data-title="Lager").toggleable
      i.icon.ion-ios-box
      .label Lager
    a(href="/transaktionen", data-title="Transaktionen").toggleable
      i.icon.ion-clipboard
      .label Transaktionen
    a(href="/produkte", data-title="Produkte").toggleable
      i.icon.ion-cube
      .label Produkte
  .bottom
    div(data-hook="taskboard-toggle")
    a(href="/taskboard", data-title="Taskboard anzeigen").mobile-only
      i.icon.ion-clipboard
      .label Taskboard
    a(href="/einstellungen", data-title="Einstellungen")
      i.icon.ion-ios-gear
      .label Einstellungen
    a(href="/logout", data-title="Abmelden...").logout
      i.icon.ion-log-out
      .label Abmelden
    .sync-indicator(data-hook="sync-indicator")
