'use strict'

const View = require('ampersand-view')
const flatten = require('lodash/flatten')
const groupBy = require('lodash/groupBy')

const app = require('ampersand-app')
const { currency } = require('../../utils')

const add = (a, b) => a + b

module.exports = View.extend({
  template: require('./best-products-card.pug').default,

  initialize () {
    const items = flatten(
      app.locations
        .filter((location) => location.type === 'bar')
        .map((location) => location.stock.models)
    )
    const groups = groupBy(items, (item) => item.product.id)

    this.products = Object.keys(groups)
      .map((id) => {
        const sum = groups[id].map((item) => item.salesGross).reduce(add)
        return {
          id,
          name: app.products.get(id).name,
          sumLocalized: currency(sum),
          sum
        }
      })
      .sort((a, b) => b.sum - a.sum)
  }
})
