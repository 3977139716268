'use strict'

const app = require('ampersand-app')
const View = require('ampersand-view')

const StockTakingDynamic = require('../../models/stock-taking-dynamic')
const SearchBarView = require('../search-bar')
const PopoverButton = require('../popover-button')
const ConfirmPopover = require('../confirm-delete')

const ItemView = View.extend({
  template: require('./stock-taking-details-item.pug').default,

  derived: {
    // build link to transactions page
    transactionsLink: {
      cache: false,
      fn () {
        const inv = this.model.collection.parent
        const params = {
          q: inv.location.name,
          product: this.model.product.slug,
          end: inv.shiftEndTime.getTime()
        }
        if (this.model.lastInventoryTime) { params.start = this.model.lastInventoryTime.getTime() }
        return `/transaktionen?${new URLSearchParams(params)}`
      }
    }
  }
})

module.exports = View.extend({
  template: require('./stock-taking-details.pug').default,

  title () {
    return `Inventur für ${this.location.name}`
  },

  derived: {
    backUrl: {
      cache: false,
      fn () {
        if (
          this.location.type === 'storage' ||
          this.location.type === 'crash'
        ) {
          return `/lager/${this.location.slug}/inventuren`
        } else {
          return `/bars/${this.location.slug}/inventuren`
        }
      }
    },
    editUrl: {
      cache: false,
      deps: ['backUrl'],
      fn () {
        return `${this.backUrl}/${this.model.id}/edit`
      }
    },
    exportUrl: {
      cache: false,
      fn () {
        return `/api/events/${app.me.currentEvent.slug}/locations/${this.location.id}/inventories/${this.model.id}/export`
      }
    }
  },

  initialize (opts) {
    this.location = opts.location
    this.model = new StockTakingDynamic({
      locationId: opts.location.id,
      id: opts.id
    })
    this.listenTo(this.model, 'sync', this.render)
    this.listenTo(this.model, 'destroy', this.onDestroy)
    this.model.fetch()
  },

  // will be called by SearchBarView
  search (query) {
    this.model.items.byName(query)
  },

  onDestroy () {
    app.navigate(this.backUrl)
    this.remove()
  },

  renderDeleteButton () {
    this.deleteButton = new PopoverButton({
      el: this.queryByHook('delete'),
      icon: 'ion-ios-trash',
      title: 'Inventur löschen...',
      classes: 'button-error',
      Popover: ConfirmPopover,
      popoverOpts: {
        model: this.model,
        name: 'Inventur',
        position: 'bottom'
      }
    })
    this.registerSubview(this.deleteButton.render())
  },

  render () {
    this.renderWithTemplate()
    this.searchBar = new SearchBarView({
      el: this.queryByHook('search-bar'),
      parent: this
    })
    this.registerSubview(this.searchBar.render())
    this.renderDeleteButton()

    this.renderCollection(this.model.items, ItemView, '[data-hook=item-list]')
    return this
  }
})
