'use strict'

const Model = require('ampersand-model')
const app = require('ampersand-app')

const { indexify, slugify } = require('../utils')
const Stock = require('./stock-collection')

/*
  Model representing a location
 */

module.exports = Model.extend({
  urlRoot () {
    return `/api/events/${app.me.currentEvent.slug}/locations`
  },

  props: {
    id: ['string'],
    name: ['string', true],
    type: {
      type: 'string',
      required: true,
      values: ['bar', 'storage', 'crash', 'delivery']
    },
    sortPosition: ['number']
  },

  collections: {
    stock: Stock
  },

  session: {
    editable: ['boolean', true, false]
  },

  derived: {
    slug: {
      deps: ['name'],
      fn () {
        return slugify(this.name)
      }
    },

    link: {
      deps: ['slug', 'type'],
      fn () {
        if (this.type === 'storage' || this.type === 'crash') {
          return `/lager#${this.slug}`
        }

        if (this.type === 'bar') {
          return `/bars#${this.slug}`
        }
      }
    },

    _nameIndex: {
      deps: ['name'],
      fn () {
        return indexify(this.name)
      }
    }
  }
})
