'use strict'

const Collection = require('ampersand-collection')
const app = require('ampersand-app')

const { byStockSortPosition, indexify } = require('../utils')

module.exports = Collection.extend({
  url () {
    return `/api/events/${app.me.currentEvent.slug}/locations/${this.locationId}/inventories`
  },

  model: require('./stock-taking-item'),
  comparator: byStockSortPosition,
  mainIndex: 'productId',

  initialize () {
    this.filtered = false
  },

  byName (term) {
    term = indexify(term)

    // save unfiltered models for later
    if (!this.filtered) this._originalModels = this.models

    // reset search
    if (!term) {
      this.reset(this._originalModels)
      this.filtered = false
      return
    }
    if (term.length < 2) return

    // filter original models
    const filtered = this._originalModels.filter((item) => {
      return item.product._nameIndex.indexOf(term) > -1
    })
    this.reset(filtered)
    this.filtered = true
  }
})
