import { Combobox as HeadlessCombobox } from '@headlessui/react'
import { Fragment } from 'preact'
import { useMemo, useState } from 'preact/hooks'
import Fuse from 'fuse.js'
import * as styles from './combobox.module.css'

/**
 * @param {{
 *   name?: string
 *   placeholder?: string
 *   options: { value: string; label: string; disabled?: boolean }[]
 *   value: string
 *   onChange(value: string): void
 * }} props
 * @returns
 */
export function Combobox ({ name, placeholder, options, value, onChange }) {
  const [query, setQuery] = useState('')
  const fuse = useMemo(
    () =>
      new Fuse(options, {
        keys: ['label'],
        minMatchCharLength: 2,
        threshold: 0.2
      }),
    [options]
  )

  const filteredOptions =
    query === '' || query.length < 2
      ? options
      : fuse.search(query).map((result) => result.item)

  return (
    <HeadlessCombobox name={name} value={value} onChange={onChange}>
      <div className={styles.combobox}>
        <HeadlessCombobox.Input
          placeholder={placeholder}
          spellcheck={false}
          className={styles.input}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={() =>
            options.find((option) => option.value === value)?.label || ''}
        />
        <HeadlessCombobox.Button className={styles.button}>
          <span hidden>Menü offnen</span>
        </HeadlessCombobox.Button>
        {filteredOptions.length > 0 && (
          <HeadlessCombobox.Options className={styles.options}>
            {filteredOptions.map((option) => (
              /* Use the `active` state to conditionally style the active option. */
              /* Use the `selected` state to conditionally style the selected option. */
              <HeadlessCombobox.Option
                as={Fragment}
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {({ active, selected }) => (
                  <li
                    className={`${styles.option} ${
                      active ? styles.optionActive : ''
                    } ${selected ? styles.optionSelected : ''}`}
                  >
                    {selected && (
                      <span class={`${styles.iconSelected} ion-checkmark`} />
                    )}
                    <div class={styles.optionLabel}>{option.label}</div>
                  </li>
                )}
              </HeadlessCombobox.Option>
            ))}
          </HeadlessCombobox.Options>
        )}
      </div>
    </HeadlessCombobox>
  )
}
