.page.welcome-page
  h1 Hallo #{model.name}!
    .append Herzlich Willkommen bei BoozeBoy!
  .pure-g
    .pure-u-1-2
      p.
        Bevor du anfangen kannst Produkte, Lager und Bars anzulegen, solltest du
        ein <abbr title="Zu einem Event gehören Orte, Produkte, Nutzer und Transaktionen.">Event</abbr>
        erstellen oder auswählen.
        Für jedes Event, das du erstellst, bist du Administrator und kannst alle Informationen
        einsehen und bearbeiten sowie Nutzer hinzufügen und entfernen.
      p.append Deine Events kannst du später in den <a href="/einstellungen">Einstellungen</a> verwalten.

    .pure-u-1-2
      div(data-hook="events-list")
      div(data-hook="create-event")
