.stock-taking
  .pure-g
    .pure-u-1-2
      h1
        a(href=backUrl).btn-back: i.ion-ios-arrow-back
        span Inventur #{location.name}
  section.stock-taking-section
    h2.stock-taking-section-header
      span Schicht
      if model.isNew()
        button(data-hook="prefill").pure-button Erstbestückung
    div(data-hook="shift-end-form")

  section.stock-taking-section
    h2.stock-taking-section-header Bestand
    div(data-hook="entry-form")

  section.stock-taking-section
    h2.stock-taking-section-header Weitere Informationen
    div(data-hook="meta-form")
