'use strict'

const Collection = require('ampersand-rest-collection')
const cacheMixin = require('ampersand-local-cache-mixin')
const ms = require('milliseconds')
const result = require('lodash/result')
const { logger } = require('../utils')

module.exports = Collection.extend(cacheMixin, {
  // cache options
  ttl: ms.years(1),
  tts: ms.seconds(1),

  initialize () {
    this.initStorage()
    this.on('stale empty', () => this.fetch({ reset: true }), this)
    this.on('change sync', this.writeToStorage, this)
  },

  initStorage () {
    this.log = logger.extend('cache:' + result(this, 'storageKey'))
    this.log('init', this.ttl, this.tts)
    cacheMixin.initStorage.apply(this)
  },

  serializeToStorage () {
    this.log('serialize')
    return this.toJSON()
  },

  parseFromStorage (data) {
    this.log('hit')
    return data
  }
})
