tr
  td
  td
    if model.product.deletedAt
      i(title=`${model.product.name} wurde gelöscht.`).is-deleted.ion-trash-a
    span(data-hook="name").item-name #{model.product.name}

    if model.lastInventoryId
      .append
        | Letzte Inventur:&nbsp;
        if isAdmin
          a(href=`${model.lastInventoryUrl}`) #{model.lastInventoryTimeLocalized}
        else
          | #{model.lastInventoryTimeLocalized}

  td.volume(data-hook="units", title=`Seit letzter Inventur: ${model.packagesSinceInventoryLocalized || ''} ${model.unitsSinceInventoryLocalized || ''}`)
    span(data-hook="packages-localized").packages #{model.packagesLocalized}
    span(data-hook="units-localized").units #{model.unitsLocalized}

  if isAdmin
    td #{model.salesGrossLocalized}
      .append #{model.packagesSoldLocalized}
      .append #{model.unitsSoldLocalized}
    td #{model.salesNetLocalized}
      .append Mwst. #{model.vatLocalized}

  td.action(data-hook="dispatch-button")
