.dialog {
  position: relative;
  z-index: 200;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(4px);
}

.backdropEnter {
  transition-property: opacity;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.backdropEnterFrom {
  opacity: 0;
}

.backdropEnterTo {
  opacity: 100;
}

.backdropLeave {
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.backdropLeaveFrom {
  opacity: 100;
}

.backdropLeaveTo {
  opacity: 0;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 0 30px;
  overflow-y: auto;
}

.dialogPanel {
  position: relative;
  padding: 30px;
  background-color: white;
  width: 650px;
  border: var(--border);
  border-radius: 3px;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.panelEnter {
  transition-property: opacity, transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.panelEnterFrom {
  opacity: 0;
  transform: scale(0.95);
}

.panelEnterTo {
  opacity: 100;
  transform: scale(1);
}

.panelLeave {
  transition-property: opacity, transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.panelLeaveFrom {
  opacity: 100;
  transform: scale(1);
}

.panelLeaveTo {
  opacity: 0;
  transform: scale(0.95);
}

.closeButton {
  appearance: none;
  background: transparent;
  border: none;
  text-align: center;
  color: #ccc;
  padding: 9px 10px;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.closeButton:hover {
  color: #888;
}

@media only screen and (max-width: 800px) {
  .dialogPanel {
    width: 100vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding: 25px;
  }
}
