import { DateTime } from 'luxon'
import * as styles from './taskboard.module.css'
import { useState } from 'preact/hooks'
import { TaskboardItemRoute } from './TaskboardItem'

/** @param {{ transactions: import('../types').Transaction[]; children: any }} props */
export function TaskboardGroup ({ transactions, children }) {
  const comments = Array.from(
    new Set(transactions.filter((tx) => tx.comment).map((tx) => tx.comment))
  )
  const { source, target, dispatchedAt, user } = transactions[0]
  const dispatchedDateTime = DateTime.fromISO(dispatchedAt)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <li class={styles.taskboardGroup}>
      <div class={styles.taskboardGroupHeader}>
        <div>
          <TaskboardItemRoute source={source} target={target} />
          {/* <div class={styles.taskboardGroupRoute}>
            <span>{source.name}</span>
            <i class='icon ion-arrow-right-c' />
            <span>{target.name}</span>
          </div> */}
          <div>
            {transactions.length === 1 && '1 Produkt'}
            {transactions.length > 1 && `${transactions.length} Produkte`}
          </div>
          {comments.length > 0 && (
            <div class={styles.taskboardGroupComment}>
              <i class='ion-ios-chatbubble' />
              <div>{comments.join(' ')}</div>
            </div>
          )}
          <div class={styles.taskboardMetadata}>
            <time
              title={dispatchedDateTime.toLocaleString(
                DateTime.DATETIME_FULL_WITH_SECONDS
              )}
              dateTime={dispatchedAt}
            >
              {dispatchedDateTime.toRelative()}
            </time>{' '}
            <span>von {user.name}</span>
          </div>
        </div>

        <button
          type='button'
          class={styles.taskboardGroupToggleButton}
          title='Gruppe öffnen'
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isOpen
            ? (
              <i class='ion-chevron-down' />
              )
            : (
              <i class='ion-chevron-right' />
              )}
        </button>
      </div>

      {isOpen && children}
    </li>
  )
}
