.pure-g.dispatch-item
  .pure-u-1
    .pure-g.dispatch-item-row
      .pure-u-1-2
        if model.source.type === 'delivery'
          select(name="productId").pure-input-1
            each product in model.products.sortBy('name')
              option(value=product.id, selected=product.id === model.productId) #{product.name}

        else
          select(name="productId").pure-input-1
            each item in model.source.stock.sortBy(function(item) { return item.product.name })
              option(value=item.product.id, selected=item.product.id === model.productId) #{item.product.name}

      .pure-u-5-24
        input(name="inputPackages", type="number", min=0, placeholder=0).pure-input-1
        span(data-hook="package-name").append #{model.product.package}

      .pure-u-5-24
        if model.product.packageId !== 'keg'
          input(name="inputUnits", type="number", min=0, step="any", placeholder=0).pure-input-1
          span(data-hook="package-unit").append #{model.product.unit}

      .pure-u-2-24
        a(href="#", class="dispatch-item-remove", title="Entfernen...", data-hook="remove", role="button", tabindex="-1")
          i.ion-ios-minus-outline

    .pure-g(data-hook="error-item").hidden
      .pure-u-1-2
      .pure-u-1-2
        .error(data-hook="error-text")
