import tippy from 'tippy.js'
import View from 'ampersand-view'
import { DateTime } from 'luxon'
import Chart from 'chartist'
import State from 'ampersand-state'
import Collection from 'ampersand-rest-collection'
import app from 'ampersand-app'
import template from './dashboard-transactions-chart.pug'

const Transactions = Collection.extend({
  url () {
    return `/api/events/${app.me.currentEvent.slug}/dashboard/transactions`
  },
  model: State.extend({ props: { count: 'number', ivl: 'date' } })
})

export default View.extend({
  template,
  events: {
    'mouseover .ct-point': 'showTooltip',
    'mouseout .ct-point': 'hideTooltip'
  },

  initialize () {
    this.transactions = new Transactions()

    // delay re-rendering, becaus it takes a lot of compute time and
    // moving in the transit-bar takes precendence
    this.listenTo(app.view, 'change:showTaskboard', () => {
      setTimeout(() => this.render(), 2000)
    })
    this.listenTo(this.transactions, 'sync', this.render)

    this.transactions.fetch()
  },

  showTooltip (evt) {
    if (this.tooltip) return
    const point = evt.target
    this.tooltip = tippy(evt.target, {
      trigger: 'manual',
      content: point.getAttribute('ct:value') + ' Transaktion(en)'
    })
    this.tooltip.show()
  },

  hideTooltip () {
    if (this.tooltip) {
      this.tooltip.destroy()
      this.tooltip = null
    }
  },

  remove () {
    if (this.chart) this.chart.detach()
    return View.prototype.remove.apply(this)
  },

  render () {
    this.renderWithTemplate()

    const chartEl = this.queryByHook('chart')

    chartEl.classList.toggle('chart-empty', !this.transactions.length)

    let labels = []
    let values = [[]]

    if (this.transactions.length) {
      labels = this.transactions.map((t) => {
        const ivl = DateTime.fromJSDate(t.ivl)
        const end = DateTime.fromJSDate(t.ivl).plus({ hours: 1 }).toFormat('H')
        return `${ivl.toLocaleString({
          day: 'numeric',
          month: 'numeric'
        })}<br>${ivl.toFormat('H')} - ${end}h`
      })
      if (labels.length > 20) {
        labels = labels.map((label, i) => (i % 4 === 0 ? label : ''))
      }
      values = [this.transactions.map((t) => t.count)]
    } else {
      for (let i = 0; i <= 10; i++) {
        labels[i] = i
        values[0][i] = Math.round(Math.random() * 100)
      }
    }

    this.chart = new Chart.Line(
      chartEl,
      {
        labels,
        series: values
      },
      {
        height: 200,
        axisY: { low: 0 },
        axisX: {
          showLabel: !!this.transactions.length
        },
        fullWidth:
          this.transactions.length > 1 || this.transactions.length === 0,
        chartPadding: {
          top: 15,
          right: 30,
          bottom: !this.transactions.length ? 0 : 20,
          left: 0
        }
      }
    )

    return this
  }
})
