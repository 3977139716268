.pure-g.search-state
  .pure-u-1
    if model.filtered
      p
        | Zeige Transaktionen
        if model.product
          |  für <span class="badge">#{model.activeProduct}<i data-hook="remove-filter" data-prop="product" class="ion-close"></i></span>
        if model.source
          |  von <span class="badge">#{model.activeSource}<i data-hook="remove-filter" data-prop="source" class="ion-close"></i></span>
        if model.target
          |  nach <span class="badge">#{model.activeTarget}<i data-hook="remove-filter" data-prop="target" class="ion-close"></i></span>
        if model.start
          |  ab <span class="badge">#{model.startLocalized}<i data-hook="remove-filter" data-prop="start" class="ion-close"></i></span>
        if model.end
          |  bis <span class="badge">#{model.endLocalized}<i data-hook="remove-filter" data-prop="end" class="ion-close"></i></span>
        | .<br>
        a(href="#", data-hook="reset-filters").search-state__reset
          i.ion-close
          | Alle Filter zurücksetzen
