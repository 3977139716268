import View from 'ampersand-view'
import { render } from 'preact'
import { SWRConfig } from 'swr'
import { api } from './hooks'

const fetcher = (...args) => api(...args)

export const PreactView = View.extend({
  initialize (opts = {}) {
    if (!this.component) {
      throw new TypeError('no preact component specified')
    }
    this._props = opts.props || {}

    // views can be initialized with an existing element or must create their own
    if (!this.el) {
      this.el = document.createElement('div')
    }
  },

  remove () {
    render(null, this.el)
    View.prototype.remove.call(this)
    return this
  },

  render () {
    const Component = this.component

    render(
      <SWRConfig value={{ fetcher }}>
        <Component {...this._props} />
      </SWRConfig>,
      this.el
    )

    if (this.el.childNodes.length > 1) {
      console.warn(
        `PreactView should only render a single node into its element. Got ${this.el.childNodes.length}.`
      )
    }

    return this
  }
})
