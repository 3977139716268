.route {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
  justify-content: stretch;
}

.dispatchItemRow {
  display: grid;
  grid-template-columns: 50fr 20fr 20fr min-content;
  align-items: flex-start;
  column-gap: 2em;
  margin-bottom: 0.5em;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
