'use strict'

// Must be the first import
if (process.env.NODE_ENV === 'development') {
  require('preact/debug')
}

const app = require('ampersand-app')
const { default: isMobile } = require('is-mobile')
const { default: tippy } = require('tippy.js')
const Router = require('./router')
const Me = require('./models/me')
const Changes = require('./models/changes')
const LocationCollection = require('./models/location-collection')
const ProductCollection = require('./models/product-collection')
const AppView = require('./components/app/app-view.js')
const AuthPage = require('./components/auth-page')
const { logger } = require('./utils')

// Set default tippy props for convenience. We take the tooltip content from the `data-title` attribute
tippy.setDefaultProps({
  delay: [500, null],
  placement: 'bottom',
  content: (el) => el.dataset.title
})

const log = logger.extend('init')

app.extend({
  isMobile: isMobile({ tablet: true, featureDetect: true }),

  init () {
    this._start = Date.now()
    this.router = new Router()
    log(`detected mobile device: ${app.isMobile}`)

    this.me = new Me()
    this.me.once('sync', this.bootstrap, this)
    this.me.once('error', this.onError, this)
    this.me.fetch()
  },

  onError () {
    if (this.view) this.view.remove()
    this.clear()
    this.authPage = new AuthPage({ model: this.me })
    document.body.appendChild(this.authPage.render().el)
  },

  bootstrap () {
    log('fetched user')
    this.changes = new Changes({ eventId: app.me.currentEventId })
    this.products = new ProductCollection()
    this.locations = new LocationCollection()

    // proxy event switch to changes-feed
    this.me.on(
      'change:currentEventId',
      function (me, currentEventId) {
        this.changes.eventId = currentEventId
        this.fetch()
      },
      this
    )

    // clear localstorage if we don't have a current event assigned
    // because maybe it was revoked by the server
    if (!this.me.currentEventId) {
      this.clear()
    }

    // ensure we have everything up front
    if (
      this.me.currentEventId &&
      (!this.products.length || !this.locations.length)
    ) {
      this.fetch(() => this.render())
    } else {
      log('had cached locations')
      this.render()
    }
  },

  // fetch products first, then locations, because location's stock depends on products
  fetch (done = () => {}) {
    this.products.once('sync', () => this.locations.fetch(), this)
    this.locations.once(
      'sync',
      () => {
        // emit app-wide "sync" event, so we know when it's save to re-render etc
        this.trigger('sync')
        done()
      },
      this
    )
    this.products.fetch()
    log('fetching products and locations')
  },

  render () {
    // render AppView
    this.view = new AppView()
    document.body.appendChild(this.view.render().el)

    log('rendered app view')
    log('start up time ' + (Date.now() - this._start) + 'ms')

    // start router
    this.router.history.start()

    // init and start changes feed listener
    this.changes.start()
  },

  navigate (page) {
    const url = page.charAt(0) === '/' ? page.slice(1) : page
    this.router.navigate(url, { trigger: true })
  },

  clear () {
    for (const key of Object.keys(window.localStorage)) {
      if (key !== 'debug') {
        window.localStorage.removeItem(key)
      }
    }
  }
})

app.init()

// const ENABLE_SERVICE_WORKER = process.env.NODE_ENV === 'production'
const ENABLE_SERVICE_WORKER = false

if (ENABLE_SERVICE_WORKER) {
  if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(new URL('service-worker.js', import.meta.url), {
          type: 'module'
        })
        .then(() => log('registered service worker'))
    })
  }
}

/**
 * Clean up service workers if ever needed
 * Also: https://developer.chrome.com/docs/workbox/remove-buggy-service-workers/
 */
if (!ENABLE_SERVICE_WORKER) {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister().then(() => log('unregistered service worker'))
      }
    })
  }
}
