'use strict'

const View = require('ampersand-view')
const app = require('ampersand-app')

module.exports = View.extend({
  template: require('./best-bars-card.pug').default,
  initialize () {
    this.bars = app.locations
      .filter((location) => location.type === 'bar')
      .sort((a, b) => b.sumSalesGrossValue - a.sumSalesGrossValue)
      .slice(0, 10)
  }
})
