import app from 'ampersand-app'
import { lazy, Suspense } from 'preact/compat'
import { useRef, useState } from 'preact/hooks'
import { Modal } from '../modal/Modal'
import { PreactView } from '../preact-view'

const ProductImport = lazy(() => import('./ProductImport'))

function ProductImportButton () {
  const [isOpen, setIsOpen] = useState(false)
  const initialFocus = useRef()

  function handleClose () {
    app.products.fetch()
    setIsOpen(false)
  }

  return (
    <>
      <button
        className='pure-button btn-round dispatch'
        data-title='Produkte importieren'
        onClick={() => setIsOpen(true)}
      >
        <i className='ion-ios-cloud-upload' />
      </button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <Suspense fallback={null}>
          <ProductImport onClose={handleClose} initialFocus={initialFocus} />
        </Suspense>
      </Modal>
    </>
  )
}

export const ProductImportButtonView = PreactView.extend({
  component: ProductImportButton
})
