const View = require('ampersand-view')
const mixin = require('../popover-mixin')

/**
 * Inventory Action Popover
 */
module.exports = View.extend(mixin, {
  template: require('./inventory-popover.pug').default,

  initialize (opts) {
    this.setButton(opts.button)
    this.opts = opts
  },

  render () {
    mixin.render.apply(this, arguments)
    return this
  }
})
