tr.transaction-list-item--edit
  td
    div(data-hook="date-picker")

  td
    if model.source.type === 'delivery'
      select(name="productId").select-plain
        each product in model.products.models
          option(value=product.id, selected=product.id === model.productId) #{product.name}

    else
      select(name="productId").select-plain
        each product in model.products.models
          option(value=product.id, selected=product.id === model.productId) #{product.name}

  td
    .input-units
      input(name="inputPackages", value=model._inputPackages || null, type="number", min=0, placeholder=0).pure-u-1
      span(data-hook="package-name").append #{model.product.package}

      input(name="inputUnits", value=model._inputUnits || null, type="number", min=0, step="any", placeholder=0).pure-u-1
      span(data-hook="package-unit").append #{model.product.unit}

  td(colspan="2")
    div(data-hook="select-source")
    div(data-hook="select-target")

  td.action
    button(data-hook="cancel").btn-round.pure-button: i.ion-close
    button(type="submit", data-hook="save").btn-round.pure-button.button-success: i.ion-checkmark
