mixin sortableColumn (prop, descr)
  th(data-hook="sort-" + prop, class=[model.stock.state.comparator === prop ? 'sorted' : null, model.stock.state.order].join(' ')).sortable-column
    span #{descr}
    span: i.ion-ios-arrow-down

section.pure-u-1.pure-u-xl-1-2.location.storage(data-id=model.id, id=model.slug)
  div(data-hook="location-header")

  table.pure-table.item-list.table-striped
    thead
      tr
        if type === 'crash'
          //- "Bruchlager"
          +sortableColumn('sortPosition', '#')
          +sortableColumn('name', 'Bezeichnung')
          +sortableColumn('stock', 'Bestand')
          th Schaden
            .span.append netto
        else
          //- normal storage spaces
          +sortableColumn('sortPosition', '#')
          +sortableColumn('name', 'Bezeichnung')
          +sortableColumn('units', 'Bestand')
          th

    tbody(data-hook="item-list")
