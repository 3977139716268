import Model from 'ampersand-model'
import app from 'ampersand-app'
import { DateTime } from 'luxon'

import { unitsLocalized, packagesLocalized } from '../utils'

export default Model.extend({
  urlRoot () {
    return `/api/events/${app.me.currentEvent.slug}/transactions`
  },

  initialize () {
    this.on('change:inputPackages change:inputUnits', this.onAmountChange, this)
  },

  props: {
    id: ['string'],
    createdAt: {
      type: 'date',
      required: true,
      default () {
        return new Date().toISOString()
      }
    },
    updatedAt: {
      type: 'date',
      required: true,
      default () {
        return new Date().toISOString()
      }
    },
    dispatchedAt: {
      type: 'date',
      required: true,
      default () {
        return new Date().toISOString()
      }
    },
    state: {
      type: 'string',
      values: ['transit', 'complete', 'cancelled', 'accepted'],
      default: 'transit'
    },
    sourceId: ['string', true],
    targetId: ['string', true],
    productId: ['string', true],
    units: ['number', true, 0],
    isFree: ['boolean', true, false],
    comment: ['string'],
    userId: {
      type: 'string',
      required: true,
      setOnce: true,
      default () {
        return app.me.id
      }
    },
    editedById: 'string',
    editedBy: 'object',
    user: 'object'
  },

  session: {
    inputPackages: ['number', false, 0],
    inputUnits: ['number', false, 0],
    // this is the "stock" for delivery transactions
    products: {
      type: 'object',
      default () {
        return app.products
      }
    }
  },

  derived: {
    product: {
      deps: ['productId'],
      fn () {
        return app.products.get(this.productId) || {}
      }
    },

    target: {
      deps: ['targetId'],
      fn () {
        return app.locations.get(this.targetId) || {}
      }
    },

    source: {
      deps: ['sourceId'],
      fn () {
        return app.locations.get(this.sourceId) || {}
      }
    },

    isEditableByMe: {
      deps: ['userId'],
      fn () {
        // only the initiator and admins can change this transaction
        return this.userId === app.me.id || app.me.role === 'admin'
      }
    },

    unitsLocalized: unitsLocalized('units'),
    packagesLocalized: packagesLocalized('units'),

    // for backwards calculation from units -> inputUnits/inputPackges
    _inputUnits: {
      fn () {
        return this.units % this.product.packageSize
      }
    },

    _inputPackages: {
      fn () {
        return Math.floor(this.units / this.product.packageSize)
      }
    },

    dateLocalized: {
      deps: ['createdAt'],
      fn () {
        return DateTime.fromJSDate(this.createdAt).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    },

    updatedAtLocalized: {
      deps: ['updatedAt'],
      fn () {
        return DateTime.fromJSDate(this.updatedAt).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    },

    dispatchedAtLocalized: {
      deps: ['dispatchedAt'],
      fn () {
        return DateTime.fromJSDate(this.dispatchedAt).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    },

    dateFromNow: {
      deps: ['createdAt'],
      cache: false,
      fn () {
        return DateTime.fromJSDate(this.createdAt).toRelative()
      }
    }
  },

  validate () {
    if (!this.sourceId) return 'Bitte Quelle angeben.'
    if (!this.targetId) return 'Bitte Ziel angeben.'
    if (!this.productId) return 'Bitte Produkt angeben.'
    if (!this.units) return 'Bitte Menge angeben.'
  },

  onAmountChange () {
    this.units = this.inputPackages * this.product.packageSize + this.inputUnits
  }
})
