
.pure-g.product-row(data-id=model.id)
  .pure-u-1-5.item-name
    .cell #{model.name}

      if model.canSpill
        i(title="Schankverlust möglich").icon.can-spill.ion-ios-wineglass

  .pure-u-1-5
    .cell #{model.packageSize} x #{model.volumePerUnit}l
      span.append
        if model.weightFull
          span voll #{model.weightFull}g
        if model.weightFull && model.weightEmpty
          span #{', '}
        if model.weightEmpty
          span leer #{model.weightEmpty}g

  .pure-u-1-5
    .cell(title=`${model.costPerLiterLocalized}/Liter`) #{model.costLocalized}
      span.append pro #{model.costUnitLocalized}

  .pure-u-1-5
    .cell(title=`${model.pricePerLiterLocalized}/Liter`) #{model.priceLocalized}
      span.append pro #{model.servingSizeLocalized}

  .pure-u-1-5.action
    .cell
      .btn-group
        button(title=`${model.name} wiederherstellen...`, data-hook="restore").pure-button.btn-round
          i.icon.ion-ios-undo
