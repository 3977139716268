mixin sortableColumn (prop, descr)
  th(data-hook="sort-" + prop, class=[model.stock.state.comparator === prop ? 'sorted' : null, model.stock.state.order].join(' ')).sortable-column
    span #{descr}
    span: i.ion-ios-arrow-down

section.pure-u-1.location.bar(data-id=model.id, id=model.slug, class=`${!isBarchef ? 'pure-u-xl-1-2' : ''}`)
  div(data-hook="location-header")

  table.pure-table.item-list.table-striped
    thead
      tr
        +sortableColumn('sortPosition', '#')
        +sortableColumn('name', 'Bezeichnung')
        +sortableColumn('units', 'Bestand')
        if isAdmin
          th.row-sales Umsatz
            span.append brutto
          th.row-sales Umsatz
            span.append netto
        th.action
          //- if isAdmin
            button.pure-button.btn-round.pure-button-primary(title="Alle dispatchen...", disabled)
              i.icon.ion-paper-airplane

    tbody(data-hook="item-list")

    if isAdmin && model.stock.length
      tfoot(data-hook="bar-footer")
