'use strict'

const Model = require('ampersand-model')
const Collection = require('ampersand-collection')
const app = require('ampersand-app')

const Weight = Model.extend({
  props: {
    value: ['number', false, 0]
  },

  session: {
    productId: 'string'
  },

  derived: {
    product: {
      deps: ['productId'],
      fn () {
        return app.products.get(this.productId)
      }
    }
  },

  serialize () {
    return this.value
  }
})

module.exports = Collection.extend({
  model: Weight
})
