'use strict'

const app = require('ampersand-app')

const Product = require('./product')
const CachedCollection = require('./cached-collection')

const { indexify } = require('../utils')

module.exports = CachedCollection.extend({
  url () {
    return `/api/events/${app.me.currentEvent.slug}/products`
  },

  model: Product,

  // cache options
  storageKey () {
    return app.me.currentEvent.slug + '-products'
  },

  props: {
    filtered: ['boolean', true, false]
  },

  initialize (data, opts = { cache: true }) {
    if (opts.cache) {
      CachedCollection.prototype.initialize.apply(this)
    }

    // listen to remote changes
    this.listenTo(app.changes, 'products', this.onChange)
  },

  onChange (change) {
    if (change.type === 'insert' || change.type === 'update') {
      this.set(change.row, { remove: false })
    }

    if (change.type === 'delete') {
      this.remove(change.id)
    }
  },

  comparator (a, b) {
    if (Number.isFinite(a.sortPosition) && Number.isFinite(b.sortPosition)) {
      const diff = a.sortPosition - b.sortPosition
      if (diff) return diff
    }
    if (a.name !== b.name) return a.name < b.name ? -1 : 1
    return 0
  },

  byName (term) {
    if (term.toLowerCase().trim() === 'schankverlust') {
      term = term.toLowerCase().trim()
    } else {
      term = indexify(term)
    }

    // save unfiltered models for later
    if (!this.filtered) this._originalModels = this.models

    // reset search
    if (!term) {
      this.reset(this._originalModels)
      this.filtered = false
      return
    }
    if (term.length < 2) return

    // filter original models
    let filtered
    if (term === 'schankverlust') {
      filtered = this._originalModels.filter((product) => product.canSpill)
    } else {
      filtered = this._originalModels.filter(
        (product) => product._nameIndex.indexOf(term) > -1
      )
    }
    this.reset(filtered)
    this.filtered = true
  }
})
