'use strict'

const assign = require('lodash/assign')
const LocationBase = require('../location-base/location-base-view')
const StorageItemView = require('./storage-item-view')

/*
  View für ein einzelnes Lager
  Model ist Storage, mit Inventar als model.stock
 */
module.exports = LocationBase.extend({
  template: require('./storage.pug').default,

  ItemView: StorageItemView,

  events: assign(
    {
      'change [data-hook=is-crash]': 'isCrash'
    },
    LocationBase.prototype.events
  ),

  initialize () {
    LocationBase.prototype.initialize.apply(this)
    this.listenTo(this.model, 'sort', this.onSort)
    this.listenTo(this.model, 'change:editable', this.onEditable)
  },

  isCrash (event) {
    event.stopPropagation()
    const isCrash = event.target.checked
    this.model.type = isCrash ? 'crash' : 'storage'
  }
})
