'use strict'

const View = require('ampersand-view')
const { default: tippy } = require('tippy.js')
const app = require('ampersand-app')

const SourceView = require('../dispatch/source-view')
const TargetView = require('../dispatch/target-view')
const DatePicker = require('../date-picker/date-picker')
const { isAdminProp } = require('../../utils')

const EditView = View.extend({
  template: require('./transactions-list-item-edit.pug').default,

  events: {
    'click [data-hook=save]': 'onSave',
    'click [data-hook=cancel]': 'onCancel',
    'change [name=inputUnits]': 'onChange',
    'change [name=inputPackages]': 'onChange',
    'change [name=productId]': 'onChange'
  },

  subviews: {
    source: {
      hook: 'select-source',
      prepareView (el) {
        return new SourceView({
          template: require('./source.pug').default,
          el,
          model: this.model
        })
      }
    },
    target: {
      hook: 'select-target',
      prepareView (el) {
        return new TargetView({
          template: require('./target.pug').default,
          el,
          model: this.model
        })
      }
    },
    datePicker: {
      hook: 'date-picker',
      prepareView (el) {
        const view = new DatePicker({
          el,
          initialValue: this.model.dispatchedAt
        })
        this.listenTo(view, 'change:inputValue', this.onDateChange)
        return view
      }
    }
  },

  onChange (evt) {
    const val =
      evt.target.type === 'number' ? +evt.target.value : evt.target.value
    this.model.set(evt.target.name, val)
  },

  onSave (evt) {
    evt.preventDefault()
    this.model.save()
    this.parent.toggle('editable')
  },

  onCancel (evt) {
    evt.preventDefault()
    this.parent.editable = false
  },

  onDateChange (view, date) {
    this.model.dispatchedAt = date
  }
})

module.exports = View.extend({
  template: require('./transactions-list-item.pug').default,

  events: {
    'click [data-hook=edit]': 'onEdit'
  },

  props: {
    editable: ['boolean', true, false],
    isAdmin: isAdminProp
  },

  initialize () {
    this.me = app.me
    this.listenTo(this.model, 'change', this.render)
    this.on('change:editable', this.showEditView, this)
  },

  onEdit (evt) {
    evt.preventDefault()
    this.toggle('editable')
  },

  showEditView () {
    if (this.editable) {
      this.editView = this.registerSubview(new EditView({ model: this.model }))
      this.el.parentNode.insertBefore(this.editView.render().el, this.el)
    } else {
      this.editView.remove()
    }
    this.el.classList.toggle('hidden', this.editable)
  },

  remove () {
    if (this.commentTooltip) {
      this.commentTooltip.destroy()
    }
    if (this.editedByTooltip) {
      this.editedByTooltip.destroy()
    }
    return View.prototype.remove.call(this)
  },

  render () {
    // don't render when in edit mode
    if (this.editable) return this

    this.renderWithTemplate(this)

    if (this.model.comment) {
      this.commentTooltip = tippy(this.queryByHook('show-comment'), {
        content: this.model.comment,
        delay: 100
      })
    }

    if (this.model.editedById) {
      const i = this.queryByHook('edited')
      if (!i) return this
      this.editedByTooltip = tippy(i, { content: i.dataset.title })
    }

    return this
  }
})
