'use strict'

const LocationBase = require('./location-base-model')

/*
  Model representing a storage space
 */

module.exports = LocationBase.extend({
  props: {
    type: ['string', true, 'storage']
  }
})
