if collection.size()
  div.event-selector
    h3 Event auswählen
    select(name="event-selector", data-hook="event-selector")
      option(disabled, selected) Event auswählen
      each event in collection.models
        option(value=event.id) #{event.name}

else
  div
