'use strict'

const View = require('ampersand-view')

module.exports = View.extend({
  template: require('./dispatch-item.pug').default,

  events: {
    'change [name=productId]': 'onProductChange',
    'change [name=inputPackages]': 'onKeyup',
    'change [name=inputUnits]': 'onKeyup',
    'change [name=isFree]': 'onChangeIsFree',
    'keyup input': 'onKeyup',
    'click [data-hook=remove]': 'onRemove'
  },

  initialize () {
    this.listenTo(this.model, 'invalid error', this.handleError)
    this.listenTo(this.model, 'sync', this.remove)
    this.listenTo(this.model, 'change:product', this.render)
    this.listenTo(this.model, 'change:units change:targetId', this.clearError)
  },

  onKeyup (event) {
    event.stopPropagation()
    this.model.set(event.target.name, +event.target.value || 0)
  },

  onRemove (event) {
    event.preventDefault()
    this.model.collection.remove(this.model)
  },

  onProductChange (event) {
    event.stopPropagation()
    this.model.set('productId', event.target.value)
  },

  onChangeIsFree (event) {
    this.model.set('isFree', event.target.checked)
  },

  clearError () {
    this.errorItem.classList.toggle('hidden', this.model.isValid())
  },

  handleError (model, error) {
    this.errorText.textContent = error
    this.errorItem.classList.remove('hidden')
  },

  render () {
    this.renderWithTemplate(this)

    this.cacheElements({
      errorItem: '[data-hook="error-item"]',
      errorText: '[data-hook="error-text"]'
    })
    return this
  }
})
