'use strict'

const Router = require('ampersand-router')
const app = require('ampersand-app')

const ProductPage = require('./components/product-page')
const TransactionPage = require('./components/transaction-page')
const WelcomePage = require('./components/welcome-page/welcome-page')
const StoragePage = require('./components/storage-page/storage-page')
const BarPage = require('./components/bar-page/bar-page')
const SettingsPage = require('./components/settings-page/settings-page')
const DashboardPage = require('./components/dashboard-page/dashboard-page')
const StockTakingPage = require('./components/stock-taking/stock-taking-page')
const StockTakingView = require('./components/stock-taking/stock-taking-view')
const StockTakingDetails = require('./components/stock-taking-details/stock-taking-details')
const NotFoundPage = require('./components/not-found-page')
const {
  default: TaskboardPage
} = require('./components/taskboard-page/TaskboardPage.js')

function isAdmin (name) {
  return function () {
    if (app.me.role === 'admin') {
      this[name].apply(this, arguments)
    } else {
      this.redirectTo('/404')
    }
  }
}

function redirectTo (routeName) {
  return function () {
    if (app.me.role === 'bar') {
      this.redirectTo('/bars')
    } else {
      this[routeName].apply(this, arguments)
    }
  }
}

module.exports = Router.extend({
  routes: {
    '': redirectTo('uebersicht'),
    bars: 'bars',
    lager: 'lager',

    'bars/:slug/inventuren': 'inventurListe',
    'lager/:slug/inventuren': isAdmin('inventurListe'),

    'bars/:slug/inventuren/neu': 'inventurNeu',
    'lager/:slug/inventuren/neu': isAdmin('inventurNeu'),
    'bars/:slug/inventuren/:id': 'inventurDetails',
    'lager/:slug/inventuren/:id': isAdmin('inventurDetails'),
    'bars/:slug/inventuren/:id/edit': 'inventur',
    'lager/:slug/inventuren/:id/edit': isAdmin('inventur'),

    transaktionen: 'transaktionen',
    taskboard: 'taskboard',
    uebersicht: 'uebersicht',
    produkte: 'produkte',
    einstellungen: 'einstellungen',
    'einstellungen/:id': isAdmin('eventEinstellungen'),
    welcome: 'welcome',
    logout: 'logout',
    '*path': 'notFound'
  },

  adminOnly () {
    if (app.me.role !== 'admin') return this.notFound()
  },

  welcome () {
    this.trigger('page', new WelcomePage())
  },

  bars () {
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    this.trigger('page', new BarPage())
  },

  lager () {
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    if (app.me.role === 'bar') return this.notFound()
    this.trigger('page', new StoragePage())
  },

  transaktionen () {
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    if (app.me.role === 'bar') return this.notFound()

    this.trigger(
      'page',
      new TransactionPage({
        query: Object.fromEntries(new URLSearchParams(window.location.search))
      })
    )
  },

  taskboard () {
    if (!app.me.currentEventId) return this.redirectTo('welcome')

    this.trigger('page', new TaskboardPage())
  },

  uebersicht () {
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    this.trigger('page', new DashboardPage())
  },

  produkte () {
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    if (app.me.role === 'bar') return this.notFound()
    this.trigger('page', new ProductPage())
  },

  inventurListe (slug) {
    const location = app.locations.find({ slug })
    if (!location) return this.redirectTo('/404')
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    this.trigger(
      'page',
      new StockTakingPage({ model: app.locations.find({ slug }) })
    )
  },

  inventurNeu (slug) {
    const location = app.locations.find({ slug })
    if (!location) return this.redirectTo('/404')
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    this.trigger(
      'page',
      new StockTakingView({
        location: app.locations.find({ slug })
      })
    )
  },

  inventur (slug, id) {
    const location = app.locations.find({ slug })
    if (!location) return this.redirectTo('/404')
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    this.trigger(
      'page',
      new StockTakingView({
        location: app.locations.find({ slug }),
        id
      })
    )
  },

  inventurDetails (slug, id) {
    const location = app.locations.find({ slug })
    if (!location) return this.redirectTo('/404')
    if (!app.me.currentEventId) return this.redirectTo('welcome')
    this.trigger(
      'page',
      new StockTakingDetails({
        location: app.locations.find({ slug }),
        id
      })
    )
  },

  einstellungen () {
    this.trigger('page', new SettingsPage())
  },

  eventEinstellungen (eventId) {
    import('./components/settings-page/EventSettings').then((module) => {
      const EventSettingsPage = module.default
      this.trigger('page', new EventSettingsPage({ props: { eventId } }))
    })
  },

  logout () {
    app.me.logout()
  },

  notFound (path) {
    this.trigger('page', new NotFoundPage({ path }))
  }
})
