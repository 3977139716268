'use strict'

const app = require('ampersand-app')
const CachedCollection = require('./cached-collection')
const Event = require('./event-simple')

module.exports = CachedCollection.extend({
  url: '/api/events',
  model: Event,

  // cache options
  storageKey () {
    return app.me.currentEvent.slug + '-events'
  },

  comparator (event) {
    // sort current event first
    if (event.isCurrentEvent) return '0'
    return event.name
  }
})
