'use strict'

module.exports = {
  events: {
    'click [data-hook="sort-name"]': 'sortByName',
    'click [data-hook="sort-units"]': 'sortByStock',
    'click [data-hook="sort-sortPosition"]': 'sortByPosition'
  },

  initSortableTable (state) {
    this.sortState = state
    this.listenTo(this.sortState, 'change:comparator change:order', this.toggleIcon)
  },

  setComparator (comparator) {
    this.model.stock.setComparator(comparator)
  },

  toggleIcon () {
    this.queryAll('.sortable-column').forEach((el) => el.classList.remove('sorted'))
    const column = this.queryByHook('sort-' + this.sortState.comparator)

    column.classList.remove('desc', 'asc')
    column.classList.add('sorted', this.sortState.order)
  },

  sortByName (evt) {
    evt.preventDefault()
    this.setComparator('name')
  },

  sortByStock (evt) {
    evt.preventDefault()
    this.setComparator('units')
  },

  sortByPosition (evt) {
    evt.preventDefault()
    this.setComparator('sortPosition')
  },

  sortByRatio (evt) {
    evt.preventDefault()
    this.setComparator('ratio')
  },

  sortByDelivery (evt) {
    evt.preventDefault()
    this.setComparator('delivery')
  }
}
