mixin location-link(location)
  a(href=location.link) #{location.name}

tr
  td
    time(datetime=model.dispatchedAt.toISOString()) #{model.dispatchedAtLocalized}
    if model.comment
      span(data-hook="show-comment").comment-bubble: i.ion-ios-chatbubble-outline
    .append
      span von #{model.user.name}
      if model.editedBy
        i(data-hook="edited", data-title=`Bearbeitet am ${model.updatedAtLocalized} von ${model.editedBy.name}`).ion-edit

  td
    if model.product.deletedAt
      i(title=`${model.product.name} wurde gelöscht.`).is-deleted.ion-trash-a
    span.item-name #{model.product.name}
    if model.isFree
      .append Freiware

  td
    if model.packagesLocalized
      span.packages #{model.packagesLocalized}
    if model.unitsLocalized
      span.units #{model.unitsLocalized}

  td.transaction-list-location: +location-link(model.source)
  td.transaction-list-location: +location-link(model.target)

  if isAdmin
    td.action
      button(title="Diese Transaktion bearbeiten...", data-hook="edit").btn-round.pure-button: i.ion-edit
