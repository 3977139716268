.btn-group.buttons
  div(data-hook="delete-button")

  if canStartDelivery && !model.isNew() && model.type !== 'crash' && !model.editable
    button(title=`${model.name} bestücken...`, data-hook="delivery-button").btn-round.pure-button
      i.ion-ios-cart

  if isAdmin && model.type !== 'crash' && !model.isNew() && model.stock.length && !model.editable
    div(data-hook="inventory-button")

  if model.isNew()
    button(title="Abbrechen (Esc)", data-hook="cancel").btn-round.pure-button
      i.ion-close-round
    button(type="submit", title="Hinzufügen (Enter)", data-hook="save", disabled).btn-round.button-success.pure-button
      i.ion-checkmark
