'use strict'

const { default: tippy } = require('tippy.js')
const View = require('ampersand-view')
const Chart = require('chartist')

module.exports = View.extend({
  template: require('./dashboard-product-chart.pug').default,

  events: {
    'mouseover .ct-bar': 'showTooltip',
    'mouseout .ct-bar': 'hideTooltip'
  },

  initialize () {
    this.listenTo(this.collection, 'sync', this.render)
  },

  showTooltip (evt) {
    if (this.tooltip) return
    const label = evt.target.getAttribute('label')
    const value = this.getValueById(evt.target.getAttribute('id'))
    this.tooltip = tippy(evt.target, {
      trigger: 'manual',
      content: `${label} ${value}%`,
      delay: 10
    })
    this.tooltip.show()
  },

  hideTooltip () {
    if (this.tooltip) {
      this.tooltip.destroy()
      this.tooltip = null
    }
  },

  remove () {
    if (this.chart) this.chart.detach()
    return View.prototype.remove.apply(this)
  },

  getLabels () {
    return this.collection.map((item) => item.product.name)
  },

  getValues () {
    return this.collection.map((item) => {
      const value = Math.round(item.ratio * 100)
      // clip values
      if (value > 120) return 120
      if (value < -20) return -20
      return value
    })
  },

  getValueById (id) {
    return Math.round(this.collection.get(id).ratio * 100)
  },

  renderChart () {
    const chartEl = this.queryByHook('chart')
    let labels = []
    let values = []

    chartEl.classList.toggle('chart-empty', !this.collection.length)

    if (this.collection.length) {
      labels = this.getLabels()
      values = this.getValues()
    } else {
      for (let i = 0; i <= 20; i++) {
        labels[i] = i
        values[i] = Math.round(Math.random() * 100)
      }
    }

    let i = 0

    if (this.chart) this.chart.detach()

    // set width on element, otherwise firefox doesn't calculate the labels correctly
    const width = labels.length > 21 ? labels.length * 40 : undefined
    if (width) chartEl.style.width = width + 'px'

    this.chart = new Chart.Bar(
      chartEl,
      {
        labels,
        series: values
      },
      {
        width,
        height: 250,
        chartPadding: {
          bottom: this.collection.length ? 35 : 0,
          left: 45,
          right: 45
        },
        axisX: {
          showGrid: false,
          showLabel: !!this.collection.length
        },
        axisY: {
          offset: 0,
          showLabel: false
        },
        high: 100,
        low: -20,
        distributeSeries: true
      }
    ).on('draw', (ctx) => {
      if (ctx.type === 'label') {
        // adjust label position for rotation
        const dX = ctx.width / 2 + (100 - ctx.width)
        ctx.element.attr({ x: ctx.element.attr('x') - dX })
      }

      if (ctx.type === 'bar') {
        // add corresponding labels for tooltips
        if (this.collection.length) {
          ctx.element.attr({ id: this.collection.at(i).id, label: labels[i] })
        }

        // add warning classes
        const value = ctx.value.y
        if (value <= 10 || value > 100) ctx.element.addClass('low')
        if (value > 10 && value < 25) ctx.element.addClass('warn')

        // reset counter when we're done, so it'll work on subsequent re-draws
        if (++i === labels.length) i = 0
      }
    })
  },

  render () {
    this.renderWithTemplate()
    this.renderChart()
    return this
  }
})
