tr
  td.item-name #{model.product.name}
  td
    if model.lastInventoryId
      span.packages #{model.packagesLastInventoryLocalized}
      span.units #{model.unitsLastInventoryLocalized}
    else
      span.append Noch keine Inventur
  td
    if model.unitsSinceInventory
      a(href=transactionsLink)
        span.packages #{model.packagesSinceInventoryLocalized}
        span.units #{model.unitsSinceInventoryLocalized}
    else
      .append Keine Nachlieferung
  td
    span.packages #{model.inputPackagesLocalized}
    span.units #{model.inputUnitsLocalized}
    //- weights
    .append
      each weight in model.weight.models
        span + #{model.product.unit}: #{weight.value}g
  td(class=model.unitsSold < 0 ? 'error' : null)
    span.packages #{model.packagesSoldLocalized}
    span.units #{model.unitsSoldLocalized}
