'use strict'

const State = require('ampersand-state')
const Collection = require('ampersand-rest-collection')
const app = require('ampersand-app')
const debounce = require('lodash/debounce')

const utils = require('../utils')
const { StockItem } = require('./stock-item')

const SortState = State.extend({
  props: {
    comparator: {
      type: 'string',
      values: ['name', 'units', 'sortPosition', 'delivery', 'ratio']
    },
    order: {
      type: 'string',
      required: true,
      values: ['asc', 'desc'],
      default: 'asc'
    }
  },

  derived: {
    comparatorFn: {
      deps: ['comparator', 'order'],
      fn () {
        switch (this.comparator) {
          case 'name':
            if (this.order === 'desc') return utils.byStockNameDesc
            return utils.byStockNameAsc
          case 'units':
          case 'delivery':
          case 'ratio':
            if (this.order === 'desc') return utils.byNumberDesc(this.comparator)
            return utils.byNumberAsc(this.comparator)
          default:
            if (this.order === 'desc') return utils.byStockSortPositionDesc
            return utils.byStockSortPosition
        }
      }
    }
  }
})

module.exports = Collection.extend({
  model: StockItem,

  initialize () {
    if (app.changes) {
      this.listenTo(app.changes, 'transactions', this.onTransaction)
    }

    this.state = new SortState()
    this.listenTo(this.state, 'change:comparatorFn', this.onComparatorChange)

    // listen for proxied sortPosition change and re-sort
    this.on('change:sortPosition', debounce(() => this.sort(), 100), this)
  },

  // so we can fetch stock by productId
  mainIndex: 'productId',

  /*
    Sort by sortPosition or name by default
   */
  comparator: utils.byStockSortPosition,

  setComparator (prop) {
    // toggle ASC/DESC
    if (this.state.comparator === prop) this.state.toggle('order')
    this.state.comparator = prop
  },

  onComparatorChange (state, comparator) {
    this.comparator = comparator
    this.sort()
  },

  /*
    Filter by provided term (in product names)
   */
  byName (term) {
    term = utils.indexify(term)

    // save unfiltered models for later
    if (!this.filtered) this._originalModels = this.models

    // reset search
    if (!term) {
      this.reset(this._originalModels)
      this.filtered = false
      return
    }
    if (term.length < 2) return

    // filter original models
    const filtered = this._originalModels.filter(function (item) {
      return item.product._nameIndex.indexOf(term) > -1
    })
    this.reset(filtered)
    this.filtered = true
  },

  /**
   * React to complete transactions
   * @param  {object} change Updated transaction
   */
  onTransaction (change) {
    if (!this.shouldReact(change.row)) return
    const stockItem = this.get(change.row.productId)

    if (stockItem) {
      stockItem.update(change.row)
    } else {
      app.locations.fetch()
    }
  },

  /**
   * Check if we should react to this transaction
   *
   * @param  {object} transaction Transaction
   * @return {boolean}
   */
  shouldReact (transaction) {
    const isSource = transaction.sourceId === this.parent.id
    const isTarget = transaction.targetId === this.parent.id
    const isComplete = transaction.state === 'complete'
    return isComplete && (isSource || isTarget)
  }
})
