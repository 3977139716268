
.pure-u-1
  .pure-u-1-24
    .cell.sort-position
      i.ion-drag(data-hook="drag-handle")

  .pure-u-3-8
    .cell.item-name #{model.name}

  div(class=isAdmin ? 'pure-u-1-6' : 'pure-u-1-3')
    .cell #{model.packageSize} x #{model.volumePerUnit}l
      span.append
        if model.weightFull
          span voll #{model.weightFull}g
        if model.weightFull && model.weightEmpty
          span #{', '}
        if model.weightEmpty
          span leer #{model.weightEmpty}g

  .pure-u-1-8
    .cell(title=`${model.costPerUnitLocalized} pro ${model.unit}`) #{model.costLocalized}
      span.append pro #{model.costUnitLocalized}

  .pure-u-1-8
    .cell
      span(title=`${model.pricePerUnitLocalized} pro ${model.unit}`) #{model.priceLocalized}
      if model.canSpill
        i(data-title="Schankverlust möglich").icon.can-spill.ion-ios-wineglass
      span.append pro #{model.servingSizeLocalized}

  if isAdmin
    .pure-u-1-6
      .cell.action.btn-group
        button(data-title=`${model.name} bearbeiten...`, data-hook="edit").pure-button.btn-round.edit: i.icon.ion-edit
        div(data-hook="delete-button")
