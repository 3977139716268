tr
  td
    a(href=baseUrl) #{model.shiftName}
    .append #{model.barChef}

  td #{model.shiftEndTimeLocalized}
    .append
      | Erstellt am #{model.createdAtLocalized}<br>
      | von #{model.user.name}

  td(class=model.sumSold < 0 ? 'error' : null) #{model.sumSoldLocalized}
    if model.tokens
      .append abzgl. #{model.tokens} Marken: #{model.salesWithoutTokensLocalized}

  td #{model.amountGivenLocalized}
    .append(class=model.differenceWithoutTokens < 0 ? 'error' : null) #{model.differenceWithoutTokensLocalized}
