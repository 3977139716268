import app from 'ampersand-app'
import { useEffect, useState } from 'preact/hooks'
import useSWR from 'swr'

/**
 * @param {string} endpoint
 * @param {{ method?: RequestInit['method'], body?: any }} options
 */
export const api = async (endpoint, options = {}) => {
  const response = await fetch(endpoint, {
    method: options.method,
    headers: {
      'content-type': 'application/json'
    },
    body: options.body ? JSON.stringify(options.body) : undefined
  })

  if (!response.ok) {
    const errorResponse = await response.json()
    const error = new Error(errorResponse.message || response.statusText)
    // @ts-ignore
    error.statusCode = response.status
    // @ts-ignore
    error.response = errorResponse
    throw error
  }

  if (response.status !== 204) {
    return response.json()
  }
}

/**
 * @param {string} eventIdOrSlug
 * @param {{
 *   sourceId: string;
 *   targetId: string;
 *   productId: string;
 *   units: number;
 *   comment?: string;
 *   isFree?: boolean
 * }[]} transactions
 */
export function bulkCreateTransactions (eventIdOrSlug, transactions) {
  return api(`/api/events/${eventIdOrSlug}/transactions`, {
    method: 'POST',
    body: transactions
  })
}

export function useCurrentEventSlug () {
  const [currentEventSlug, setCurrentEventSlug] = useState(
    /** @type {string} */ (app.me.currentEvent.slug)
  )

  useEffect(() => {
    const syncSlug = () => {
      setCurrentEventSlug(app.me.currentEvent.slug)
    }
    app.on('sync', syncSlug)

    return () => {
      app.off('sync', syncSlug)
    }
  }, [])

  return currentEventSlug
}

export function useCurrentRole () {
  const [currentRole, setCurrentRole] = useState(
    /** @type {'admin' | 'dispatcher' | 'bar'} */ (app.me.role)
  )

  useEffect(() => {
    const syncSlug = () => {
      setCurrentRole(app.me.role)
    }
    app.on('sync', syncSlug)

    return () => {
      app.off('sync', syncSlug)
    }
  }, [])

  return currentRole
}

export function useTransactions () {
  const eventSlug = useCurrentEventSlug()
  const qs = new URLSearchParams({ state: 'transit' })
  qs.append('state', 'accepted')

  /** @type {import('swr').SWRResponse<import('./types').GetTransactionsResponse>} */
  const response = useSWR(`/api/events/${eventSlug}/transactions?${qs}`, null, {
    refreshInterval: 3000
  })

  return response
}

/**
 * @param {string} eventIdOrSlug
 */
export function useLocations (eventIdOrSlug) {
  /** @type {import('swr').SWRResponse<import('./types').Location[]>} */
  const { data: locations, error } = useSWR(
    `/api/events/${eventIdOrSlug}/locations`
  )

  const storage = locations?.filter(
    (location) => location.type === 'storage' || location.type === 'crash'
  )
  const bars = locations?.filter((location) => location.type === 'bar')
  const delivery = locations?.find((location) => location.type === 'delivery')

  return {
    error,
    locations,
    storage,
    bars,
    delivery
  }
}

/**
 * @param {string} locationId
 */
export function useLocationById (locationId) {
  const eventSlug = useCurrentEventSlug()
  /** @type {import('swr').SWRResponse<import('./types').Location>} */
  const response = useSWR(`/api/events/${eventSlug}/locations/${locationId}`)

  return response
}
