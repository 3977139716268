.pure-g.dispatch-item
  .pure-g
    .pure-u-10-24
      input(name="inputPackages", type="number", min=0, placeholder=0).pure-input-1
      span(data-hook="package-name").append #{model.product.package}
    .pure-u-4-24
    .pure-u-10-24
      if model.product.packageId !== 'keg'
        input(name="inputUnits", type="number", min=0, step="any", placeholder=0).pure-input-1
        span(data-hook="package-unit").append #{model.product.unit}

  .pure-g(data-hook="error-item").hidden
    .pure-u-1
      .error(data-hook="error-text")
