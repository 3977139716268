.popover.confirm-delete.animated
  .popover-arrow
  .popover-content
    if opts.text
      div #{opts.text}
    else
      div #{opts.name || model.name} wirklich löschen?
    .btn-group
      button(data-hook="cancel").pure-button Abbrechen
      button(data-hook="confirm-delete").pure-button.button-error #{opts.confirmButtonText || 'Löschen'}
