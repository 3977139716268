form.pure-form.pure-form-stacked.password-form
  fieldset
    legend Passwort ändern

    label(for="oldPassword") Altes Passwort
    input(type="password", name="oldPassword", autocomplete="current-password")

    label(for="newPassword") Neues Passwort
    input(type="password", name="newPassword", autocomplete="new-password")

    label(for="newPasswordConfirm") Neues Passwort bestätigen
    input(type="password", name="newPasswordConfirm", autocomplete="new-password")

  button.pure-button(type="submit", disabled, data-hook="submit") Passwort ändern
  div(data-hook="error").error
