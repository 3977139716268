tr
  td.item-name #{model.product.name}
  td
    span.packages #{model.deliveryPackagesLocalized}
    span.units #{model.deliveryUnitsLocalized}
    if model.freeware
      .append davon Freiware:
      .append #{model.freewarePackagesLocalized} #{model.freewareUnitsLocalized}
  td
    span.packages #{model.packagesLocalized}
    span.units #{model.unitsLocalized}
  td
    span.packages #{model.packagesSoldLocalized}
    span.units #{model.unitsSoldLocalized}
  //- td
  //-   .progress
  //-     .progress-bar(
  //-       class=model.ratio <= 0.1 || model.ratio > 1 ? 'low' : null,
  //-       aria-valuenow=model.ratio*100,
  //-       style="width:" + Math.floor(model.ratio*100) + "%;",
  //-       role="progressbar", aria-valuemin="0", aria-valuemax="100"
  //-     )
  //-   .append #{model.ratioLocalized}
