'use strict'

const View = require('ampersand-view')
const ItemView = require('./stock-taking-item-view')

module.exports = View.extend({
  template: require('./templates/entry-form.pug').default,

  subviews: {
    list: {
      hook: 'item-list',
      prepareView (el) {
        return this.renderCollection(this.model.items, ItemView, el)
      }
    }
  }
})
