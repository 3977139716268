'use strict'

const View = require('ampersand-view')
const FilteredCollection = require('ampersand-filtered-subcollection')
const app = require('ampersand-app')

function hasStock (model) {
  return model.type === 'delivery' ? true : model.stock.length
}

module.exports = View.extend({
  template: require('./source.pug').default,

  events: {
    'change select': 'onSelect'
  },

  initialize () {
    this.collection = new FilteredCollection(app.locations, {
      filter (model) {
        // only display delivery and locations with stock
        return model.type !== 'crash' && hasStock(model)
      },
      watched: ['type']
    })

    // set initial source
    if (!this.model.sourceId) {
      const storage = this.collection.filter(location => location.type === 'storage')
      if (storage.length) this.model.set('sourceId', storage[0].id)
    }

    this.listenTo(this.collection, 'add remove change', this.render)
  },

  onSelect (evt) {
    this.model.set('sourceId', evt.target.value)
  }
})
