.auth-flip-view.back
  form.pure-form.pure-form-stacked.signup-form
    fieldset
      legend Neuen Benutzer anlegen

      label(for="username") Benutzername
      input.pure-u-1(name="username", type="text", placeholder="Name", data-hook="username", autocomplete="off", autocorrect="off", autocapitalize="off")

      label(for="password") Passwort
      input.pure-u-1(type="password", name="password", placeholder="Passwort", data-hook="password")

    button(type="submit", disabled).pure-button.pure-button-primary Anlegen
