'use strict'

const View = require('ampersand-view')

const Bar = require('../../models/bar')
const StockTakingDynamic = require('../../models/stock-taking-dynamic')
const ShiftEndForm = require('./stock-shift-end')
const MetaForm = require('./stock-meta-form')
const EntryForm = require('./entry-form')

function parseItems (item) {
  return { productId: item.productId, stockUnits: item.units }
}

module.exports = View.extend({
  template: require('./templates/stock-taking.pug').default,

  title () {
    if (this.model.isNew()) {
      return `Neue Inventur für ${this.location.name}`
    }
    return `Inventur für ${this.location.name}`
  },

  derived: {
    backUrl: {
      cache: false,
      fn () {
        if (
          this.location.type === 'storage' ||
          this.location.type === 'crash'
        ) {
          return `/lager/${this.location.slug}/inventuren`
        } else {
          return `/bars/${this.location.slug}/inventuren`
        }
      }
    }
  },

  events: {
    'click [data-hook=prefill]': 'prefill'
  },

  subviews: {
    shiftEndForm: {
      hook: 'shift-end-form',
      prepareView (el) {
        return new ShiftEndForm({ el, model: this.model })
      }
    },

    entryForm: {
      hook: 'entry-form',
      prepareView (el) {
        return new EntryForm({ el, model: this.model })
      }
    },

    metaForm: {
      hook: 'meta-form',
      prepareView (el) {
        return new MetaForm({ el, model: this.model })
      }
    }
  },

  initialize (opts = {}) {
    this.location = opts.location

    if (opts.id) {
      // existing inventory
      this.model = new StockTakingDynamic({
        locationId: this.location.id,
        id: opts.id
      })
      this.model.once('sync', () => {
        this.listenTo(this.model, 'change:shiftEndTime', this.onTimeChange)
      })
      this.model.fetch()
    } else {
      // new inventory with current stock
      this.model = new StockTakingDynamic({ locationId: this.location.id })
      // fetch latest location stock
      this.onTimeChange(this.model, this.model.shiftEndTime)

      this.listenTo(this.model, 'change:shiftEndTime', this.onTimeChange)
    }
  },

  onTimeChange (model, shiftEndTime) {
    const location = new Bar({ id: this.location.id })
    location.fetch({ data: { before: new Date(shiftEndTime).toISOString() } })
    location.once('sync', () => {
      this.model.items.set(location.stock.map(parseItems))
    })
  },

  prefill (evt) {
    evt.preventDefault()
    this.model.prefill('Erstbestückung')
  }
})
