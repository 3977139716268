.product-page
  .pure-g(data-hook="page-header")
    .pure-u-1-4
      h1 Produkte
    .pure-u-3-4
      .flex-center

        .cell
          div(data-hook="search-bar")
        if isAdmin
          .cell.btn-group
            button.btn-round.pure-button.add-item(data-hook="add-product", data-title="Produkt hinzufügen")
              i.icon.ion-plus
            div(data-hook="import-products")
            a.btn-round.pure-button(href=exportUrl, data-title="Produkte als CSV exportieren", download, target="_blank")
              i.icon.ion-ios-cloud-download

  .table.item-list.table-striped
    header
      .pure-g
        .pure-u-1-24
          .cell #
        .pure-u-3-8
          .cell Bezeichung
        div(class=isAdmin ? 'pure-u-1-6' : 'pure-u-1-3')
          .cell Verpackung
        .pure-u-1-8
          .cell Einkauf
            span.append netto
        .pure-u-1-8
          .cell Verkauf
            .span.append brutto
        if isAdmin
          .pure-u-1-6
            .cell

    .tbody(data-hook="products")

  .new-item(data-hook="new-product")

  section.deleted-products
    .pure-g
      .pure-u-3-4
        h2 Gelöschte Produkte
          small Hier erscheinen Produkte, die gelöscht, aber schon verwendet wurden.

    .table.item-list.table-striped
      header
        .pure-g
          div(class=isAdmin ? 'pure-u-1-5' : 'pure-u-1-4')
            .cell Bezeichung
          div(class=isAdmin ? 'pure-u-1-5' : 'pure-u-1-4')
            .cell Verpackung
          div(class=isAdmin ? 'pure-u-1-5' : 'pure-u-1-4')
            .cell Einkauf
              span.append netto
          div(class=isAdmin ? 'pure-u-1-5' : 'pure-u-1-4')
            .cell Verkauf
              .span.append brutto
          if isAdmin
            .pure-1-5
              .cell

      .tbody(data-hook="deleted-products")
