import Big from 'big.js'
import app from 'ampersand-app'
import stem from 'snowball-german'
import slugger from 'slugger'
import debug from 'debug'

import { packaging } from './models/packages'

export const logger = debug('booze-boy')

const spaces = /\s+/g
const HUNDRED = new Big(100)

/**
 * @param {string} str
 */
function entumlautify (str) {
  return str
    .toLowerCase()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
}

export function indexify (string) {
  if (typeof string !== 'string') return string
  return stem(string.toLowerCase().replace(spaces, ''))
}

/**
 * Makes currency string from number
 * @param  {number} amount Value in cents
 * @return {string}        Formatted Currency
 */
export function currency (amount) {
  if (!Number.isFinite(+amount) || isNaN(+amount)) return ''
  amount = new Big(amount || 0)
  return amount.toFixed(2).toString().replace('.', ',') + ' €'
}

export function net (value, vat) {
  vat = vat || app.me.currentEvent.vat
  if (!Number.isFinite(value) || !Number.isFinite(vat)) return 0

  const factor = HUNDRED.div(HUNDRED.plus(vat))
  return +factor.times(value)
}

export function vat (net, gross) {
  if (!Number.isFinite(gross) || !Number.isFinite(net)) return 0
  gross = new Big(gross)
  return +gross.minus(net)
}

export function sum (array, prop) {
  return +array
    .map((product) => (Number.isFinite(product[prop]) ? product[prop] : 0))
    .reduce((memo, num) => new Big(memo).plus(num), 0)
}
// comparator functions
export function byLocationSortPosition (model) {
  // left pad with zeros to ensure proper ordering - 0001, 0002....0010
  if (Number.isFinite(model.sortPosition)) {
    return model.sortPosition.toString().padStart(4, '0')
  }
  if (model.type === 'delivery') return '\ufff0'
  return model.name.toLowerCase()
}

export function byStockSortPosition (model) {
  // left pad with zeros to ensure proper ordering - 0001, 0002....0010
  if (Number.isFinite(model.product.sortPosition)) {
    return model.product.sortPosition.toString().padStart(4, '0')
  }
  return model.product.name
}

export function byStockSortPositionDesc (one, two) {
  return two.product.sortPosition - one.product.sortPosition
}

export function byStockNameAsc (model) {
  return model.product.name
}

export function byStockNameDesc (one, two) {
  if (one.product.name !== two.product.name) {
    return one.product.name > two.product.name ? -1 : 1
  }
  return 0
}

export function byNumberAsc (key) {
  return function (one, two) {
    return one[key] - two[key]
  }
}

export function byNumberDesc (key) {
  return function (one, two) {
    return two[key] - one[key]
  }
}

export function i18nUnits (totalUnits, product) {
  const units = Math.round((totalUnits % product.packageSize) * 100) / 100
  if (!units) return null

  const unitName = packaging.get(product.unitId)
  return `${units.toString().replace('.', ',')} ${
    units > 1 || units < -1 ? unitName.plural : unitName.name
  }`
}

export function i18nPackages (totalUnits, product) {
  let packages = totalUnits / product.packageSize
  packages = totalUnits < 0 ? Math.ceil(packages) : Math.floor(packages)
  if (!packages) return null

  const unitName = packaging.get(product.packageId)
  return `${packages} ${
    packages > 1 || packages < -1 ? unitName.plural : unitName.name
  }`
}

export function unitsLocalized (key) {
  return {
    deps: [key, 'product'],
    fn () {
      return i18nUnits(this[key], this.product)
    }
  }
}

export function packagesLocalized (key) {
  return {
    deps: [key, 'product'],
    fn () {
      return i18nPackages(this[key], this.product)
    }
  }
}

export function localizedUnit (key) {
  return {
    deps: [key, 'product'],
    fn () {
      if (!this[key]) return null
      const unitName = packaging.get(this.product.unitId)
      return `${this[key]} ${
        this[key] > 1 || this[key] < -1 ? unitName.plural : unitName.name
      }`
    }
  }
}

export function localizedPackage (key) {
  return {
    deps: [key, 'product'],
    fn () {
      if (!this[key]) return null
      const packageName = packaging.get(this.product.packageId)
      return `${this[key]} ${
        this[key] > 1 || this[key] < -1 ? packageName.plural : packageName.name
      }`
    }
  }
}

/**
 * @param {number} servingSize
 */
export function localizeServingSize (servingSize) {
  if (servingSize < 0.1) {
    return `${servingSize * 100}cl`
  } else if (servingSize < 1) {
    return `${servingSize * 1000}ml`
  } else {
    return `${servingSize}l`
  }
}

export const formatCurrency = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'symbol'
}).format

export const isAdminProp = {
  type: 'boolean',
  setOnce: true,
  default () {
    return app.me.role === 'admin'
  }
}

export function slugify (prop) {
  if (typeof prop !== 'string') return prop
  // don't decode URI components, otherwise it crashes when the string contains a %
  return slugger(entumlautify(prop), { decode: false })
}
