form.shift-end-form.pure-form-stacked
  .pure-g
    .pure-u-1-3
      label(for="shiftName") Schichtname
      input(type="text", name="shiftName", value=model.shiftName, required)
    .pure-u-1-3
      label(for="date") Schichtende
      div(data-hook="date-picker")
      if !model.isNew()
        p.append.error.
          Achtung: Durch Änderung der Schichtendzeit <em>können</em> bereits eingetragene Inventurbestände verloren gehen.
    .pure-u-1-3
      .append.
        Die Schichtendzeit muss eingestellt werden, damit der Bestand zu dieser Zeit ermittelt werden kann.
        Daraus errechnet sich die verkaufte Menge und schlussendlich die Differenz zwischen erhaltenem Betrag und Umsatz.
