import Model from 'ampersand-model'
import app from 'ampersand-app'
import { DateTime } from 'luxon'

import { currency as formatCurrency, indexify } from '../utils'

function currency (key) {
  return {
    deps: [key],
    fn () {
      return formatCurrency(this[key])
    }
  }
}

export default Model.extend({
  urlRoot () {
    return `/api/events/${app.me.currentEvent.slug}/locations/${this.locationId}/inventories`
  },

  props: {
    id: {
      type: 'string',
      setOnce: true
    },
    shiftEndTime: {
      type: 'date',
      required: true,
      default () {
        return new Date()
      }
    },
    amountGiven: {
      type: 'string',
      default: '0'
    },
    barChef: 'string',
    shiftName: 'string',
    tokens: ['number', true, 0],
    comment: 'string',
    locationId: ['string', true],
    createdAt: 'string',
    user: 'object'
  },

  session: {
    sumSold: ['number', true, 0],
    difference: ['number', true, 0]
  },

  derived: {
    location: {
      deps: ['locationId'],
      fn () {
        return app.locations.get(this.locationId) || {}
      }
    },

    createdAtLocalized: {
      deps: ['createdAt'],
      fn () {
        return DateTime.fromISO(this.createdAt).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    },

    shiftEndTimeLocalized: {
      deps: ['shiftEndTime'],
      fn () {
        return DateTime.fromJSDate(this.shiftEndTime).toLocaleString(
          DateTime.DATETIME_SHORT
        )
      }
    },

    salesWithoutTokens: {
      deps: ['sumSold', 'tokensValue'],
      fn () {
        return this.sumSold - this.tokensValue
      }
    },

    salesWithoutTokensLocalized: currency('salesWithoutTokens'),

    differenceWithoutTokens: {
      deps: ['salesWithoutTokens', 'amountGiven'],
      fn () {
        return this.amountGiven - this.salesWithoutTokens
      }
    },

    differenceWithoutTokensLocalized: currency('differenceWithoutTokens'),

    differenceLocalized: currency('difference'),
    sumSoldLocalized: currency('sumSold'),
    amountGivenLocalized: currency('amountGiven'),

    tokensValue: {
      deps: ['tokens'],
      fn () {
        return this.tokens * app.me.currentEvent.tokenValue
      }
    },
    tokensLocalized: currency('tokensValue'),

    isDifferenceNegative: {
      deps: ['differenceWithoutTokens'],
      fn () {
        return this.differenceWithoutTokens < 0
      }
    },

    isSumSoldNegative: {
      deps: ['sumSold'],
      fn () {
        return this.sumSold < 0
      }
    },

    _nameIndex: {
      deps: ['shiftName'],
      fn () {
        return indexify(this.shiftName) || ''
      }
    }
  },

  validate () {
    if (!this.shiftName) return 'Bitte Schichtname angeben.'
  }
})
