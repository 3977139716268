'use strict'

const FilteredCollection = require('ampersand-filtered-subcollection')
const assign = require('lodash/assign')
const app = require('ampersand-app')
const LocationBase = require('../location-base/location-base-page')
const StorageView = require('./storage-view')
const Storage = require('../../models/storage')

/*
  Seite - Lagerübersicht
 */
module.exports = LocationBase.extend({
  title: 'Lager',
  template: require('./storage-page.pug').default,

  events: assign({}, LocationBase.prototype.events, {}),

  initialize () {
    // render from cache and fetch updates in the background
    this.collection = new FilteredCollection(app.locations, {
      filter (model) {
        return model.type === 'storage' || model.type === 'crash'
      },
      watched: ['type']
    })

    this.collection.filterFn = function (model) {
      return model.type === 'storage' || model.type === 'crash'
    }

    app.locations.fetch()
  },

  /**
   * Adds a new location to the collection
   * @param  {object} event DOM Event
   */
  onNew () {
    this.renderSubview(
      new StorageView({
        model: new Storage(),
        parent: this
      }),
      '[data-hook="new-location"]'
    )
  }
})
