import app from 'ampersand-app'
import { useEffect, useState } from 'preact/hooks'
import { PreactView } from '../preact-view'

export const TaskboardCheckboxView = PreactView.extend({
  component: TaskboardCheckbox
})

function TaskboardCheckbox () {
  const [checked, setShowTaskboard] = useState(app.view.showTaskboard)
  useEffect(() => {
    function onShowTaskboardChange (view, showTaskboard) {
      setShowTaskboard(showTaskboard)
    }

    app.view.on('change:showTaskboard', onShowTaskboardChange, this)

    return () => {
      app.view.off('change:showTaskboard', onShowTaskboardChange)
    }
  }, [])

  return (
    <label class='taskboard-toggle'>
      <input
        type='checkbox'
        name='toggle-taskboard'
        checked={checked}
        onChange={(event) => {
          app.view.toggleTaskboard(event)
        }}
      />
      <div>Taskboard</div>
    </label>
  )
}
