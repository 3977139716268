'use strict'

const View = require('ampersand-view')

module.exports = View.extend({
  template: require('./templates/stock-taking-item-weight.pug').default,

  events: {
    'keyup input': 'onKeyUp',
    'change input': 'onKeyUp',
    'click [data-hook="remove-weight"]': 'removeModel'
  },

  removeModel (evt) {
    evt.preventDefault()
    this.model.collection.remove(this.model)
  },

  onKeyUp (evt) {
    evt.stopPropagation()
    if (!evt.target.value) return
    this.model.value = +evt.target.value
  }
})
