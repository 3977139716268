li.event.pure-g
  .pure-u-1-2
    span(data-hook="name") #{model.name}
    .append Du bist #{model.roleLocalized}.

  .pure-u-1-3
    .btn-group.buttons
      button(data-hook="activate", title=`Zu ${model.name} wechseln...`).pure-button.btn-round
        i.ion-checkmark
      if isAdmin
        a(href=`/einstellungen/${model.id}`, title=`Einstellungen für ${model.name}`).pure-button.btn-round
          i.ion-ios-gear
