'use strict'

const View = require('ampersand-view')

module.exports = View.extend({
  template: require('./not-found.pug').default,

  title: ':-(',

  initialize (opts = {}) {
    this.path = opts.path
  }
})
