'use strict'

const View = require('ampersand-view')
const TargetView = require('./target-view')
const ItemView = require('./dispatch-item-view')
const Transaction = require('../../models/transaction').default
const mixin = require('../popover-mixin')

module.exports = View.extend(mixin, {
  template: require('./dispatch-simple.pug').default,

  events: {
    'change [data-hook=target]': 'onTargetChange',
    'click [data-hook=submit]': 'submit'
  },

  subviews: {
    target: {
      hook: 'select-target',
      prepareView (el) {
        return new TargetView({ el, model: this.model })
      }
    },
    item: {
      hook: 'products',
      prepareView (el) {
        return new ItemView({
          el,
          model: this.model,
          template: require('./dispatch-item-simple.pug').default
        })
      }
    }
  },

  initialize (opts = {}) {
    this.setButton(opts.button)
    this.opts = opts

    this.model = new Transaction({
      sourceId: opts.sourceId,
      productId: opts.productId
    })

    this.listenTo(this.model, 'sync', this.remove)
    this.listenTo(this.model, 'change:units', this.isValid)
  },

  /*
    Vollständigkeit prüfen und Button togglen
   */
  isValid () {
    const isValid = this.model.isValid()
    if (this.btn) this.btn.disabled = !isValid
    return isValid
  },

  // set new target for all transactions
  onTargetChange (event) {
    event.preventDefault()
    this.model.targetId = event.target.value
  },

  submit (event) {
    event.preventDefault()
    this.model.save()
  },

  render () {
    mixin.render.apply(this, arguments)

    window.requestAnimationFrame(() => this.query('[name=target]').focus())

    this.cacheElements({
      btn: '[data-hook=submit]'
    })
    return this
  }
})
