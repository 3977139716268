'use strict'

const View = require('ampersand-view')
const { onAnimationEnd } = require('../../on-end')

/*
  Basic Settings Form
 */

module.exports = View.extend({
  events: {
    submit: 'submit',
    'keyup input': 'onKeyup',
    'change input': 'onKeyup'
  },

  submit (evt) {
    evt.preventDefault()
    this.model.save(this.toJSON())
  },

  onSuccess (model, res, opts) {
    const el = this.queryByHook(`checkmark-${opts.target.name}`)
    if (!el) return
    onAnimationEnd(el, () => el.classList.remove('fadeOutUp'))
    el.classList.add('fadeOutUp', 'animated')
  },

  onKeyup (evt) {
    const type = evt.target.type
    const val = evt.target.value
    this.set(evt.target.name, type === 'number' ? +val : val)
  }
})
