'use strict'

const app = require('ampersand-app')

const { byLocationSortPosition } = require('../utils')
const CachedCollection = require('./cached-collection')
const Storage = require('./storage')
const Bar = require('./bar')
const Location = require('./location-base-model')

/*
 Collection representing a list of locations
 */

module.exports = CachedCollection.extend({
  url () {
    return `/api/events/${app.me.currentEvent.slug}/locations`
  },

  model (attrs, opts) {
    switch (attrs.type) {
      case 'storage':
      case 'crash':
        return new Storage(attrs, opts)
      case 'bar':
        return new Bar(attrs, opts)
      default:
        return new Location(attrs, opts)
    }
  },

  isModel (model) {
    return model instanceof Storage || model instanceof Bar || model instanceof Location
  },

  // cache options
  storageKey () {
    return app.me.currentEvent.slug + '-locations'
  },

  comparator: byLocationSortPosition,

  initialize () {
    CachedCollection.prototype.initialize.apply(this)

    // listen to remote changes
    this.listenTo(app.changes, 'locations', this.onChange)

    this.on('change:sortPosition', this.onSortChange, this)
  },

  onSortChange () {
    // delay to nextTick, so all changes have been set
    setTimeout(() => this.sort(), 0)
  },

  onChange (change) {
    // don't react to inserts to avoid github.com/klaemo/booze-boy-client/issues/14
    if (change.type === 'update' || change.type === 'insert') {
      this.set(change.row, { remove: false })
    }

    if (change.type === 'delete') {
      this.remove(change.id)
    }
  },

  onError (error) {
    console.error(error)
  }
})
