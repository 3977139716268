
select(name="target").pure-input-1
  option(disabled, selected=!model.targetId) Ziel wählen...

  optgroup(label="Bars")
    each target in collection.models
      if target.type === 'bar'
        option(value=target.id, selected=model.targetId === target.id) #{target.name}

  optgroup(label="Lager")
    each target in collection.models
      if target.type === 'storage' || target.type === 'crash'
        option(value=target.id, selected=model.targetId === target.id) #{target.name}
